import React from "react";
import { useState, useEffect } from "react";
import "../login_Page/login.css";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoginLeftPart from "../login_Page/LoginLeftPart";
// import SendOtp from "./SendOtp";
import "antd/dist/antd.min.css";
import { notification, message } from "antd";
import axios from "axios";
// import config from "../../config";

import { ReactComponent as CLoseEye } from "../../Icons/closeEye.svg";
import { ReactComponent as OpenEye } from "../../Icons/openEye.svg";
import config from "../../config";

export default function Login(props) {
  const navigate = useNavigate();
  const params  =  useParams()
  const [password, setPassword] = useState("");
  const [reEnterPass, setReEnPss] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Reset link sent to your email",
      duration: 2,
      closeIcon: <></>,
    });
  };


  useEffect(() => {
    if (isCPasswordDirty) {
      if (password === reEnterPass) {
        setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    }
  }, [reEnterPass, isCPasswordDirty, password]);

  const handleCPassword = (e) => {
    setReEnPss(e.target.value);
    setIsCPasswordDirty(true);
  };

  const handelEnterKey=(event)=>{
    if(event.keyCode === 13){
      if( password.length >= 8 &&
        reEnterPass.length >= 8 && 
        showErrorMessage === false){
        // console.log("key press")
        handleContinueToLogin()
      }
  
    }
  }
  const setPasswordSuccessFul = (type) => {
    notification[type]({
      message: "Password set successfully",
      duration: 2,
      closeIcon: <></>,
    });
  };
  // const showError = () => {
  //   message.error({
  //     content: "Invalid credentials",
  //     className: "custom-class",
  //     style: {
  //       marginTop: "56vh",
  //       marginLeft: "20vw",

  //     },
  //   });
  // };
  // const showOtpError = () => {
  //   message.error({
  //     content: "OTP does not match",
  //     className: "custom-class",
  //     style: {
  //       marginTop: "5vh",
  //       marginLeft: "77vw",
  //     },
  //   });
  // };
  // const showErrorUserNotExist = () => {
  //   message.error({
  //     content: "User does not exist",
  //     className: "custom-class",
  //     style: {
  //       marginTop: "5vh",
  //       marginLeft: "77vw",
  //     },
  //   });
  // };

  // if(window.location.pathname ==="/"){
  //   localStorage.clear()
  // }

  useEffect(() => {
    if (window.location.pathname === "/") {
      localStorage.clear();
    }
  }, [window.location.pathname]);

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(0);
  };

  // ----------------------------OTP Request Function---------------------
//   const otpRequest = () => {
//     setPassword("");
//   };
  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
  };

//   const agentforgetpassword = () => {
//     axios
//       .post(
//         config.forgotPassword,
//         { agent_email_id: email },
//         { headers: headers }
//       )
//       .then((res) => {
//         setEmail("")
//         console.log("forget password", res);

//         // if (res.data) {
//         //   // setSendOtp(true);

//           openNotificationWithIcon("success");
//           navigate("/")
//         //   setSendOtp(true);
//         // }
//       })
//       .catch((error) => {
//         console.log("forget password error", error);
//         // if (error.response.data.non_field_errors[0]) {
//         //   showErrorUserNotExist();

//         //   // console.log("error function call");
//         // }
//       });
//   };

  

  const handleContinueToLogin = () => {
     axios.post(
      config.setPassword,
      {agent_id:params.agent_id,token:params.reset,password:password},
      {headers:headers}
     ).then((res) => {
             setPasswordSuccessFul("success")
              // console.log(" set forget password", res);
              navigate("/")
            
            })
            .catch((error) => {
              // console.log("set forget password error", error);
            
            });
  }

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Helmet>
        <title>Login</title>
        {/* <link rel="icon" href="/public/faveicon.ico" /> */}
        <link
          rel="icon"
          type="image/png"
          href="./././public/feicon.ico"
          sizes="16x16"
        />
      </Helmet>

      <LoginLeftPart />
      <div style={{ display: "flex", flexDirection: "column" ,marginLeft:"5vw" }}>
    
          <img className="logo-img" src="/assets/Logo.png" alt="Loding...." />
          <h1 className="heading-set-pss">Set up your new password</h1>
          <div
            style={{
              display:"flex",
              flexDirection: "column",
              justifyContent: "center",
              rowGap:"0.55vw",
              marginTop: "0.55vw",
            }}
          >
        
            <input
              value={password}
              className="password-input"
              type="password"
              placeholder="Create password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
           
            <input
              value={reEnterPass}
              className="password-input"
              type="password"
              placeholder="Re-type password"
              onChange={handleCPassword}
              onKeyDown={handelEnterKey}
            />
          </div>
          <div>
          <p className="suggest-para">
              Your password should have minimum 8 digits 
            </p>
  
            {showErrorMessage && isCPasswordDirty ? (
              <span className="password-error">
                {" "}
                Passwords did not match{" "}
              </span>
            ) : (
              ""
            )}
          </div>
          <div>
            
              <button
                disabled={
                  showErrorMessage === false &&
                  password.length >= 8 && 
                  reEnterPass.length >= 8 
                    ? false
                    : true
                }
                className={`continue-btn ${
                  password.length >= 8 &&
                  reEnterPass.length >= 8 && 
                  showErrorMessage === false 
                    ? "active-continue-btn"
                    : ""
                }`}
                onClick={handleContinueToLogin}

              >
                Continue to login
              </button>
     
          
           </div> 
      
    </div>
     
    </div>
  );
}
