import axios from "axios";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import config from "../../../config";
import OtherLinkView from "../../Clients/ClientnDetails/AboutCLient";
import { Digio } from "../../../digio"
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function OtherLinkOverView(props) {
  const [otherLink, setOtherLink] = useState([]);

  const editFunction = () => {
    props.setDetailView(false);
    props.setFillDetails(true);
  };
  const nexthandel = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    props.handleComplete()
  };




  // const uploadfile = () => {
  //   axios.post(
  //     config.agreeMentDoc,
  //     {file:props.agreementDoc},
  //     {headers:headers}
  //   ).then((response) => {
  //     console.log("post file",response);
  //     message.success("Upload Successful");
  //   }).catch((error) => {
  //     console.log("post error",error);
  //   })
  // }










  useEffect(() => {
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };
    axios
      .get(config.otherLinks, { headers: headers })
      .then((res) => {
        props.handleComplete()
        // console.log("agent other get link get ", res);
        props.setFillDetails(false);
        //  if(res.status === 200){
        //     props.setDetailView(false)
        //     props.setFillDetails(true)
        //  }

        //   props.setDetailView(false);

        const otherLinkDetail = [
          {
            heading: "Company Name",

            heading_info: res.data.agent_company_name
            ? res.data.agent_company_name
            : "--",
          },
          {
            heading: "Facebook Page",

            heading_info: res.data.agent_facebook_acount? res.data.agent_facebook_acount: "--",
          },
          {
            heading: "LinkedIn Page",

            heading_info: res.data.agent_linkedin_acount? res.data.agent_linkedin_acount: "--",
          },
          {
            heading: "Instagram Page",

            heading_info: res.data.agent_instagram_page? res.data.agent_instagram_page: "--",
          },
          {
            heading: "Website",

            heading_info: res.data.agent_website? res.data.agent_website: "--",
          },
          {
            heading: "Microsite",

            heading_info: res.data.agent_micro_site_name? res.data.agent_micro_site_name: "--",
          },
        ];
        setOtherLink(otherLinkDetail);
        //   props.setDetailView(true);
        props.setLinkdin(res.data.agent_linkedin_acount);
        props.setInstaLink(res.data.agent_instagram_page);
        props.setFbLink(res.data.agent_facebook_acount);
        props.setWebLink(res.data.agent_website);
        props.setMicroSiteLink(res.data.agent_micro_site_name);
        props.setCompanyName(res.data.agent_company_name)
      })
      .catch((error) => {
        // console.log("agent other get link error", error);
        props.setDetailView(false);
        props.setFillDetails(true);
      });
  }, []);


  let options = {
    environment : "localhost",
    callback : function (response){
    if(response.hasOwnProperty("error_code")){
       return console.log("error occurred in process");
    }
    console.log("SUCCESS");
    }
  }
  function digioWindowOpen() {
    let digio = new Digio(options);
    digio.init();
    // digio.submit("DID2211231650017857FHDSC39RDMZCT", "push@gmail.com"); //DID from Step-1 response, customer identifier

    // digio.submit(requestId, identifier, token_id); // From Step-1 response (KID, ...., GWT)
  }

  const openDgo = () => {
    digioWindowOpen()
    console.log("CLL FUNCTION");
  };

  return (
    <div>
      <Helmet>

      </Helmet>
      <div className="overViewContainer">
        {otherLink.map((data) => {
          return (
            <OtherLinkView
              clientInfoHeading={data.heading}
              clientInfoHeadingData={data.heading_info}
            />
          );
        })}
        {/* <button
          type="button"
          style={{ width: "8vw", height: "2vw" }}
          onClick={openDgo}
        >
          Click Me!
        </button> */}
        <div className="edit-next-btn-container">
          <p className="edit-btn" onClick={editFunction}>
            Edit
          </p>
          <p className="edit-btn" onClick={nexthandel}>
            Next
          </p>
        </div>
      </div>
    </div>
  );
}
