
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Login from './Components/login_Page/Login';
import SignUp from './Components/SignUpPage/SignUp';
import Layout from './Components/Layout';
import { useEffect } from 'react';
// import Clients from './Components/Clients/Clients';
import AddNewCLients from './Components/Clients/AddNewCLients/AddNewCLients';
import ClientDetails from './Components/Clients/ClientnDetails/ClientDetails';
import { useState } from 'react';
import Dashboard from './Components/Dashboard/Dashboard';
import Payment from './Components/Payment/Payment';
import Clients from './Components/Clients/Clients';
import Agent from './Components/Agents/Agent';
import FundListing from './Components/Clients/FundList/FundListing';
import FundDetails from './Components/Clients/FundList/FundDetails/FundDetails';
import LatestNews from './Components/Dashboard/LatestNews/LatestNews';
import Transaction from './Components/Transactions/Transaction';
import Marketing from './Components/Marketing/Marketing';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import PublicShareFundDetail from './Components/PublicShareFundDetail/PublicShareFundDetial';
import SetPassword  from "./Components/ForgotPassword/SetPassword"

// import PromotionalItems from './Components/Marketing/PromotionalItems';
// import FundMaterials from './Components/Marketing/FundMaterials';
// import MarketUpDates from './Components/Marketing/MarketUpDates';

const token = localStorage.getItem("access_token")

// console.log("token:",token)

// import Clients from './Components/Clients/Clients';
// import ClientChart from './Componen ts/Clients/ClientnDetails/ClientChart';
// import ClientDetails from './Components/Clients/ClientnDetails/ClientDetails';
// import { useState } from 'react';
function App() {
  
  const [isloged,setLoged]  = useState(false)
// const [login,setLogin] = useState(false)

// console.log("idloged :",isloged )
useEffect(() => {
 
// const token = localStorage.getItem("access_token")
// console.log("token:",token)
}, [])
console.log(process.env.REACT_APP_ENV)
  return (

    <div>

      <Router>
        <Routes>
   
          <Route exact path="/" element={<Login setLoged={setLoged} />} />
            <Route path="/signup" element={<SignUp setLoged={setLoged} />} />
            <Route path="/:isin" element={<PublicShareFundDetail/>} />
            <Route path="/forgotpassword" element={<ForgotPassword/>} />
            <Route path="/setpassword/:agent_id/:random/:reset" element={<SetPassword/>} />


            





          <Route element={<Layout  isloged={isloged}  token={token}/>}>
             <Route path="/dashboard" element={< Dashboard/>}/>
             <Route path="/clients" element={<Clients/>} />
             <Route path='transactions' element={<Transaction/>}/>
             <Route path="/earnings" element={<Payment/>} /> 
             <Route path="/fundlist" element={<FundListing/>} /> 
             <Route path="/marketing" element={<Marketing/>}/>
             <Route path="/agent" element={< Agent/>} />



             <Route path="/dashboard/latestnews" element={<LatestNews/>} /> 
             <Route path="/clients/addnewclients" element={< AddNewCLients/>} />
             <Route path="/clients/client/:id" element={< ClientDetails/>} /> 
             <Route path="/dashboard/client/:id" element={< ClientDetails/>} /> 
             <Route path="/fundlist/funddetails/:isin" element={< FundDetails/>} /> 
             {/* <Route path="/marketing/promotionalitems" element={< PromotionalItems/>} />  */}
             {/* <Route path="/marketing/fundmaterials" element={< FundMaterials/>} />  */}
             {/* <Route path="/marketing/marketupdates" element={< MarketUpDates/>} />  */}
          </Route>

            <Route path="*" element={"Not Found"} />
        </Routes>
      </Router>
 

    </div>

  );
}

export default App;
