import React from "react";
import Slider from "react-slick";
import "./Slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
export default function ImageSlider(props) {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    // speed: 500,
    slidesToShow: 1,
    // slidesToScroll: 2,

    // touchMove: false,
  };
  return (
    <div className="dash-news-cont">
      <Slider {...settings}>
        {props.latestNewsData
          // .slice(props.latestNewsData.length - 3, props.latestNewsData.length)
          .map((data) => {
            return (
              <a href={data.news_url} target="_blank">
                <div className="dash-carousel-conetnt">
                  <div className="div-content">
                    <h1>{data.news_heading}</h1>
                    {/* <h6>
                Dutta, 71, will retire on September 30, 2022, after guiding IndiGo
                through the turbulent
              </h6> */}
                    <h5>{data.news_date.split("T")[0]}</h5>
                  </div>
                </div>
              </a>
            );
          })}
      </Slider>
    </div>
  );
}
