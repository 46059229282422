import { DatePicker } from "antd";
import React from "react";
// import Filters from '../Filter/Filters'
import AboutCLient from "./AboutCLient";

export default function AddEventModalContent(props) {
  // const [pickerDate, setPickerDate] = useState(null);
  // const [meetingDate, setMeetingDate] = useState(null);
  //  console.log("pickerDate: " + pickerDate);
  //  console.log("meetingDate: " + meetingDate);
  return (
    <div className="add-event-main-container">
      <AboutCLient
        clientInfoHeading={"Client name"}
        clientInfoHeadingData={props.clientsName}
      />
      <AboutCLient
        clientInfoHeading={"Email"}
        clientInfoHeadingData={props.clientContactData[1].clientContactData}
      />
      <div className="slector-container">
        <select
          className="event-selecter"
          value={props.eventType}
          onChange={(e) => props.setEventType(e.target.value)}   
        >
          <option hidden selected>
            Select event type
          </option>
          <option value="Birthday">Birthday</option>
          <option value="Wedding anniversary">Wedding anniversary</option>
          <option value="Spouse's birthday">Spouse's birthday</option>
          <option value="Son's birthday">Son's birthday</option>
          <option value="Daughter's birthday">Daughter's birthday</option>
          <option value="Mother's birthday">Mother's birthday</option>
          <option value="Father's birthday">Father's birthday</option>
        </select>
      </div>

      <div
        className={
          props.eventType === "Birthday" ||
          props.eventType === "Wedding anniversary" ||
          !props.eventType
            ? "hide-name-input-cont"
            : "name-input-cont"
        }
      >
        <input
          type="text"
          className="add-event-input"
          // min={disablePastDate()}
          value={props.eventHoderName}
          onChange={(e) => props.setEventHolderName(e.target.value)}
          placeholder="Full Name"
        />
      </div>
      <div className="date-input-cont">
        <DatePicker
          format={"DD/MM/YYYY"}
          allowClear={false}
          // value={pickerDate}
          placeholder="Date (DD/MM/YYYY)"
          suffixIcon
          className="add-event-input"
          onChange={(e) => {
            let temp =
              e._d.getFullYear() +
              "-" +
              (e._d.getMonth() + 1) +
              "-" +
              e._d.getDate();
            // console.log(temp);
            // setPickerDate(e);
            props.setEventDate(temp);
          }}
        />
      </div>
    </div>
  );
}
