import React from 'react'
import "./LatestNews.css"
import NewsCarousel from './NewsCarousel'
export default function LatestNews() {
  return (
    <div className='latestNews-main-cont'>
        <p>Latest news</p>
        <div className='about-stocks-news-cont'>
              <h1 className="heading-text">About Stock Markets</h1>
              <div>
                  {/* <NewsCarousel/>  */}
              </div>
        </div>
        <div className='about-mutual-fund-news-cont'>
               <h1 className="heading-text">Latest news on Mutual Funds</h1>
               <div>
                {/* <NewsCarousel/> */}
               </div>
        </div>
        <div className='fund-in-focus-news-cont'>
               <h1 className="heading-text">Funds in Focus</h1>
               <div>
                {/* <NewsCarousel/> */}
               </div>
        </div>
    </div>
  )
}
