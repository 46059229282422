import { Link, useNavigate } from "react-router-dom";
import "../Dashboard.css";
import "./Hamburger.css";
import "./EllipsisMenuDropDown.css";
import React, { useState } from "react";
import { Drawer, Dropdown, Menu, Tooltip } from "antd";

import SidebarMenu from "../Sidebar/SidebarMenu";
import { ReactComponent as HamburgerMenu } from "../../../Icons/hamburger-menu.svg";
import { ReactComponent as MenuEllipsis } from "../../../Icons/menu-ellipsis.svg";
import { ReactComponent as Profile } from "../../../Icons/profile-img.svg";
import { ReactComponent as Notificaton } from "../../../Icons/Notification-icons.svg";
import { ReactComponent as Logout } from "../../../Icons/Log-out-icon.svg";
import { ReactComponent as HelpSupport } from "../../../Icons/Help-icon.svg";

import LogOutPopUp from "./LogOutPopUp";

function SearchProfile(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const[text,setText] =  useState()
  const searchHandel = (e) => {
    // setText(e.target.value)
    if (window.location.pathname === "/clients/addnewclients") {
      // props.setSeachClientsName(null)
      // console.log("send null ins the input")
    }
    props.setSeachClientsName(e.target.value);
  };

  const openAgentProfile = () => {
    navigate("/agent");
  };
  const openHmburgerMenu = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const agentName = localStorage.getItem("agentName");
 

  const redirectToHelpSupport = () => {
    window.open("https://support.kuants.in", "_blank");
  };

  const openPopUp = () => {
    setIsModalOpen(true);
  };

  const items = [
    {
      label: <a href="https://www.antgroup.com">1st menu item</a>,
      key: "0",
    },
    {
      label: <a href="https://www.aliyun.com">2nd menu item</a>,
      key: "1",
    },
  ];

  return (
    <div>
      <div
        className="serach-profile-container"
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        //   marginLeft: "3.33vw",
        //   width: "72.22vw",
        // }}
      >
        <div className="hamburger-menu-container">
          <HamburgerMenu onClick={openHmburgerMenu} />
        </div>
        <input
        // style={{visibility :  window.location.pathname ==="/clients" || window.location.pathname ==="/dashboard"? "visible": "hidden" }}
          // disabled={window.location.pathname === "/clients/addnewclients" ? true :false}
          type="text"
          placeholder="Search by client name"
          disabled={window.location.pathname ==="/clients" || window.location.pathname ==="/dashboard"?false : true}
          className="search-bar"
          onChange={searchHandel}
        />
        <div
          className="profile-container"
          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          //   gap: "0.48vw",
          //   alignItems: "center",
          //   marginTop: "3.41vh",
          // }}
        >
          <p className="client-name" onClick={openAgentProfile}>
            {agentName}
          </p>
          <div className="profile-image-container">
            <div className="profile-img" onClick={openAgentProfile}>
              <Profile width={"100%"} height={"100%"} />
            </div>
            
            <Dropdown
             
              overlay={
                <Menu   className="menu-dropdown-container">
                  {/* <Menu.Item key="0">
                    <Notificaton/>
                    Notification</Menu.Item> */}
                  <Menu.Item key="1" onClick={redirectToHelpSupport}>
           
                    <HelpSupport />
    
                    Help and support</Menu.Item>
                  <Menu.Item key="1" onClick={openPopUp}>
                    <Logout />
                    Logout</Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <MenuEllipsis onClick={(e) => e.preventDefault()} />
            </Dropdown>
          </div>
          <div className="icon-cont-inside-profile">
            {/* <Notificaton />
             */}
            {/* <a  href={"https://support.kuants.in"} target="blank"> */}
            <Tooltip title={"Help&Support"}>
            <HelpSupport onClick={redirectToHelpSupport} />
            </Tooltip>
            {/* </a> */}
            <Tooltip title={"Logout"}>
            <Logout onClick={openPopUp} />
            </Tooltip>
            <LogOutPopUp
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        </div>
      </div>
      <div className="drawer-menu-container">
        <Drawer
          className="hambergur-menu-drawer"
          placement="left"
          onClose={onClose}
          open={open}
        >
          {/* <div className=""> */}
          <img
            className="logo-png"
            src="/assets/Logo.png"
            alt=""
            style={{ width: "9.30vw" }}
          />
          <p className="organization-name">Kuants Wealth Private Limited</p>
          {/* <div> */}
          <SidebarMenu setOpen={setOpen} />
          {/* </div>
        </div> */}
        </Drawer>
      </div>
    </div>
  );
}
export default SearchProfile;
