import { message } from "antd";
import axios from "axios";
import React from "react";
import config from "../../../config";
import "./Event.css";
import { ReactComponent as Marriage } from "../../../Icons/marriage.svg";
import { ReactComponent as Birthday } from "../../../Icons/birthday.svg";
import { ReactComponent as UpcomingSip } from "../../../Icons/upcoming-sip-icon.svg";
import { ReactComponent as EmptyScreen } from "../../../Icons/emptyscreen.svg";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function Event(props) {
  const chngObject = (data) => {
    const tempeventdata = props.eventData;
    if (data.mail_sent_status === false) {
      data.mail_sent_status = true;
    }
    props.setEventData([...tempeventdata, data]);
  };
  const decrypted_access_token = decrypt(
    "access_token",
    localStorage.getItem("access_token")
  );

  const headers = {
    "Content-Type": "Application/json",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };
  const sendWish = (data) => {
    axios
      .post(
        config.sendEvent,
        {
          client_id: data.client_id ? data.client_id : data.sip_id,
          type_of_event: data.type_of_event,
          event_holder_name: data.event_holder_name
            ? data.event_holder_name
            : data.client_name,
          // relationship_with_client: data.relationship_with_client,
        },
        { headers: headers }
      )
      .then((res) => {
        // console.log("send wish", res);
        message.success("Mail Sent");
        chngObject(data);
      })
      .catch((error) => {
        // console.log("send wish failed", error);

        message.success("Email allready sent for this event");
      });
  };

  // console.log("length of events", props.eventData);
  return (
    <div
      className="event-main-container"
      // style={{ padding: "1.50em" }}
    >
      {props.eventData.length >  0 ? (
        <>
          {props.eventData.map((data, index) => {
            if (!data.mail_sent_status) {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "1vw",
                  }}
                >
                  <div
                    className="icon-eventdetails-cont"
                    // style={{ display: "flex", gap: "1.2vw" }}
                  >
                    <div className="event-icon-cont">
                      {data.type_of_event === "Wedding anniversary" ? (
                        <Marriage />
                      ) : "" || data.type_of_event === "Birthday" ? (
                        <Birthday />
                      ) : "" || data.type_of_event === "SIP Reminder" ? (
                        <UpcomingSip />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="holder-name-date-container">
                      <p className="name">
                        {data.type_of_event === "SIP Reminder"
                          ? data.client_name
                          : data.event_holder_name}
                      </p>
                      <div className="evnt-date-type-contianer">
                        <p className="date">
                          {data.type_of_event === "SIP Reminder"
                            ? data.sip_start_day
                            : data.event_date}
                        </p>
                        {data.type_of_event === "SIP Reminder" ? (
                          <>
                            <p className="date">|</p>
                            <p className="date">{"Upcoming SIP"}</p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="send-wishes-btn"
                      onClick={() => sendWish(data)}
                    >
                      {data.type_of_event === "SIP Reminder"
                        ? "Send message"
                        : "Send wishes"}
                    </button>
                  </div>
                </div>
              );
            }
          })}
        </>
      ) : (
        <div className="upcoming-event-empty-screen">
          <EmptyScreen />
          <h1>No upcoming events</h1>
          <p></p>
        </div>
      )}
    </div>
  );
}
