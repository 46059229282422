import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function FundGraph(props) {
  const labels = props.fundGraphDate;
 const options = {
    responsive: true,
    
    plugins: {
      legend: {
          display:false
      },
      title: {
        display: true,
      },
      labels:{
        display:false
      },
     
    },
    
    interaction: {
      intersect: false,
      mode: 'index',
  },
    // scales: {
    //   xAxesID: {
    //     display: false
    //   },
    //   yAxes: {
    //     display: false
    //   },
    // },
    
    scales: {
      x: {
        grid: {
          display: false
        },
        display:false
      },
   
      y: {
        grid: {
          display: false
        },
        display:false
        
      }
    }
  }
 const data = {
    labels,
    datasets: [
      {
        label: '',
        data: props.fundGraphNav,
        borderColor: '#6D42DA',
        backgroundColor: '#6D42DA',
        borderWidth:3,
        tension:0.5,
        pointRadius: 0,
        hoverBackgroundColor: '#6D42DA',
        pointHoverRadius: 5,
        hoverBorderColor: '#6D42DA',
        boxShadow: "2px 12px 6px rgba(0, 0, 0, 0.15)"
      },
   
    ],
  };
  const plugins= [{
    afterDraw: chart => {
        if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let yAxis = chart.scales.y;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#00000';
            ctx.stroke();
            ctx.restore();
        }
    }
}]

  return (
    <div className="graph-for-fund-detail" style={{width:"66.95vw",}}>
   <Line options={options} data={data} plugins={plugins} />
    </div>
  );
}
