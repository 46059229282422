import React from "react";
import "../Agent.css";
function PersonalForm(props) {
  const handelPinCode = (e) => {
    if (e.target.value.length === 7) return true;
    // if(props.pi)
    props.setPinCode(e.target.value);
  };
  return (
    <div className="agent-personal-detail-main-container"   
    //  style={{
    //   display: "flex",
    //   flexDirection: "column",
    //   gap: "2.22vw",
    //   marginTop: "1.67vw",
    //   marginLeft: "1.66vw",
    // }}
    >
        <input
          required
          type="text"
          readonly="readonly"
          style={{ cursor: "not-allowed" }}
          value={props.email}
          placeholder="Email"
          //  onChange={(e)=>props.setEmail(e.target.value)}
          // onChange={(e)=>{
          //   if (!isValidEmail(e.target.value)) {
          //     setError("Email is invalid");
          //   } else {
          //     setError(null);
          //     // setDisable(false)
          //   }
          //   setEmail(e.target.field.value)
          // }}
          className="email-phone-add-input"
        />

        {/* {error ? <p className="error-text">{error}</p> : ""} */}

        <span className="phone-input-prefix">
          +91-{" "}
          <input
            type="text"
            value={props.number}
            readonly="readonly"
            style={{ cursor: "not-allowed" }}
            // checked
            // pattern=" /^[0-9\b]+$/"
            // maxlength="10"
            required
            placeholder="Mobile no."
            // onChange={(e)=>props.setNumber(e.target.value)}
            // onChange={(e)=>{
            //   if(e.target.value.length === 11) return false
            //   props.setNumber(e.target.value)
            //  } }

            // onChange={handnum}

            className="input-phone"
          />
        </span>

        <input
          type="text"
          value={props.add01}
          required
          placeholder="Address line 01"
          onChange={(e) => props.setAdd01(e.target.value)}
          className="email-phone-add-input"
        />

        <input
          type="text"
          value={props.add02}
          required
          placeholder="Address line 02"
          onChange={(e) => props.setAdd02(e.target.value)}
          className="email-phone-add-input"
        />

        {/* <div style={{ display: "flex", flexDirection: "column", gap: "48px" }}> */}

        {/* <CountryDropdown
                className="state-drop-down"
                value={props.state}
              
                onChange={(val)=>props.setState(val)}
        
              />
          */}

        {/* <RegionDropdown
                defaultOptionLabel="Select city"
                country={props.state}
                value={props.city}
                onChange={(val)=>props.setCity(val)}
                
              /> */}

        {/* </div> */}

        <input
          type="number"
          value={props.pinCode}
          placeholder="Pin code"
          onChange={handelPinCode}
          className="email-phone-add-input"
        />
        <div className="error-message-div">
          {props.pinCodeError === true && props.pinCode.length < 6 ? (
            <p className="error-msg-text">Invalid Pin Code</p>
          ) : (
            ""
          )}
        </div>

        <select
          placeholder=" Select language"
          value={props.language}
          onChange={(e) => props.setlanguage(e.target.value)}
        >
          <option selected disabled value="Select language">
            Select language
          </option>
          <option value="Hindi">Hindi</option>
          <option value="English">English</option>
        </select>
        <span className="phone-input-prefix">
          ARN-
          <input
            type="text"
            value={props.arnNumber}
            placeholder="ARN-Number"
            onChange={(e) => props.setArnNumber(e.target.value)}
            className="input-phone"
          />
        </span>
        <div className="error-message-div" >
          {props.requiredFields === true ? (
            <p className="error-msg-text">Please check all the fields</p>
          ) : (
            ""
          )}
        </div>
    </div>
  );
}

export default PersonalForm;
