
import React, { useEffect, useState } from "react";
import "./ClientDetails.css";
import { useParams } from "react-router-dom";
import { Slider, Tooltip } from "antd";
import { ReactComponent as ToolTipICon } from "../../../../src/Icons/ToolTip.svg";

export default function WealthCalculator(props) {
  // console.log(props.xirrVal,"21323232",props.xirrVal>12);
  const windowWidth = window.screen.width;
  const params = useParams();
  const [interest, setInterest] = useState(10);
  const [actvToogleBtn, setActvtoggleBtn] = useState(1);
  // const [interest, setInterest] = useState()

  const [investedvalue, setInvestedValue] = useState(
    100000

    // window.location.pathname.split("/")[1] === "fundlist"
    //   ? 100000
    //   : props.totalInv?.portFolioData > 50000
    //   ? props.totalInv.portFolioData
    //   : 100000
    // //  100000
  );
  const [mnthlyVal, setMnthlyVal] = useState(10000);
  const [returnAmount, setReturnAmount] = useState(0);
  const [year, setYear] = useState(
    window.location.pathname.split("/")[1] === "fundlist" ? 5 : 5
  );
  const [fromSlider, setFromSlider] = useState(false);
  // const [interest, setInterest] = useState(
  //   props.xirrVal > 12 ? props.xirrVal : 12
  // );
  // console.log(props.fundCategory);

  const calToggelBtn = ["SIP", "Lumpsum"];

  // cal toggle handle function
  const toggleHandel = (index) => {
    setActvtoggleBtn(index);
    setInvestedValue(index === 0 ? 10000 : 100000)
    // console.log(index, actvToogleBtn);
  };

  // check intrest on every fund and set intrest only for the fund details page calculator
  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "fundlist") {
      // console.log(props.fundCategory);
      if (
        props.fundCategory === "Equity" ||
        props.fundCategory === "Solution Oriented"
      ) {
        // console.log(props.annualizedreturn5y);
        if (props.annualizedreturn5y > 12)
          setInterest(props.annualizedreturn5y);
        else setInterest(12);
      } else if (props.fundCategory === "Hybrid") {
        if (props.annualizedreturn5y > 10)
          setInterest(props.annualizedreturn5y);
        else setInterest(10);
      } else if (props.fundCategory === "Debt") {
        if (props.annualizedreturn5y > 8)
          setInterest(props.annualizedreturn5y * 100);
        else setInterest(8);
      } else if (props.fundCategory === "Liquid Fund") {
        if (props.annualizedreturn5y > 6)
          setInterest(props.annualizedreturn5y * 100);
        else setInterest(6);
      }
    }
  }, [props.fundCategory, props.annualizedreturn5y]);

  const marksForDesktop = {
    0: "1",
    7: "3",
    14: "5",
    21: "10",
    28: "15",
    35: "20",
    42: "25",
    49: "30",
  };
  const marksForMobile = {
    0: "1",
    14: "3",
    28: "5",
    42: "10",
    56: "15",
    70: "20",
    84: "25",
    98: "30",
  };

  const sipAndLumpsumCal = (numbr) => {
     const cpmndIntrest =  (interest/100)/12
        // console.log("cmpnd intrest",cpmndIntrest);
      
  
    if (actvToogleBtn === 1) {
      // console.log("claculate lumpsum");
      if (fromSlider) {
        setYear(
          parseInt(
            windowWidth < 767 ? marksForMobile[numbr] : marksForDesktop[numbr]
          )
        );
        const totalReturnValue =
          parseInt(investedvalue) *
          (1 + interest / 100) **
            parseInt(
              windowWidth < 767 ? marksForMobile[numbr] : marksForDesktop[numbr]
            );
        setReturnAmount(totalReturnValue.toFixed(2));
        // const totalInvestedvalue = (investedvalue)*parseInt(marksForDesktop[numbr])
      } else if (!fromSlider) {
        const totalReturnValue =
          parseInt(investedvalue) * (1 + interest / 100) ** parseInt(year);
        if (isNaN(totalReturnValue)) {
          setReturnAmount(0);
        } else setReturnAmount(totalReturnValue.toFixed(2));
        // const totalInvestedvalue = (investedvalue)*parseInt(marksForDesktop[numbr])
        // setTotalEnvestedVal(investedvalue)
      }
    } else {
      // console.log("calculating sip");
      //  setMnthlyVal(mnthlyVal * year)
      if (fromSlider) {
        // console.log("running fromSlider");
        setYear(
          parseInt(
            windowWidth < 767 ? marksForMobile[numbr] : marksForDesktop[numbr]
          )
        );

        let tenure = parseInt(
          windowWidth < 767 ? marksForMobile[numbr] : marksForDesktop[numbr]
        ) *
        12
   

        const totalInvstment = 
          parseInt(investedvalue) *
          parseInt(
            windowWidth < 767 ? marksForMobile[numbr] : marksForDesktop[numbr]
          ) *
          12;
     


        const totalReturnValue = parseInt(investedvalue * (Math.pow((1 + cpmndIntrest), tenure) - 1) * (1 + cpmndIntrest) / cpmndIntrest);
        // console.log("tnr 1",tenure);
      //  console.log("totalReturnValue: 1 " + totalReturnValue);
        setReturnAmount(totalReturnValue);


        setMnthlyVal(totalInvstment);
      } else if (!fromSlider) {

        let tenure = parseInt(year)*12
       
        // console.log("stop formslider");


        const totalInvstment = parseInt(investedvalue) * parseInt(year) * 12;
        setMnthlyVal(totalInvstment);


        const totalReturnValue =  parseInt(investedvalue * (Math.pow((1 + cpmndIntrest), tenure) - 1) * (1 + cpmndIntrest) / cpmndIntrest);
            
      //  console.log("totalReturnValue: 2 " + totalReturnValue);
      //  console.log("tnr 2",tenure);
        setReturnAmount(totalReturnValue);
        if (isNaN(totalReturnValue)) {
          setReturnAmount(0);
        } else setReturnAmount(totalReturnValue.toFixed(2));
      }
    }
  };

  useEffect(() => {
    sipAndLumpsumCal(year);
    setFromSlider(false);
  }, [fromSlider, investedvalue, interest, year, actvToogleBtn]);

  const handleInputValue = (e) => {
    setFromSlider(false);
    setInvestedValue(e.target.value);
    setMnthlyVal(e.target.value);
  };

  // useEffect(() => {
  //   setInterest(props.xirrVal > 12 ? props.xirrVal : 12);
  //   // console.log(interest);
  // }, [props.xirrVal]);

  return (
    <div className="wealth-calculator">
      <div className="cal-togle-btn-cont">
        {calToggelBtn.map((btn, index) => {
          return (
            <p
              className={
                actvToogleBtn === index ? "toggle-btn-actv" : "toggle-btn"
              }
              onClick={() => toggleHandel(index)}
            >
              {btn}
            </p>
          );
        })}
      </div>

      <div style={{ display: "flex", marginTop: "4.26vh", gap: "1vw" }}>
        <p className="wealth-subheading">
          {actvToogleBtn === 0 ? "Monthly SIP value:" : "Investment value:"}
        </p>
        <span>
          ₹
          <input
            type="number"
            // placeholder="₹"
            value={investedvalue}
            onChange={handleInputValue}
          />
        </span>
      </div>
      <div className="tenative-period-heading-contianer">
        <p className="wealth-subheading">Tenative period of years</p>
      </div>
      <div className="slider-container">
        <Slider
          marks={windowWidth < 767 ? marksForMobile : marksForDesktop}
          step={null}
          defaultValue={14}
          onChange={(n) => {
            setYear(n);
            setFromSlider(true);

            // sipAndLumpsumCal(n)
          }}
          tooltip={{ open: false }}
        />
      </div>

      <div
        className={
          window.location.pathname.split("/")[1] === "fundlist"
            ? "hdng_intrate_inpt_container_hide"
          :
          "hdng_intrate_inpt_container"
        }
      >
        <p>Expected rate of return (p.a.)</p>
        <span className="prctg-icn">
          <input
            value={interest}
            type="number"
            onChange={(e) => {
              if (e.target.value.length > 2) return false;
              setInterest(e.target.value > 30 ? 30 : e.target.value);
            }}
          />
          %
        </span>
      </div>

      <div
        className={
          window.location.pathname.split("/")[1] === "fundlist"
            ? "rtn-slider-container-hide"
          :
          "rtn-slider-container"
        }
      >
        <Slider
          defaultValue={10}
          max={30}
          value={interest}
          onChange={(percnt) => {
            setInterest(percnt);
            // console.log("percnt VAl", percnt);
          }}
        />
      </div>

      <div
        className="investment-return-value-container"
        // style={{ display: "flex", marginTop: "5.36vw", gap: "6.45vw" }}
      >
        <div>
          <p className="total-invsmt-text">Total investment</p>
          <p className="rupee">{`₹${
            actvToogleBtn === 1 ? investedvalue : mnthlyVal
          }`}</p>
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: ".5vw" }}>
            <p className="total-invsmt-text">
              Total returns in {`${year}`} years
            </p>

            <Tooltip
              title={
                window.location.pathname ===
                `/fundlist/funddetails/${params.isin}` ? (
                  "The final value is estimated using average returns applicable to the fund's category"
                ) : (
                  <p>
                    The final value is estimated using the average equity funds
                    returns based on client's current schemes.
                  </p>
                )
              }
            >
              <ToolTipICon width="1.11vw" height="1.11vw" />
            </Tooltip>
          </div>
          <p className="rupee">{`₹${returnAmount}`}</p>
        </div>
      </div>
    </div>
  );
}
