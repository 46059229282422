// import React, { useRef } from "react";
import "./DataTable.css";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Pagination from "../ClientnDetails/Pagination";
import Checkbox from "./Checkbox";
import SheduleMeetingModal from "./SheduleMeetingModal";
import SendEmailModal from "./SendEmailModal";
import { ReactComponent as Calendar } from "../../../Icons/calendar.svg";
import { ReactComponent as Email } from "../../../Icons/email.svg";
import { ReactComponent as Download } from "../../../Icons/download.svg";
import { ReactComponent as EmptySreen } from "../../../Icons/emptyscreen.svg";
import { Spin } from "antd";
// import { tuple } from "antd/lib/_util/type";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function DataTable(props) {
  const navigate = useNavigate();
  const [searchClientsName] = useOutletContext();

  const [loder, setLoder] = useState(true);
  const windowWidth = window.screen.width;
  const [clientsData, setClientData] = useState([]);
  const [tabledata, setTabledata] = useState([]);
  // const [dataShow, setDataShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(windowWidth < 767 ? 8 : 10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForMeeting, setIsModalOpenForMeeting] = useState(false);
  const [statusArr, setStatusArr] = useState([]);
  const [transactionArr, setTransactionArr] = useState([]);
  const [aumArr, setAumArr] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [clientNameInModal, setClientNameInModal] = useState("");
  const [clientEmailInModal, setClientEmailInModal] = useState("");
  const [clientIdForModal, setClientIdForModal] = useState();
  const [meetingMsg, setMeetingMsg] = useState();
  // const [currentItems ,setCurrentItem] =  useState([])
  // const[selectFilterValue,setSelectFilterValue] =  useState()
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;

  let currentItems;
  if (tabledata.length > 0) {
    currentItems = tabledata.slice(indexOfFirstItem, indexOfLastItem);
  } else {
    currentItems = tabledata;
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const showDetail = (data) => {
    // props.setClientName(data.full_name);
    // console.log(data);

    // props.setShowClietnsDetails(true);
    navigate(`/clients/client/${data.user_id ? data.user_id : data.temp_id}`);
  };
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };
  // console.log("input value isnside clients data table",searchClientsName)

  useEffect(() => {
    // ------------------------API-----_CALL-----------------------START

    axios
      .get(config.allClientTableData, { headers: headers })
      .then((res) => {
        if (res.data.data) {
          setClientData(res.data.data);
          setTabledata(res.data.data);
          setLoder(false);
          props.setSelections([
            ...props.selections,
            ...props.showOnlyActiveLeads.myName,
          ]);

          // console.log("client data table", res);
        }
      })
      .catch((error) => {
        // console.log("clients tab/le data error", error);
      });
  }, []);
  // useEffect(() => {
  // if(props.showOnlyActiveLeads.myName){
  //   props.setSelections([...props.selections,props.showOnlyActiveLeads.myName])
  // }

  // }, [props.showOnlyActiveLeads.myName])

  useEffect(() => {
    let tempArray = props.selections;

    // console.log("TEMPARRAY",tempArray);
    // console.log("length of selections", props.selections.length);

    let tempAumArray = [];
    let tempStatusArr = [];
    let tempTransactionArray = [];
    let tempTagsArr = [];

    for (let i = 0; i < tempArray.length; i++) {
      for (let j = 0; j < props.Selectclientstatus.length; j++) {
        if (tempArray[i] === props.Selectclientstatus[j]) {
          tempStatusArr.push(tempArray[i]);
          // console.log("AYA?");
        }
      }

      for (let k = 0; k < props.transactionPeriod.length; k++) {
        if (tempArray[i] === props.transactionPeriod[k]) {
          tempTransactionArray.push(tempArray[i]);
        }
      }
      for (let l = 0; l < props.AUMrange.length; l++) {
        if (tempArray[i] === props.AUMrange[l]) {
          tempAumArray.push(tempArray[i]);
        }
      }
      for (let m = 0; m < props.clientTags.length; m++) {
        if (tempArray[i] === props.clientTags[m]) {
          tempTagsArr.push(tempArray[i]);
        }
      }
    }
    // console.log("List of All Filters Applied",tempArray,tempTransactionArray,tempAumArray,tempStatusArr);
    // console.log("status asrr",tempStatusArr);
    setStatusArr(tempStatusArr);
    props.setFistFilterSelctedValue(tempStatusArr);
    setTransactionArr(tempTransactionArray);
    props.setScndFilterSelctedValue(tempTransactionArray);
    setAumArr(tempAumArray);
    props.setThirdFilterSelctedValue(tempAumArray);
    setTagsArr(tempTagsArr);
    props.setFourthFilterSelctedValue(tempTagsArr);
  }, [props.selections]);

  useEffect(() => {
    // console.log("clientsData Step 1: Complete Data", clientsData)

    let clientMainData = clientsData;
    // console.log("CLIENTMAIN DATA",clientMainData);
    // console.log(statusArr.length,"statusArr.length ", statusArr )
    if (statusArr.length > 0) {
      // console.log("AYA?",statusArr.length);
      const statusFilter = clientMainData.filter((data) => {
        for (let i = 0; i < statusArr.length; i++) {
          if (statusArr[i].toLowerCase() === data.client_status.toLowerCase()) {
            return data;
          }
        }
      });
      // console.log("statusFilter Step 2", statusFilter)

      clientMainData = statusFilter;
    }

    if (transactionArr.length > 0) {
      // console.log("Length of TRansaction Selection", transactionArr.length)
      const transactionFilter = clientMainData.filter((data) => {
        for (let i = 0; i < transactionArr.length; i++) {
          let tableDate = "";
          if (transactionArr[i] === "Last 1 month") {
            tableDate = new Date(data.last_transaction.split("T")[0]);
            const currentDate = new Date().getMonth();

            if (tableDate.getMonth() + 1 === currentDate) {
              return data;
            }
          }
          // console.log("1 Month Done",transactionArr[i])
          if (transactionArr[i] === "Last 3 months") {
            tableDate = new Date(data.last_transaction.split("T")[0]);
            const currentDate = new Date().getMonth();
            if (tableDate.getMonth() - 2 < currentDate) {
              return data;
            }
          }
          if (transactionArr[i] === "Last 6 months") {
            tableDate = new Date(data.last_transaction.split("T")[0]);
            const currentDate = new Date().getMonth();

            if (tableDate.getMonth() - 5 < currentDate) {
              return data;
            }
          }
          if (transactionArr[i] === "Last year") {
            tableDate = new Date(data.last_transaction.split("T")[0]);
            const currentDate = new Date().getMonth();

            if (tableDate.getMonth() - 11 <= currentDate) {
              // console.log("GET DATA",data);
              return data;
            }
          }
          // console.log(transactionArr[i]);
          if (transactionArr[i] === "All time") {
            return data;
          }
        }
      });
      console.log("Date of Transaction Filter Step 4", transactionFilter);
      clientMainData = transactionFilter;
    }

    if (aumArr.length > 0) {
      // console.log("Aum ARR length", aumArr.length);
      const aumFilter = clientMainData.filter((data) => {
        for (let i = 0; i < aumArr.length; i++) {
          if (aumArr[i] === "< ₹5,000") {
            if (data.total_aum < "5000") {
              // console.log("GET DATA", data);
              return data;
            }
          }
          if (aumArr[i] === "₹5,000 - ₹10,000") {
            if (data.total_aum > "5000" && data.total_aum <= "10000") {
              // console.log("Yash's Data, data.total_aum", data.total_aum);
              return data;
            }
          }
          if (aumArr[i] === "₹10,000 - ₹50,000") {
            if (data.total_aum > "10000" && data.total_aum <= "50000") {
              // console.log("GET DATA", data);
              return data;
            }
          }
          if (aumArr[i] === "50,000 - ₹1,00,000") {
            if (data.total_aum > "50000" && data.table === "100000") {
              // console.log("GET DATA", data);
              return data;
            }
          }
        }
      });
      // console.log("aumFilter Step 3", aumFilter)

      clientMainData = aumFilter;
    }
    // console.log("TAGS ARAY ",tagsArr);

    if (tagsArr.length > 0) {
      const tagsFilter = clientMainData.filter((data) => {
        for (let j = 0; j < tagsArr.length; j++) {
          if (data.client_tags) {
            for (let i = 0; i < data.client_tags.length; i++) {
              console.log(data.client_tags[i]);
              if (tagsArr[j] === data.client_tags[i]) {
                // console.log("FILTER  DATA" ,data);
                return data;
              }
            }
          }
        }
      });
      // console.log("TAGS FIter data FTERAPLIED",tagsFilter);
      clientMainData = tagsFilter;

      // clientMainData = tagsFilter
    }
    //  console.log("Final Data: ")
    setTabledata(clientMainData);
  }, [statusArr, aumArr, transactionArr]);

  // console.log("VALUE", val);
  const showModal = (data) => {
    setClientIdForModal(data.user_id);
    axios
      .get(
        `${config.customEmail}/${data.user_id}`,

        { headers: headers }
      )
      .then((res) => {
        if (res.data) {
          setClientNameInModal(res.data.client_name);
          setClientEmailInModal(res.data.client_email);
        }

        // console.log("get custom eamil",res);
      })
      .catch((error) => {
        // console.log("get custome email",error);
      });

    setIsModalOpen(true);
  };
  const showModalMeeting = (data) => {
    setIsModalOpenForMeeting(true);
    setClientIdForModal(data.user_id);
    axios
      .get(`${config.meetingscheduleMail}/${data.user_id}`, {
        headers: headers,
      })
      .then((res) => {
        setMeetingMsg(res.data.data);
        // console.log("GET MAIL FOR MEETING", res);
      })
      .catch((error) => {
        // console.log("GET MAIL FOR MEETING ERROR", error);
      });
    axios
      .get(`${config.meetingscheduleLink}/${data.user_id}`, {
        headers: headers,
      })
      .then((res) => {
        // console.log("GET link FOR MEETING", res);
      })
      .catch((error) => {
        // console.log("GET Link FOR MEETING ERROR", error);
      });
  };
  // console.log("MEETING MASG", meetingMsg);

  return (
    <div>
      <div className="clietns-data-table">
        {currentItems.length > 0 ? (
          <div className="table-style-one">
            <table>
              <tr>
                <th>Sno.</th>
                <th 
                // style={{ paddingLeft: "0.8vw" }}
                >Client name</th>
                <th>Status</th>
                <th style={{ textAlign: "center" }}>Last Transaction</th>
                <th style={{ textAlign: "center" }}>Last Communication</th>
                <th>AUM</th>
                <th>Tags</th>
                <th>Actions</th>
              </tr>
              {currentItems.filter((data)=>{
                if(searchClientsName ===""){
                  return data
                }
                else if(data.full_name? data.full_name.toLowerCase().includes(searchClientsName.toLowerCase()):""){
                  return data
                }
              }).map((data, index) => {
                return (
                  <tr>
                    <td>{`${indexOfFirstItem + index + 1}`}</td>

                    <td
                      style={{
                        fontWeight:"400",
                        cursor: "pointer",
                        
                        whiteSpace:
                          data.full_name ? data.full_name.length > 14 ? "wrap" :"nowrap":"",
                        // paddingLeft: "0.8vw",
                      }}
                      onClick={() => showDetail(data)}
                    >
                      {data.full_name
                        ? data.full_name.replace(
                            /(\w)(\w*)/g,
                            function (g0, g1, g2) {
                              return g1.toUpperCase() + g2.toLowerCase();
                            }
                          )
                        : "-"}
                    </td>

                    <td
                      style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                      onClick={() => showDetail(data)}
                    >
                      {data.client_status.length > 15
                        ? data.client_status.slice(0, 15) + "..."
                        : data.client_status}
                    </td>
                    <td
                      style={{ cursor: "pointer", textAlign: "center" }}
                      onClick={() => showDetail(data)}
                    >{`${data.last_transaction.split("T")[0]}
                  `}</td>
                    <td
                      style={{ cursor: "pointer", textAlign: "center" }}
                      onClick={() => showDetail(data)}
                    >{`${
                      data.last_communication
                        ? data.last_communication.split("T")[0]
                        : ""
                    }`}</td>

                    <td
                      style={{ cursor: "pointer", textAlign: "initial" }}
                      onClick={() => showDetail(data)}
                    >
                      &#8377;{data.total_aum ? data.total_aum : "0"}
                    </td>
                    <td style={{ cursor: "pointer" }}>
                      <Checkbox clientData={data} />
                    </td>
                    <td>
                      <div
                        className="comunication-icon-container"
                        // style={{
                        //   display: "flex",
                        //   gap: "1.80vw",
                        //   alignItems: "center",
                        //   cursor: "pointer",
                        // }}
                      >
                        <Calendar
                          onClick={() => showModalMeeting(data)}
                          width="1.31vw"
                          height="1.53vw"
                        />
                        <Email
                          onClick={() => showModal(data)}
                          width="1.66vw"
                          height="1.11vw"
                        />
                        {/* <Download/> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
              <SheduleMeetingModal
                meetingMsg={meetingMsg}
                clientIdForModal={clientIdForModal}
                setMeetingMsg={setMeetingMsg}
                setIsModalOpenForMeeting={setIsModalOpenForMeeting}
                isModalOpenForMeeting={isModalOpenForMeeting}
              />
              <SendEmailModal
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                clientNameInModal={clientNameInModal}
                clientEmailInModal={clientEmailInModal}
                clientIdForModal={clientIdForModal}
              />
            </table>

            <Pagination
              itemPerPage={itemPerPage}
              totalUsers={tabledata.length}
              paginate={paginate}
              currentPage={currentPage}
              handleNextPage={() =>
                setCurrentPage(
                  currentPage <= tabledata.length
                    ? currentPage + 1
                    : currentPage
                )
              }
              handlePrevPage={() => setCurrentPage(currentPage - 1)}
            />
          </div>
        ) : (
          <div
            className={
              loder === true
                ? "client-table-loder-screen"
                : "client-table-empty-screen"
            }
          >
            {loder === true ? (
              <Spin size="large" />
            ) : (
              <>
                <EmptySreen />
                <h1>No clients onboarded</h1>
                <p>
                  Add new clients in just a few clicks and view their summary
                  here.
                </p>
              </>
            )}
          </div>
        )}
      </div>
      <div className="pagination-in-mob">
        {currentItems.length > 0 ? (
          <Pagination
            itemPerPage={itemPerPage}
            totalUsers={tabledata.length}
            paginate={paginate}
            currentPage={currentPage}
            handleNextPage={() =>
              setCurrentPage(
                currentPage <= tabledata.length ? currentPage + 1 : currentPage
              )
            }
            handlePrevPage={() => setCurrentPage(currentPage - 1)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
