import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useRef } from "react";
import "./ImgShareMod.css";
import { ReactComponent as PoweredLogo } from "../../Icons/powered-logo.svg";
import { ReactComponent as Kuants } from "../../Icons/www.kuants.in.svg";
import { ReactComponent as Download } from "../../Icons/Img_download.svg";
import { ReactComponent as Share } from "../../Icons/Img_share.svg";
import { useState } from "react";
import axios from "axios";
import config from "../../config";
import LinkShareModal from "./LinkShareModal";
import { decrypt } from "../TockenConversion/JwtConvert";
export default function ImageShareModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [img, setImg] = useState();
  const[agentEmail,setAgentEmail] = useState()
  const[agentNumbe,setAgentNumber] = useState()
  const[agentArn,setAgentArn] = useState()

  // console.log("ImageShareModal" ,  agentPropDtl)
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));
  
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };

  useEffect(() => {
    axios
      .get(config.agentPersonalDetail, { headers: headers })
      .then((response) => {
        setAgentEmail(response.data.agent_email_id)
        setAgentArn(response.data.arn_number)
        setAgentNumber(response.data.contact_number)
        // console.log("img share modal", response);
      })
      .catch((error) => {
        // console.log("error img share modla", error);
      });
  }, [props.openImgMod]);


  const handleOk = () => {
    props.setOpenImgMod(false);
  };

  const handleCancel = () => {
    props.setOpenImgMod(false);
  };


  useEffect(() => {
    setImg()
    axios
      .post(
        config.coBrandImg,
        { content_id: props.imageUrl.id, image_url: props.imageUrl.url_image },
        { headers: headers }
      )
      .then((response) => {
        setImg(response.data.brand_image_url);
        // console.log("response: ", response);
      })
      .catch((error) => {
        // console.error("error: ", error);
      });
  }, [props.openImgMod]);

  return (
    <>
      <Modal
        l
        className="mrktg-img-shr-modal"
        open={props.openImgMod}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* <canvas id="canvas1"   ref={canvasRef}/> */}
        <div id="my-img" className="new-my-img-div">
          <div className="my-img-div">
            <img
              width={"100%"}
              height={"100%"}
              src={props.imageUrl.url_image}
            />
          </div>

          <div className="info-div-cont">
            <span className="info-head">
              Name:
              <h6 className="info-sbhd">{localStorage.getItem("agentName")}</h6>
            </span>
            <span className="info-head">
              Phone:<h6 className="info-sbhd">+91-{agentNumbe ? agentNumbe:"-"}</h6>
            </span>
            <span className="info-head">
              Email:<h6 className="info-sbhd">{agentEmail?agentEmail:"-"}</h6>
            </span>
            <span className="info-head">
              ARN:<h6 className="info-sbhd">{agentArn?agentArn:"-"}</h6>
            </span>
          </div>
          <div className="footer-div-cont">
            <PoweredLogo />
            <Kuants />
          </div>
        </div>
        <div className="shr-dwld-btn">
          <a href={img} download>
            <Download />
          </a>

          <Share onClick={() => setIsModalOpen(true)} />
        </div>
      </Modal>

      <LinkShareModal
        imageUrl={img}
        tittleUrl={"some"}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
    </>
  );
}
