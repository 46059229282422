import React from "react";
import { useState, useEffect } from "react";
import "../login_Page/login.css";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import LoginLeftPart from "../login_Page/LoginLeftPart";
// import SendOtp from "./SendOtp";
import "antd/dist/antd.min.css";
import { notification, message } from "antd";
import axios from "axios";
// import config from "../../config";

import { ReactComponent as CLoseEye } from "../../Icons/closeEye.svg";
import { ReactComponent as OpenEye } from "../../Icons/openEye.svg";
import config from "../../config";

export default function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userOtp, setUserOtp] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [showPass, setShowPassword] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Reset link sent to your email",
      duration: 2,
      closeIcon: <></>,
    });
  };
  // const showError = () => {
  //   message.error({
  //     content: "Invalid credentials",
  //     className: "custom-class",
  //     style: {
  //       marginTop: "56vh",
  //       marginLeft: "20vw",

  //     },
  //   });
  // };
  const showOtpError = () => {
    message.error({
      content: "OTP does not match",
      className: "custom-class",
      style: {
        marginTop: "5vh",
        marginLeft: "77vw",
      },
    });
  };
  // const showErrorUserNotExist = () => {
  //   message.error({
  //     content: "This email does not exist",
  //     className: "custom-class",
  //     style: {
  //       marginTop: "5vh",
  //       marginLeft: "77vw",
  //     },
  //   });
  // };
  const showErrorUserNotExist = (type) => {
    notification[type]({
      message: "This email does not exist",
      duration: 2,
      closeIcon: <></>,
    });
  };

  // if(window.location.pathname ==="/"){
  //   localStorage.clear()
  // }

  useEffect(() => {
    if (window.location.pathname === "/") {
      localStorage.clear();
    }
  }, [window.location.pathname]);

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(0);
  };

  // ----------------------------OTP Request Function---------------------
  const otpRequest = () => {
    setPassword("");
  };
  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
  };

  const agentforgetpassword = () => {
    axios
      .post(
        config.forgotPassword,
        { agent_email_id: email },
        { headers: headers }
      )
      .then((res) => {
        setEmail("")
        // console.log("forget password", res);

        // if (res.data) {
        //   // setSendOtp(true);

          openNotificationWithIcon("success");
          navigate("/")
        //   setSendOtp(true);
        // }
      })
      .catch((error) => {
        // console.log("forget password error", error);
        showErrorUserNotExist("error");
        // if (error.response.data.non_field_errors[0]) {
        //   showErrorUserNotExist();

        //   // console.log("error function call");
        // }
      });
  };

  // -----------------------------Login Function-------------------
  //   const loginSuccess = () => {
  //     if (sendOtp === true) {
  //       const headers = {
  //         "Content-Type": "application/json",
  //         // "Access-Control-Allow-Origin":"*",
  //         Accept: "application/json",
  //       };

  //       axios
  //         .post(
  //           config.signupOtp,
  //           {
  //             otp_generated: userOtp.split(" ").join(""),
  //             contact_number: mobileNumber,
  //           },
  //           { headers: headers }
  //         )
  //         .then((res) => {
  //           console.log("login otp verfy seccfly", res);
  //           if (res.data.message) {
  //             props.setLoged(true);
  //             navigate("/dashboard");
  //             message.success("Login successful");
  //           }
  //           localStorage.setItem("access_token", res.data.access);
  //           localStorage.setItem("refresh_token", res.data.refresh);
  //           localStorage.setItem("agentName", res.data.agent_name);
  //         })
  //         .catch((error) => {
  //           console.log("login otp vrfy fail error", error);
  //           if (error.response.data.non_field_errors) {
  //             showOtpError();
  //             // showError();
  //             // message.error("OTP does not match")
  //           }
  //         });
  //     } else {
  //       const headers = {
  //         "Content-Type": "application/json",
  //         // "Access-Control-Allow-Origin":"*",
  //         Accept: "application/json",
  //       };
  //       axios
  //         .post(
  //           config.login,
  //           { agent_email_id: mobileNumber, password: password },
  //           { headers: headers }
  //         )
  //         .then((res) => {
  //           console.log("login succ", res);

  //           if (res.data.message) {
  //             // props.setLoged(true)
  //             localStorage.setItem("access_token", res.data.access);
  //             localStorage.setItem("refresh_token", res.data.refresh);
  //             localStorage.setItem("agentName", res.data.agent_name);
  //             props.setLoged(true);
  //             navigate("/dashboard");
  //             message.success("Login successful");
  //           }

  //           // <Link to="/dashboard"/>
  //         })
  //         .catch((error) => {
  //           console.log("login error", error);
  //           if (error.response.data.non_field_errors) {
  //             // showError("error");
  //             setLoginError(true);
  //           }
  //           // setLoginError("Id password does not matched")
  //         });
  //     }
  //   };

  const numberEmailChange = (e) => {
    const val = e.target.value;
    setEmail(val);
  };
  const handelEnterKey = (event) => {
    if (event.keyCode === 13) {
      // console.log("key press")
      if (email) {
        // loginSuccess();
      }
    }
  };

  // const showPassword = () => {
  //   if (password) {
  //     if (showPass === true) {
  //       setShowPassword(false);
  //     } else {
  //       setShowPassword(true);
  //     }
  //   }
  // };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Helmet>
        <title>Login</title>
        {/* <link rel="icon" href="/public/faveicon.ico" /> */}
        <link
          rel="icon"
          type="image/png"
          href="./././public/feicon.ico"
          sizes="16x16"
        />
      </Helmet>

      <LoginLeftPart />

      <div
        className="login-container"
      
      >
        <img className="logo-img" src="/assets/Logo.png" alt="Loding...." />
        <h4 className="login-text">Forgot password</h4>

        <div className="login-input-container">
          <input
            className="phone-input"
            type="text"
            value={email}
            placeholder="Enter Your email"
            required
            onChange={numberEmailChange}
            onKeyDown={handelEnterKey}
          />
     
        
        </div>

        <button
          disabled={email ? false : true}
          className={`login-btn ${email ? "active-login-btn" : ""}`}
          type="submit"
          onClick={agentforgetpassword}
        >
          Reset
        </button>
 
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            width: "23.7vw",
            minWidth: "306px",
         
          }}
        >
          <p className="Forget-text" style={{ visibility: "hidden" }}>
            Forgot password?
          </p>
          <Link
            to="/"
            style={{
        
              whiteSpace: "nowrap",
              display: "flex",
         
              gap: "3.2px",
            }}
          >
            <p className="new-on-text">New on Kuants?</p>

            <h6 className="Sign-up-btn">Sign-in</h6>
          </Link>
        </div>
      </div>
    </div>
  );
}
