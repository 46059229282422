import React from "react";
import { Modal, Button, message, DatePicker, Select } from "antd";
import Filters from "../Filter/Filters";
import { useState } from "react";
import axios from "axios";
import config from "../../../config";
import { decrypt } from "../../TockenConversion/JwtConvert";
const time = ["10:00"];
const startTimeArr = [
  { value: "Select start time" },
  { value: "00:00" },
  { value: "00:30" },
  { value: "01:00" },
  { value: "01:30" },
  { value: "02:00" },
  { value: "02:30" },
  { value: "03:00" },
  { value: "03:30" },
  { value: "04:00" },
  { value: "04:30" },
  { value: "05:00" },
  { value: "05:30" },
  { value: "06:00" },
  { value: "06:30" },
  { value: "07:00" },
  { value: "07:30" },
  { value: "08:00" },
  { value: "08:30" },
  { value: "09:00" },
  { value: "09:30" },
  { value: "10:00" },
  { value: "10:30" },
  { value: "11:00" },
  { value: "11:30" },
  { value: "12:00" },
  { value: "12:30" },
  { value: "13:00" },
  { value: "13:30" },
  { value: "14:00" },
  { value: "14:30" },
  { value: "15:00" },
  { value: "15:30" },
  { value: "16:00" },
  { value: "16:30" },
  { value: "17:00" },
  { value: "17:30" },
  { value: "18:00" },
  { value: "18:30" },
  { value: "19:00" },
  { value: "19:30" },
  { value: "20:00" },
  { value: "20:30" },
  { value: "21:00" },
  { value: "21:30" },
  { value: "22:00" },
  { value: "22:30" },
  { value: "23:00" },
  { value: "23:30" },
];
const endTimeArr = [
  { value: "Select end time" },
  { value: "00:00" },
  { value: "00:30" },
  { value: "01:00" },
  { value: "01:30" },
  { value: "02:00" },
  { value: "02:30" },
  { value: "03:00" },
  { value: "03:30" },
  { value: "04:00" },
  { value: "04:30" },
  { value: "05:00" },
  { value: "05:30" },
  { value: "06:00" },
  { value: "06:30" },
  { value: "07:00" },
  { value: "07:30" },
  { value: "08:00" },
  { value: "08:30" },
  { value: "09:00" },
  { value: "09:30" },
  { value: "10:00" },
  { value: "10:30" },
  { value: "11:00" },
  { value: "11:30" },
  { value: "12:00" },
  { value: "12:30" },
  { value: "13:00" },
  { value: "13:30" },
  { value: "14:00" },
  { value: "14:30" },
  { value: "15:00" },
  { value: "15:30" },
  { value: "16:00" },
  { value: "16:30" },
  { value: "17:00" },
  { value: "17:30" },
  { value: "18:00" },
  { value: "18:30" },
  { value: "19:00" },
  { value: "19:30" },
  { value: "20:00" },
  { value: "20:30" },
  { value: "21:00" },
  { value: "21:30" },
  { value: "22:00" },
  { value: "22:30" },
  { value: "23:00" },
  { value: "23:30" },
];

export default function SheduleMeetingModal(props) {
  const [startTime, setStartTime] = useState(startTimeArr[0]);
  const [endTime, setEndTime] = useState(endTimeArr[0]);
  const [pickerDate, setPickerDate] = useState(null);
  const [meetingDate, setMeetingDate] = useState(null);
  const [meetingStartTime, setMeetingStartTime] = useState(false);
  const [meetingEndTime, setMeetingEndTime] = useState(false);
  // console.log("MEETIMG hgdfhjdsfjhdsf",props.MeetingMsg);
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };

  const handleSend = () => {
    axios
      .post(
        config.meetingscheduleMail,
        {
          meeting_date: meetingDate,
          meeting_start: startTime,
          meeting_end: endTime,
          client_id: props.clientIdForModal,
        },
        { headers: headers }
      )
      .then((res) => {
        if (res.data) {
          setStartTime(startTimeArr[0]);
          setEndTime(endTimeArr[0]);
          setPickerDate(null);
          message.success("Meeting scheduled successfully");
        }
        // console.log("post link FOR MEETING", res);
      })
      .catch((error) => {
        // console.log("post Link FOR MEETING ERROR", error);
      });
    props.setIsModalOpenForMeeting(false);
  };

  const handleCancel = () => {
    props.setIsModalOpenForMeeting(false);
    setPickerDate(null);
    setStartTime(startTimeArr[0]);
    setEndTime(endTimeArr[0]);
  };


  return (
    <div className="modal-cont">
      <Modal
        className="client-table-modal schedule-table-modal"
        title="Schedule Meeting"
        open={props.isModalOpenForMeeting}
        onOk={handleSend}
        onCancel={handleCancel}
        footer={[
          <Button
            className="save-btn"
            onClick={handleSend}
            // loading={loading}
          >
            Send
          </Button>,
          <Button className="cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div className="date-input-cont">
            <DatePicker
              format={"DD/MM/YYYY"}
              allowClear={false}
              value={pickerDate}
              placeholder="Date (DD/MM/YYYY)"
              suffixIcon
              className="add-event-input"
              onChange={(e) => {
                let temp =
                  e._d.getFullYear() +
                  "-" +
                  (e._d.getMonth() + 1) +
                  "-" +
                  e._d.getDate();
                console.log(temp);
                // let temp=e._d.getDate()+"/"+(e._d.getMonth()+1)+"/"+e._d.getFullYear()
                setPickerDate(e);
                setMeetingDate(temp);
              }}
            />
          </div>
          <div className="main-select-time-cont">
            <div className="select-time-cont">
         
              <Select
                bordered={false}
                listHeight={190}
                placeholder={"Select start time"}
                onSelect={(e) => {setStartTime(e)
                  //  setMeetingStartTime(false)
                }}
                value={startTime}
                // value={ meetingStartTime === true ? startTime:startTimeArr[0]}
                // defaultValue={ meetingStartTime === true? startTimeArr[21]:startTimeArr[0]}
                defaultValue={startTimeArr[0]}
                options={startTimeArr}
                className="down-arrow"
                // onClick={(e)=> setMeetingStartTime(!meetingStartTime)}
              />
            </div>
            <div className="select-time-cont">
              <Select
                bordered={false}
                dropdownStyle={{}}
                listHeight={190}
                placeholder={"Select start time"}
                onSelect={(e) => {setEndTime(e)
                // setMeetingEndTime(false)
                }}
                // value={ meetingEndTime === true ? endTime :endTimeArr[0]}
                value={endTime}
                defaultValue={endTimeArr[0]}
                // defaultValue={ meetingEndTime === true ? endTimeArr[21]:endTimeArr[0]}
                // onClick={(e) => setMeetingEndTime(!meetingEndTime)}
                options={endTimeArr}
                className="down-arrow"
              />

              {/* <Filters options={startTimeArr} setSelectOptn={setStartTime} />
                <Filters options={endTimeArr} setSelectOptn={setEndTime} /> */}
            </div>
            {/* <Select         options={endTimeArr}/> */}
          </div>

          <div className="text-area-cont">
            <h5>Message</h5>
            <textarea
              // style={{ width: "38.95vw", height: "16.11vw" }}
              placeholder="Type message..."
              value={props.meetingMsg}
              onChange={(e) => props.setMeetingMsg(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
