import axios from "axios";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import Pagination from "../Clients/ClientnDetails/Pagination";
import CheckBoxFilter from "../Clients/Filter/CheckBoxFilter";
// import ToolTip from  "../../ToolTip.svg"
import { CSVLink } from "react-csv";
import { ReactComponent as ToolTipICon } from "../../../src/Icons/ToolTip.svg";
import { ReactComponent as CsvDownlaod } from "../../../src/Icons/csvColoredDownload.svg";
import { ReactComponent as EmptySreen } from "../../Icons/emptyscreen.svg";
import { ReactComponent as FilterIcon } from "../../Icons/filter-icon.svg";
import { decrypt } from "../TockenConversion/JwtConvert";
import "./Transaction.css";
import { Tooltip ,Spin} from "antd";
export default function Transaction() {
  //   console.log("SELECTED VALUES",selections);
  const windowWidth = window.screen.width;
  const navigate = useNavigate();
  const[loder,setLoder] =  useState(true)
  const [selections, setSelections] = useState([]);
  const [allSelect,setAllSelect] = useState([])
  const [transactionData, setTransactionData] = useState([]);
  const [transactionsTableData, setTransactionsTableData] = useState([]);
  const [clientNameArr, setClientNameArr] = useState([]);
  const [investmentArr, setInvestmentArr] = useState([]);
  const [transactionPeriodArr, setTransactionPeriodArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  const [byClientName, setByClientName] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(windowWidth < 767 ? 8 : 10);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const[isFilterInMobile,setIsFilterInMobile] = useState(false)

  let currentItems;
  if (transactionsTableData.length > 0) {
    currentItems = transactionsTableData.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    // console.log("if reach");
  }
  //   else if (transactionData.length !== 1) {
  //     currentItems = transactionData.slice(indexOfFirstItem, indexOfLastItem);
  //   }
  else {
    currentItems = transactionsTableData;
  }
  // console.log("curent item ", currentItems);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const byInvestment = [
    "< ₹5,000",
    "₹5,000 - ₹10,000",
    "₹10,000 - ₹50,000",
    "₹50,000 - ₹1,00,000",
    ">₹1,00,000",
  ];
  const transactionPeriod = [
    "Last 1 Month",
    "Last 3 Months",
    "Last 6 Months",
    "Last Year",
    "All Time",
  ];
  const Bystatus = ["Submitted", "Pending", "Successful", "Failed"];

  // console.log("knflfelflfef",wid);

// setTimeout(() => {
//   setLoder(false)
// }, 2000);

  useEffect(() => {

    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };

    axios
      .get(config.transactions, { headers: headers })
      .then((res) => {
        setTransactionData(res.data);
        setTransactionsTableData(res.data);
        setLoder(false)
        // setByClientName(res.data.full_name)
        // if(res.data.length > 0){
        //   setLoder(false)
        //   console.log("loder deactivate");
        // }
        // console.log("GET transaction DATA", res);
      })
      .catch((error) => {
        // console.log("GET transaction DATA ERROR", error);
      });

    axios
      .get(config.clientName, { headers: headers })
      .then((res) => {
        // console.log("GET client Name", res);
        //   let d =  res.data.replace(
        //     /(\w)(\w*)/g,
        //      function (g0, g1, g2) {
        //       return g1.toUpperCase() + g2.toLowerCase();
        //      }
        //  )
        //  console.log("GET client Name", d);
        setByClientName(res.data);
        // console.log("GET client Name", res);
      })
      .catch((error) => {
        // console.log("GET client Name error", error);
      });
  }, []);

  //   console.log("GET DATA FROM API AND SAVE HERE",transactionData,transactionsTableData)

  let tempArray = selections;
  // console.log("TEMP ARRA AFTER SELECTIOJ");
  useEffect(() => {
    let tempCientNameArr = [];
    let tempInvestmentArr = [];
    let tempTransactionPeriodArray = [];
    let tempStatusArr = [];

    for (let i = 0; i < tempArray.length; i++) {
      for (let j = 0; j < byClientName.length; j++) {
        if (tempArray[i] === byClientName[j]) {
          tempCientNameArr.push(tempArray[i]);
        }
      }
      if (
        tempArray[i] === "< ₹5,000" ||
        tempArray[i] === "₹5,000 - ₹10,000" ||
        tempArray[i] === "₹10,000 - ₹50,000" ||
        tempArray[i] === "₹50,000 - ₹1,00,000"
      ) {
        tempInvestmentArr.push(tempArray[i]);
      }

      if (
        tempArray[i] === "Last 1 Month" ||
        tempArray[i] === "Last 3 Months" ||
        tempArray[i] === "Last 6 Months" ||
        tempArray[i] === "Last Year"
      ) {
        tempTransactionPeriodArray.push(tempArray[i]);
      }

      for (let m = 0; m < Bystatus.length; m++) {
        if (tempArray[i] === Bystatus[m]) {
          tempStatusArr.push(tempArray[i]);
        }
      }
    }

    //  console.log("TEMP STATUS ARRAY",tempStatusArr);

    setClientNameArr(tempCientNameArr);
    setInvestmentArr(tempInvestmentArr);
    setTransactionPeriodArr(tempTransactionPeriodArray);
    setStatusArr(tempStatusArr);
  }, [selections]);

  useEffect(() => {
    let trsactionMainData = transactionData;

    if (clientNameArr.length > 0) {
      const clientNameFilter = trsactionMainData.filter((data) => {
        for (let i = 0; i < clientNameArr.length; i++) {
          if (clientNameArr[i].toLowerCase() === data.full_name.toLowerCase()) {
            return data;
          }
        }
      });
      trsactionMainData = clientNameFilter;
    }

    if (investmentArr.length > 0) {
      // console.log("investmentArr length",investmentArr);
      const investMentFilter = trsactionMainData.filter((data) => {
        for (let i = 0; i < investmentArr.length; i++) {
          if (investmentArr[i] === "< ₹5,000") {
            if (parseInt(data.amount) < 5000) {
              // console.log("GET DATA", data);
              return data;
            }
          }
          if (investmentArr[i] === "₹5,000 - ₹10,000") {
            if (
              parseInt(data.amount) > 5000 &&
              parseInt(data.amount) <= 10000
            ) {
              // console.log("Yash's Data, data.amount", data.amount);
              return data;
            }
          }
          if (investmentArr[i] === "₹10,000 - ₹50,000") {
            if (
              parseInt(data.amount) > 10000 &&
              parseInt(data.amount) <= 50000
            ) {
              // console.log("GET DATA", data);
              return data;
            }
          }
          if (investmentArr[i] === "₹50,000 - ₹1,00,000") {
            if (
              parseInt(data.amount) > 50000 &&
              parseInt(data.amount) <= 100000
            ) {
              // console.log("GET DATA", data);
              return data;
            }
          }
        }
      });
      // console.log("aumFilter Step 3", aumFilter)
      trsactionMainData = investMentFilter;
    }

    if (transactionPeriodArr.length > 0) {
      // console.log("Length of TRansaction Selection", transactionArr.length)
      const transactionFilter = trsactionMainData.filter((data) => {
        for (let i = 0; i < transactionPeriodArr.length; i++) {
          let tableDate = "";
          if (transactionPeriodArr[i] === "Last 1 Month") {
            tableDate = new Date(data.created_at.split("T")[0]);
            const currentDate = new Date().getMonth();

            if (tableDate.getMonth() + 1 === currentDate) {
              return data;
            }
          }
          // console.log("1 Month Done",transactionPeriodArr[i])
          if (transactionPeriodArr[i] === "Last 3 Months") {
            tableDate = new Date(data.created_at.split("T")[0]);
            const currentDate = new Date().getMonth();
            if (tableDate.getMonth() - 2 < currentDate) {
              return data;
            }
          }
          if (transactionPeriodArr[i] === "Last 6 Months") {
            tableDate = new Date(data.created_at.split("T")[0]);
            const currentDate = new Date().getMonth();

            if (tableDate.getMonth() - 5 < currentDate) {
              return data;
            }
          }
          if (transactionPeriodArr[i] === "Last Year") {
            tableDate = new Date(data.created_at.split("T")[0]);
            const currentDate = new Date().getMonth();

            if (tableDate.getMonth() - 12 <= currentDate) {
              // console.log("GET DATA",data);
              //    console.log("InSIDE LAST year MONTH")

              return data;
            }
          }
        }
      });
      // console.log("Date of Transaction Filter Step 4", transactionFilter)
      trsactionMainData = transactionFilter;
    }

    if (statusArr.length > 0) {
      // console.log("TRNSACTION STATUS ARRAY", statusArr);

      const statusFilter = trsactionMainData.filter((data) => {
        for (let m = 0; m < statusArr.length; m++) {
          let sts = data.order_state.toLowerCase();
          let arr = statusArr[m].toLowerCase();

          if (arr === sts) {
            return data;
          }
        }
      });
      trsactionMainData = statusFilter;
    }

    setTransactionsTableData(trsactionMainData);
  }, [clientNameArr, investmentArr, transactionPeriodArr, statusArr]);

  const csvAllTransactionsHeader = [
    { label: "Client name", key: "full_name" },
    { label: "Fund Name", key: "fund_scheme_name" },
    { label: "Transaction ID", key: "order_id" },
    { label: "Investment", key: "amount" },
    { label: "Transaction date", key: "created_at" },
    { label: "Status", key: "order_state" },
  ];
const clearALlFilter =()=>{
  setSelections([])
  setAllSelect([])

}
const showFiltersInMobile =()=>{
  setIsFilterInMobile(!isFilterInMobile);
}
  return (
    <div className="transaction-main-cont">
      <Helmet>
        <title>Transactions</title>
        <link rel="icon" href="faveicon.ico" />
      </Helmet>
      <div style={{ display: "flex", alignItems: "baseline", gap: "0.3vw" }}>
        <p className="clients-text" onClick={() => navigate("/dashboard")}>
          Dashboard{" "}
        </p>
        <p className="client-name disable-hover">/ {"Transactions"}</p>
      </div>
      <div className="filter-heading-cont">
        <FilterIcon onClick={showFiltersInMobile}/>
        <p className="filter-heading">Filters</p>
        <p className="clear-all-filter-text" onClick={clearALlFilter}>Clear all filters</p>

      </div>
      <div
        className={isFilterInMobile ? "checkbox-filter-container-in-mob":"checkbox-filter-container"} 
        // style={{
        //   display: "flex",
        //   gap: "1.66vw",
        //   flexWrap: "wrap",
        //   width: "75vw",
        //   rowGap: "2.24vw",
        // }}
      >
        <CheckBoxFilter
          filterValue={byClientName}
          filterName={"By Client"}
          searchPlaceholder={"Seach by Client"}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={clientNameArr}
        />
        <CheckBoxFilter
          filterValue={byInvestment}
          filterName={"By Investment"}
          searchPlaceholder={"Seach by investment"}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={investmentArr}
        />
        <CheckBoxFilter
          filterValue={transactionPeriod}
          filterName={"By Transaction Period"}
          searchPlaceholder={"Seach by transaction"}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={transactionPeriodArr}
        />
        <CheckBoxFilter
          filterValue={Bystatus}
          filterName={"By Status"}
          searchPlaceholder={"Seach by status"}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={statusArr}
        />
      </div>
      <div className="transaction-heading">
        <h1> Transactions</h1>
        <div className="downloadtxt-png-cont">
          <h6>Download all</h6>
          <CSVLink
            headers={csvAllTransactionsHeader}
            filename={"all-transactions.csv"}
            data={transactionsTableData}
          >
            <CsvDownlaod />
          </CSVLink>
        </div>
      </div>

      <div className="trasaction-table-cont">
        {currentItems.length > 0 ? (
        <div  className="table-style-one">
          <table>
            <tr>
              <th>Client name</th>
              <th>Fund name</th>
              <th>Transaction ID</th>
              <th>Investment</th>
              <th style={{ textAlign: "center", paddingLeft: "0" }}>
                Transaction date
              </th>
              <th>
                <div
                  className="tooltip-icn-cont"
                  style={{
                    display: "flex",
                    gap: "0.37vw",
                    alignItems: "center",
                  }}
                >
                  Status
                  <Tooltip
                  placement="left"
                  // className="transaction-tooltip"
                    title={
                      <ul>
                        <li style={{ color: "#ffff", fontSize: "0.75vw" }}>
                          SUBMITTED: Order has been submitted to the fund house
                        </li>
                        <li style={{ color: "#ffff", fontSize: "0.75vw" }}>
                          PENDING: Order is pending for confirmation from the
                          fund house
                        </li>
                        <li style={{ color: "#ffff", fontSize: "0.75vw" }}>
                          SUCCESS: Order has been successfully processed & units
                          have been allotted
                        </li>
                        <li style={{ color: "#ffff", fontSize: "0.75vw" }}>
                          FAILED: Order could not be processed & any amount
                          deducted will be reversed in 5-7 business days.
                        </li>
                      </ul>
                    }
                  >
                    <ToolTipICon width="1.11vw" height="1.11vw" />
                  </Tooltip>
                </div>
              </th>
            </tr>

            {currentItems.map((data) => {
              return (
                <tr>
                  <td style={{ cursor: "default" ,whiteSpace:"nowrap" }}>
                    {data.full_name
                      ? data.full_name.replace(
                          /(\w)(\w*)/g,
                          function (g0, g1, g2) {
                            return g1.toUpperCase() + g2.toLowerCase();
                          }
                        )
                      : "-"}
                  </td>
                  <Tooltip placement="right" title={data.fund_scheme_name}>
                    <td style={{ whiteSpace: "nowrap", cursor: "default" }}>
                      {data.fund_scheme_name
                        ? data.fund_scheme_name.length > 50
                          ? data.fund_scheme_name.slice(0, 50) + "..."
                          : data.fund_scheme_name
                        : "-"}
                    </td>
                  </Tooltip>
                  <Tooltip className="trnsctn-page-tltp" placement="left" title={data.order_id}>
                    <td style={{ cursor: "default" }}>
                      {data.order_id ? data.order_id.slice(0, 10) : "-"}...
                    </td>
                  </Tooltip>
                  <td style={{ cursor: "default" }}>&#8377;{data.amount}</td>
                  <td style={{ textAlign: "center", paddingLeft: "0" }}>
                    {data.created_at ? data.created_at.split("T")[0] : "-"}
                  </td>
                  <td style={{ cursor: "default" }}>
                    {data.order_state
                      ? data.order_state.replace(
                          /(\w)(\w*)/g,
                          function (g0, g1, g2) {
                            return g1.toUpperCase() + g2.toLowerCase();
                          }
                        )
                      : "-"}{" "}
                  </td>
                </tr>
              );
            })}
          </table>
          </div>
        ) : (
          <div className={loder === true? "client-table-loder-screen":"client-table-empty-screen"}>
            {loder==true ? <Spin size="large" /> :  
            <>
           <EmptySreen />
            <h1>No transactions executed</h1>
            <p>
              Start investing for your clients and view their transaction
              summary here.
            </p>
            </>
             }
          </div>
        )}
        {currentItems.length > 0 ? (
          <Pagination
            itemPerPage={itemPerPage}
            totalUsers={transactionsTableData.length}
            paginate={paginate}
            currentPage={currentPage}
            handleNextPage={() =>
              setCurrentPage(
                currentPage <= transactionsTableData.length
                  ? currentPage + 1
                  : currentPage
              )
            }
            handlePrevPage={() => setCurrentPage(currentPage - 1)}
          />
        ) : (
          ""
        )}
      </div>
      <div className="pagination-in-mob">
        {currentItems.length > 0 ? (
          <Pagination
            itemPerPage={itemPerPage}
            totalUsers={transactionsTableData.length}
            paginate={paginate}
            currentPage={currentPage}
            handleNextPage={() =>
              setCurrentPage(
                currentPage <= transactionsTableData.length
                  ? currentPage + 1
                  : currentPage
              )
            }
            handlePrevPage={() => setCurrentPage(currentPage - 1)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
