import React, { useState, } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from '@mui/material/Button';
// import Button from "@material-ui/core/Button";
// import Paper from "@material-ui/core/Paper";
// import Typography from "@material-ui/core/Typography";
import "./Agent.css";

import {  FormProvider } from "react-hook-form";
import BankDetailForm from "./Steps/BankDetailForm";
import PersonalForm from "./Steps/PersonalForm";
import AgreementUpload from "./Steps/AgreementUpload";
// import ProductsPlan from "./Steps/ProductsPlan";
// import MarketingCampaigns from "./Steps/MarketingCampaigns.jsx";
import OtherLinks from "./Steps/OtherLinks";
// import { useState } from "react";
import axios from "axios";
import config from "../../config";
import PersonalOverViewForm from "./Steps/PersonalOverViewForm";
import BankDetailOverViewForm from "./Steps/BankDetailOverViewForm";
import OtherLinkOverView from "./Steps/OtherLinkOverView";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../TockenConversion/JwtConvert";
function getSteps() {
  return [
    "Personal details",
    "Bank details",

    // "Marketing campaigns",
    // "Products & plan",
    "Other details",
    "Upload agreement",
  ];
}

function InfoSteper(props) {
const navigate = useNavigate()



  const [fillDetails, setFillDetails] = useState(false);
  const [detailView, setDetailView] = useState(true);
  // const [agreementDoc, setAgreementDoc] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  // const [completed, setCompleted] = React.useState(new Set());
  const [completed, setCompleted] = useState({});

  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [add01, setAdd01] = useState();
  const [add02, setAdd02] = useState();
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [pinCodeError, setPinCodeError] =  useState(false)
  const [language, setlanguage] = useState();
  const [arnNumber, setArnNumber] = useState("");

  const [accountNum, setAccountNum] = useState("");
  const [reAccountNum, setReAccountNum] = useState("");
  const[invalideAccountNum, setInvalideAccountNum] = useState(false);
  const [ifsc, setIfsc] = useState("");
  const[ifscCodeError,setIfscCodeError] = useState(false)
  const [holderName, setHolderName] = useState("");
  const [requiredFields, setRequiredFields] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [fbLink, setFbLink] = useState("");
  const [linkdin, setLinkdin] = useState("");
  const [instLink, setInstaLink] = useState("");
  const [webLink, setWebLink] = useState("");
  const [microSiteLink, setMicroSiteLink] = useState("");
  const [companyName, setCompanyName] = useState("");
  // console.log(" active state :", activeStep);

  // const totalSteps = () => {
  //   return getSteps().length;
  // };

  // const completedSteps = () => {
  //   return Object.keys(completed).length;
  // };
  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   // handleNext();
  // };






  function getStepContent(step) {
    switch (step) {
      case 0:
        return detailView ? (
          <PersonalOverViewForm
            setFillDetails={setFillDetails}
            setDetailView={setDetailView}
            setEmail={setEmail}
            setNumber={setNumber}
            setAdd01={setAdd01}
            setAdd02={setAdd02}
            setState={setState}
            setCity={setCity}
            setPinCode={setPinCode}
            setlanguage={setlanguage}
            setArnNumber={setArnNumber}
            handleComplete={handleComplete}
            arnNumber={arnNumber}
            email={email}
            number={number}
            state={state}
            city={city}
            add01={add01}
            add02={add02}
            pinCode={pinCode}
            language={language}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        ) : (
          <PersonalForm
            setFillDetails={setFillDetails}
            setEmail={setEmail}
            setNumber={setNumber}
            setAdd01={setAdd01}
            setAdd02={setAdd02}
            setState={setState}
            setCity={setCity}
            setPinCode={setPinCode}
            setlanguage={setlanguage}
            setArnNumber={setArnNumber}
            arnNumber={arnNumber}
            email={email}
            number={number}
            state={state}
            city={city}
            add01={add01}
            add02={add02}
            pinCode={pinCode}
            pinCodeError={pinCodeError}
            language={language}
            requiredFields={requiredFields}
          />
        );
      case 1:
        return detailView ? (
          <BankDetailOverViewForm
            setFillDetails={setFillDetails}
            setActiveStep={setActiveStep}
            setDetailView={setDetailView}
            setAccountNum={setAccountNum}
            setReAccountNum={setReAccountNum}
            setIfsc={setIfsc}
            setIfscCodeError={setIfscCodeError}
            setHolderName={setHolderName}
            handleComplete={handleComplete}
            accountNum={accountNum}
            reAccountNum={reAccountNum}
       
            ifsc={ifsc}
           
            holderName={holderName}
            
          />
        ) : (
          <BankDetailForm
            setFillDetails={setFillDetails}
            setAccountNum={setAccountNum}
            setInvalideAccountNum={setInvalideAccountNum}
            setReAccountNum={setReAccountNum}
            setShowErrorMessage={setShowErrorMessage}
            setRequiredFields={setRequiredFields}
            showErrorMessage={showErrorMessage}
            requiredFields={requiredFields}
            setIfsc={setIfsc}

            ifscCodeError={ifscCodeError}
            setHolderName={setHolderName}
            accountNum={accountNum}
            invalideAccountNum={invalideAccountNum}
            reAccountNum={reAccountNum}
            ifsc={ifsc}
            holderName={holderName}
            
          />
        );

      // case 3:
      //   return <MarketingCampaigns setFillDetails={setFillDetails} />;
      // case 4:
      //   return <ProductsPlan setFillDetails={setFillDetails} />;
      case 2:
        return detailView ? (
          <OtherLinkOverView
            setDetailView={setDetailView}
            setActiveStep={setActiveStep}
            setFillDetails={setFillDetails}
            activeStep={activeStep}
            setFbLink={setFbLink}
            setLinkdin={setLinkdin}
            setInstaLink={setInstaLink}
            setWebLink={setWebLink}
            setMicroSiteLink={setMicroSiteLink}
            setCompanyName={setCompanyName}
            handleComplete={handleComplete}
          />
        ) : (
          <OtherLinks
            // setFillDetails={setFillDetails}
            activeStep={activeStep}
            setFillDetails={setFillDetails}
            setFbLink={setFbLink}
            setLinkdin={setLinkdin}
            setInstaLink={setInstaLink}
            setWebLink={setWebLink}
            setMicroSiteLink={setMicroSiteLink}
            setCompanyName={setCompanyName}
            fbLink={fbLink}
            instLink={instLink}
            linkdin={linkdin}
            webLink={webLink}
            microSiteLink={microSiteLink}
            companyName={companyName}
          />
        );
      case 3:
        return (
          <AgreementUpload
          setActiveStep={setActiveStep}
            setFillDetails={setFillDetails}
            // agreementDoc={agreementDoc}
            handleComplete={handleComplete}
            activeStep={activeStep}
            // setAgreementDoc={setAgreementDoc}
          />
        );
      default:
        return "Unknown step";
    }
  }

  const steps = getSteps();
  // console.log("steps",steps)

  const blankfunction = () => {
    // console.log("blank function");
    if (activeStep === 1) {
      if (!accountNum) {
        // setShowErrorMessage(true)
        setRequiredFields(true);
        // console.log("SHOW ERROR MESSAGE");
      }
      if (!reAccountNum) {
        setRequiredFields(true);
      }
      if(!ifsc){
        setRequiredFields(true);
      }
      if(!holderName){
        setRequiredFields(true);
      }
      if(accountNum === reAccountNum && accountNum.length < 8){
        // console.log("invalid account number");
        // setRequiredFields(true);
        setInvalideAccountNum(true)
      }
    }
    if(activeStep === 0){
      if(! add01 || !add02 || !pinCode|| !language){
        setRequiredFields(true)
      }
      if(pinCode.length<6){
        setPinCodeError(true)
      }
    }
  };

  const saveAndContinue = (e) => {
    // console.log("fvsjfgjfwkfwekjfwqgfqgfkqgv");
    e.preventDefault();
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };

    //  ------------PersonalForm---API----CALL-----------
    if (activeStep === 0) {
      // console.log("move from personal ");
      axios
        .post(
          config.agentPersonalDetail,
          {
            agent_address_line1:
              add01 + " , " + add02,
            agent_address_pincode: pinCode,
            agent_selected_language: language,
            arn_number: arnNumber?arnNumber:null,
          },
          { headers: headers }
        )
        .then((res) => {
          // console.log("agent personal post rqst detail", res);
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setDetailView(true);
          setRequiredFields(false)
          handleComplete()
          // setFillDetails(true);
          //  setDetailView(false);
        })
        .catch((error) => {
          // console.log("agent personal post rqst detail error", error);
          // setDetailView(false);
        });
    }

    // ----------------------------BankDetailForm--------API-----------CALL-------
    else if (activeStep === 1) {
      // console.log("mover from banking");

      axios
        .post(
          config.agentBankingDetail,
          {
            agent_bank_account_number_1: accountNum,
            agent_bank_ifsc_1: ifsc,
            agent_bank_account_name: holderName,
          },
          { headers: headers }
        )
        .then((res) => {
          // console.log("agent bank post detail", res);
          // setFillDetails(false);
          setDetailView(true);
          setIfscCodeError(false)
          handleComplete()
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch((error) => {
          // console.log("agent bank  post detail error", error);
          if(error.response.data)
          setIfscCodeError(true)
        });
    }

    // ----------OtherLinks------API-------CALl------------
    else if (activeStep === 2) {
      // console.log("mover from other lik");
      // console.log("fb link",fbLink)
      axios
        .post(
          config.otherLinks,
          {
            agent_facebook_acount: fbLink,
            agent_instagram_page: instLink,
            agent_linkedin_acount: linkdin,
            agent_website: webLink,
            agent_micro_site_name: microSiteLink,
            agent_company_name: companyName,
          },
          { headers: headers }
        )
        .then((res) => {
          // console.log("agent otherlink post response", res);
          setDetailView(true);
          handleComplete()
        })
        .catch((error) => {
          // console.log("agent other post link error ", error);
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  // const handleSubmit = (data) => {
  //   console.log(data);
  // };
  const handleStep = (step) => () => {
    // if (step === activeStep - 1){
      setActiveStep(step);
    // }
    
  };
  // const totalSteps = () => {
  //   return steps.length;
  // };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    // handleNext();
  };
  return (
    <div className="stepper-main-container">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel
              onClick={handleStep(index)
              //   () => {
              //   if (activeStep === 0) {
              //     return false;
              //   } else if (index === activeStep - 1) {
              //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
              //   }
              // }
            }
            >
              {label}
            </StepLabel>
            <StepContent>
              <div>
                <div>
                  <FormProvider>
                    {getStepContent(activeStep)}

                    <p
                      onClick={
                        (email &&
                          add01 &&
               
                          pinCode.length ===6 &&
                          number &&
                          language &&
                          activeStep === 0) ||
                        (accountNum.length>7 &&
                          reAccountNum.length >7 &&
                          ifsc &&
                          // ifscCodeError===true &&
                          holderName &&
                          showErrorMessage=== false &&
                          activeStep === 1) ||
                          activeStep === 2
                          ? saveAndContinue
                          : blankfunction
                      }
                      // className= "save-next-btn"
                      // disabled={email && add01 && add02 && pinCode && number && language ? true : false}
                      className={`${
                        fillDetails === true
                          ? "save-next-btn"
                          : "hide-save-next-btn"
                      }`}
                    >
                      {activeStep === 7 ? "Skip" : "Save and continue"}
                    </p>
                  </FormProvider>
                </div>
              </div>
            </StepContent>
          </Step>
        ))} 
        <button
        disabled={completedSteps() === steps.length? false: true}
          className={`reqst-btn ${
            completedSteps() === steps.length ? "reqst-btn-active" : ""
          }`}
          onClick ={()=>navigate("/dashboard")}
        >
          {activeStep === steps.length ? "Done" : "Continue"}
        </button>
      </Stepper>
    </div>
  );
}

export default InfoSteper;
