import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./CheckBoxFilter.css";
import { ReactComponent as SearchIcon } from "../../../Icons/search-icon.svg";
import Item from "antd/lib/list/Item";
import { Tooltip } from "antd";
export default function CheckBoxFilter(props) {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  // const[transactionPrd,setTransactionPrd]= useState([])
  const [expandedBlur, setExpandedBlur] = useState(true);
  const [inputVal, setInputVal] = useState("");

  // console.log("expand",expanded)
  const toggleExpanded = (event) => {
    // console.log("exoand",event)
    if (!expanded) {
      setExpanded(true);
      // console.log("expand",expanded)
    } else {
      setExpanded(false);
      props.setFilterValue("");
      setInputVal("");
      // console.log("after closeexpand",expanded)
    }
  };
  const displayAmcName = [
    "Aditya Birla Sun Life AMC Ltd.",
    "DSP Investment Managers Private Ltd.",
    "HDFC Asset Management Company Ltd.",
    "Kotak Mahindra Asset Management Co Ltd.",
    "ICICI Prudential Asset Management Company Ltd.",
    "Nippon Life India Asset Management Ltd.",
    "Taurus Asset Management Company Ltd.",
    "Franklin Templeton Asst Mgmt(IND)Pvt Ltd.",
    "Canara Robeco Asset Management Co. Ltd.",
    "Sundaram Asset Management Company Ltd.",
    "SBI Funds Management Ltd.",
    "UTI Asset Management Company Ltd.",
    "Tata Asset Management Ltd.",
    "Quantum Asset Management Co Pvt. Ltd.",
    "Invesco Asset Management (India) Private Ltd.",
    "Mirae Asset Investment Managers (India) Private Ltd.",
    "IDFC Asset Management Company Ltd.",
    "Edelweiss Asset Management Ltd.",
    "Axis Asset Management Company Ltd.",
    "Navi AMC Ltd.",
    "IDBI Asset Management Ltd.",
    "Motilal Oswal Asset Management Co. Ltd.",
    "BNP Paribas Asset Mgmt India Pvt. Ltd.",
    "Union Asset Management Co. Pvt. Ltd..",
    "PPFAS Asset Management Pvt. Ltd.",
    "IIFL Asset Management Ltd.",
    "Mahindra Manulife Investment Management Pvt. Ltd..",
    "ITI Asset Management Ltd.",
    "WhiteOak Capital Asset Management Ltd.",
  ];

  // let tempArr =  []
  // useEffect(() => {
  //   console.log("expand",expanded)
  // }, [expanded]);

  const handleChange = (event) => {
    // console.log("change",event.target.value);

    if (event.target.checked) {
      props.setSelections([...props.selections, event.target.value]);
    } else {
      let tempData = event.target.value;
      let tempDataArray = props.selections;
      let tempCurrent = [];

      for (let i = 0; i < tempDataArray.length; i++) {
        if (tempDataArray[i] == tempData) {
        } else {
          // console.log(tempDataArray[i]);
          tempCurrent.push(tempDataArray[i]);
        }
      }

      props.setSelections(tempCurrent);
      props.setActivate(false);
    }
    console.log(props.selections);
  };
  // console.log("FILTER VALUE",props.filterValue)

  const clearAllSelectedValue = () => {
    let temp = props.selections;
    temp = props.selections.filter(
      (el) => !props.filterSelectedValue.includes(el)
    );
    props.setSelections([...temp]);
    props.setAllSelect([]);
  };

  const selectAllItems = (event) => {
    if (event.target.checked) {
      // console.log("selectAllItems");
      props.setSelections([...props.selections, ...props.filterValue]);
      props.setAllSelect([...props.allSelect, event.target.value]);
      // console.log("props.props.allSelect value: " ,props.props.allSelect)
    } else {
      clearAllSelectedValue();
      // console.log("unselectAllItems");
    }
  };

  useEffect(() => {
    // if (expanded === false) {
      moveOnTop();
      // console.log("expanded" ,expanded);
    // }
  }, [expanded === false]);


  const moveOnTop = () => {
    // props.filterValue.map((d,index)=>{
    //   if (props.selections.includes(props.filterValue[index])) {
    //         let element = props.filterValue.splice(index, 1);
    //         props.filterValue.unshift(element[0]);
    //       }
    // })
    for (let i = 0; i < props.filterValue.length; i++) {
      if (props.selections.includes(props.filterValue[i])) {
        let element = props.filterValue.splice(i, 1);
        props.filterValue.unshift(element[0]);
      }
    }
  };

  return (
    <div
      className="checkbox-filter-cont"
      onBlur={() => {
        if (!expandedBlur) {
          setExpanded(true);
        } else {
          setExpanded(false);
          setInputVal("");
          // console.log(“false else condition”);
        }
      }}
    >
      <div onClick={toggleExpanded} className="click-div" tabIndex="-1">
        {props.filterSelectedValue.length === 0 ? (
          <h6 className="selecttag-text">{props.filterName}</h6>
        ) : (
          <h6 className="selecttag-text">
            {
              // .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
              //           return g1.toUpperCase() + g2.toLowerCase();
              //         })
              props.filterSelectedValue[0].length > 25
                ? props.filterSelectedValue[0]
                    // .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                    //   return g1.toUpperCase() + g2.toLowerCase();
                    // })
                    .slice(0, 25) + "..."
                : props.filterSelectedValue[0]
              // .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
              //   return g1.toUpperCase() + g2.toLowerCase();
              // })
            }{" "}
            {props.filterSelectedValue.length > 1 ? "+" : ""}{" "}
            {props.filterSelectedValue.length > 1
              ? props.filterSelectedValue.length - 1
              : ""}
          </h6>
        )}
        <div
          className={`font-semibold cursor-pointer ${
            expanded ? "up-arrow" : "down-arrow"
          }`}
        ></div>
      </div>

      {expanded && (
        <div
          className={`expand-cont ${
            window.location.pathname === "/clients"
              ? "expand-cont-clients"
              : null
          }`}
          // tabIndex="-1"
          onFocus={() => {
            setExpandedBlur(false);
            // setInputVal("")
          }}
          onMouseEnter={() => {
            setExpandedBlur(false);
            // setInputVal("")
          }}
          onMouseLeave={() => {
            setExpandedBlur(true);
          }}
          onBlur={() => {
            setExpandedBlur(true);
          }}
        >
          <div
            className={`${
              window.location.pathname === "/clients"
                ? "search-cont-hide"
                : "search-cont"
            }`}
          >
            <input
              type="text"
              className="expand-cont-input"
              onChange={(event) => setInputVal(event.target.value)}
              placeholder={props.searchPlaceholder}
            />
            <SearchIcon />
            {/* <img src="/assets/search-icon.png" alt="" /> */}
          </div>
          <div className="heading-clearAll-cont" style={{ display: "flex" }}>
            <h1 className="heading">{props.filterName}</h1>
            <h6 onClick={clearAllSelectedValue}>Clear All</h6>
          </div>

          {/* <h1>{"ravi"}</h1> */}
          <div className="expand-cont-value">
            {props.filterName === "Select Tags" ? (
              <p className="profess-heading">Profession</p>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                marginLeft: "1.1vw",
                marginBottom: "1.31vw",
                marginTop: "0.76vw",
              }}
            >
              <label className="block">
                <input
                  type="checkbox"
                  value={props.filterName}
                  checked={
                    props.allSelect.find((e) => e === props.filterName)
                      ? true
                      : false
                  }
                  onChange={selectAllItems}
                ></input>
                All
              </label>
            </div>

            {props.filterValue
              .filter((data) => {
                if (inputVal === "") {
                  return data;
                } else if (
                  data.toLowerCase().includes(inputVal.toLowerCase())
                ) {
                  return data;
                }
              })

              .map((platform, index) => (
                // {amcName.map((d)=>{})}

                <div
                  style={{
                    display: "flex",
                    marginLeft: "1.1vw",
                    marginBottom: "1.31vw",
                  }}
                >
                  <label htmlFor="one" className="block" key={platform}>
                    <input
                      type="checkbox"
                      name={platform}
                      value={platform}
                      checked={
                        props.selections.find((e) => platform === e)
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      className="m-3 cursor-pointer"
                    />
                    {/* } */}

                    {
                      // displayAmcName[index]
                      props.filterName === "Select AMC" ? (
                        <Tooltip
                          placement={"right"}
                          title={
                            platform.includes("Limited")
                            ? platform.split("Limited")[0] + "Limited"
                            : platform.includes("Ltd")
                            ? platform.split("Ltd")[0] + "Ltd"
                            : platform.split("Limited")[0]}
                        >
                          <p style={{cursor:"pointer",margin:"unset"}}>{platform.slice(0, 20) + "..."}</p>
                        </Tooltip>
                      ) : (
                        // <Tooltip  title={displayAmcName[index]}>

                        // </Tooltip>
                        // platform.includes("Limited")
                        // ? platform.split("Limited")[0] + "Limited"
                        // : platform.includes("Ltd")
                        // ? platform.split("Ltd")[0] + "Ltd"
                        // : platform.split("Limited")[0]
                        platform
                      )
                      // .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                      //   return g1.toUpperCase() + g2.toLowerCase();
                      // })
                    }
                    {index === 4 && props.filterName === "Select Tags" ? (
                      <p className="investr-type-Heading">Investor Type</p>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
