import React, { useState, useEffect } from "react";
import "./ClientDetails";
import { Button, message, Modal } from "antd";
import axios from "axios";
import config from "../../../config";
import { useParams } from "react-router-dom";
import { ReactComponent as ColoredShare } from "../../../Icons/shareColored.svg";
import FundDetailShareModal from "../FundList/FundDetailShareModal";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function Recommendation(props) {
  const windowWidth = window.screen.width;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fundNameForModal, setFundNameForModal] = useState();
  const [isinForModal, setIsinForModal] = useState();
  const[senderData,setSenderData] =  useState([])

  const [loading, setLoading] = useState(false);
  const [getUrl, setGetUrl] = useState("");
  const [fundDetails, setFundDetails] = useState({});

  const params = useParams();
  const headers = {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin":"*",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      };
  const showModal = (data) => {
    setFundNameForModal(data.heading);
    setIsinForModal(data.isin_number);
    setIsModalOpen(true);
    axios.get(
      config.emailFunds,
      {headers:headers}
   
   )  .then((response) => {
     // console.log(response,"success get sender data");
     // console.log("full name", response.data[0].mobile);
     setSenderData(response.data)
   }).catch((error)=>{
     // console.log("get sender data error");
   })
    // console.log("REMND FUND DATA: ", data);
    //   const headers = {
    //     "Content-Type": "application/json",
    //     // "Access-Control-Allow-Origin":"*",
    //     Accept: "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //   };
    //   setIsModalOpen(true);
    //   setFundDetails({
    //     fund_name: fundName,
    //     client_id: params.id,
    //     fund_return: fndReturn,
    //   });
    //   axios
    //     .get(config.emailFunds, { headers: headers })
    //     .then((res) => {
    //       // console.log("get url",res);
    //       setGetUrl(res.data);
    //     })
    //     .catch((error) => {
    //       // console.log("get url error",error);
    //     });
    // };
    //   console.log("fund detail",fundDetails);

    // const handleOk = () => {
    //   setLoading(true);
    //   setTimeout(() => {
    //     setLoading(false);
    //     setIsModalOpen(false);
    //   }, 1000);
    // };

    // const handleCancel = () => {
    //   setIsModalOpen(false);
  };

  useEffect(() => {
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };
  }, []);

  // const shareMail = () => {
  //   const headers = {
  //     "Content-Type": "application/json",
  //     // "Access-Control-Allow-Origin":"*",
  //     Accept: "application/json",
  //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //   };
  //   axios
  //     .post(config.emailFunds, fundDetails, { headers: headers })
  //     .then((res) => {
  //       // console.log("send mail sccs", res);
  //       message.success("Mail sent successfully");
  //       setIsModalOpen(false);
  //     })
  //     .catch((error) => {
  //       // console.log("send mail fail", error);
  //     });
  // };

  // const shareMsg = () => {
  //   const headers = {
  //     "Content-Type": "application/json",
  //     // "Access-Control-Allow-Origin":"*",
  //     Accept: "application/json",
  //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //   };
  //   axios.post(config.smsFunds, { headers: headers });
  // };
  return (
    <>
      <div className="recmnd-data-container">
        {props.relatedFundData.map((data, index) => {
          return (
            <div className="rcmdfund-single-data-outer-container">
              <div className="rcmndfund-single-data-inner-container">
                <div className="logo-fundName-container">
                  <div className="rcmndfund-logo-cont">
                    <img
                      src={data.logo}
                      width="100%"
                      height="100%"
                      alt="loding.."
                    />
                  </div>
                  <div>
                    <p className="remnd-heading">{data.heading}</p>
                    <p className="remnd-subheading">{data.subheading}</p>
                  </div>
                </div>
                <div
                  className="rcmndfund-sharelogo-precnt-container"
                  // style={{ display: "flex", gap: "1vw", marginLeft: "1.89vw" }}
                >
                  <h6 className={`${data.percent <0?"negative-percentage-text":"percentage-text" }`}>{data.percent}%</h6>
                  <div
                    className="recmndfund-share-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => showModal(data)}
                  >
                    <ColoredShare />
                  </div>
                </div>
              </div>

              {windowWidth < 767 ? (
                index === 0 || index === 1 || index === 2 ? (
                  <hr className="horizntl-line" />
                ) : (
                  ""
                )
              ) : index === 0 || index === 1 ? (
                <hr className="horizntl-line" />
              ) : (
                ""
              )}
              {/* {index === 0 || index === 1 ? (
                <hr className="horizntl-line" />
              ) : (
                ""
              )} */}
            </div>
          );
        })}
      </div>
      <div>
        <FundDetailShareModal
          setIsModalOpen={setIsModalOpen}
          isinForModal={isinForModal}
          fundNameForModal={fundNameForModal}
          isModalOpen={isModalOpen}
          senderData={senderData}
        />
        {/* <Modal
          className="event-Share-modal"
          title="Share"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button className="copy-btn" onClick={handleOk} loading={loading}>
              Copy
            </Button>,
          ]}
        >
          <div style={{ marginLeft: "40px" }}>
            <div style={{ display: "flex", gap: "2.22vw" }}>
              <div>
                <div className="via-sms-contanier" onClick={shareMsg}>
                  <img src="/assets/sms.png" alt="loding.." />
                </div>
                <h6 className="via-text">via SMS</h6>
              </div>
              <div>
                <div className="via-sms-contanier" onClick={shareMail}>
                  <img src="/assets/mail.png" alt="loding.." />
                </div>
                <h6 className="via-text">via Mail</h6>
              </div>
            </div>
            <div className="copy-url-container">
              <h6 className="copy-url-text">Copy URL</h6>
              <input
                type="text"
                className="url-input"
                placeholder="https:www.kuants.in"
                value={getUrl}
              />
            </div>
          </div>
        </Modal> */}
      </div>
    </>
  );
}
