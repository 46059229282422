import React from "react";
import "../Dashboard.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import { ReactComponent as DashboardIcon } from "../../../Icons/Dashboard-icon.svg";
import { ReactComponent as DashboardWhiteIcon } from "../../../Icons/Dashboard-icon-white.svg";
import { ReactComponent as ClientIcon } from "../../../Icons/Clients-icon.svg";
import { ReactComponent as ClientWhiteIcon } from "../../../Icons/Clients-icon-white.svg";
import { ReactComponent as TransactionIcon } from "../../../Icons/Transactions-icon.svg";
import { ReactComponent as TransactionWhiteIcon } from "../../../Icons/Transactions-icon-white.svg";
import { ReactComponent as EarningIcon } from "../../../Icons/Earnings-icon.svg";
import { ReactComponent as EarningWhiteIcon } from "../../../Icons/Earnings-icon-white.svg";
import { ReactComponent as AllFundIcon } from "../../../Icons/All funds-icon.svg";
import { ReactComponent as AllFundWhiteIcon } from "../../../Icons/All funds-icon-white.svg";
import { ReactComponent as MarketingIcon } from "../../../Icons/Marketing-icon.svg";
import { ReactComponent as MarketingWhiteIcon } from "../../../Icons/Marketing-icon-white.svg";
import { ReactComponent as LogOutIcon } from "../../../Icons/Log-out-icon.svg";
// import { ReactComponent as LogOutIconWhite } from "../../../Icons/Log-out-icon-white.svg";

import { ReactComponent as HelpAndSupport } from "../../../Icons/Help-icon.svg";
// import { ReactComponent as HelpAndSupportWhite } from "../../../Icons/Help-icon-white.svg";
// import { ReactComponent as LogOutIconWhite } from "../../../Icons/Log-icon-white.svg";
export default function SidebarMenu(props) {
  const [item, setItem] = React.useState("/dashboard");
  // const selectNavbar = () => {};
  const params = useParams();
  const navigate = useNavigate();

  const logOutBtn = () => {
    // console.log("remove me");
    
    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
    };

    axios
      .post(
        config.logOut,
        { refresh: `${localStorage.getItem("refresh_token")}` },
        { headers: headers }
      )
      .then((res) => {
        // console.log("log out ", res);
        if (res.data.msg) {
          localStorage.clear();
          window.history.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        // console.log(" log out error", error);
        // localStorage.clear();
        // navigate("/")
      });
  };

  return (
    <div className="sideBar-options-contianer">
      <Link
        className={`${
          (window.location.pathname === "/dashboard" && item === "Dashboard") ||
          window.location.pathname === "/dashboard/latestnews" ||
          window.location.pathname === "/dashboard" ||
          window.location.pathname === `/dashboard/client/${params.id}`
            ? "sideBar-active-option"
            : ""
        }`}
        to="/dashboard"
        onClick={() => {setItem("Dashboard")
        props.setOpen(false)}
      }
      >
        {(window.location.pathname === "/dashboard" && item === "Dashboard") ||
        window.location.pathname === "/dashboard/latestnews" ||
        window.location.pathname === "/dashboard" ||
        window.location.pathname === `/dashboard/client/${params.id}` ? (
          <DashboardWhiteIcon />
        ) : (
          <DashboardIcon />
        )}
        Dashboard
      </Link>

      <Link
        className={`${
          (window.location.pathname === "/clients" && item === "Clients") ||
          window.location.pathname === "/clients" ||
          window.location.pathname === "/clients/addnewclients" ||
          window.location.pathname === `/clients/client/${params.id}`
            ? "sideBar-active-option"
            : ""
        }`}
        to="/clients"
        state={{ myName: "" }}
        onClick={() => {setItem("Clients")
        props.setOpen(false)
      }}
      >
        {(window.location.pathname === "/clients" && item === "Clients") ||
        window.location.pathname === "/clients" ||
        window.location.pathname === "/clients/addnewclients" ||
        window.location.pathname === `/clients/client/${params.id}` ? (
          <ClientWhiteIcon />
        ) : (
          <ClientIcon />
        )}
        Clients
      </Link>
      <Link
        className={`${
          (window.location.pathname === "/transactions" &&
            item === "Transactions") ||
          window.location.pathname === "/transactions"
            ? //  window.location.pathname ===`/clients/client/${params.id}`
              "sideBar-active-option"
            : ""
        }`}
        to="/transactions"
        onClick={() => {setItem("Transactions")
        props.setOpen(false)}
      }
      >
        {(window.location.pathname === "/transactions" &&
          item === "Transactions") ||
        window.location.pathname === "/transactions" ? (
          <TransactionWhiteIcon />
        ) : (
          <TransactionIcon />
        )}
        Transactions
      </Link>

      <Link
        to="/earnings"
        className={`${
          (window.location.pathname === "/earnings" && item === "earnings") ||
          window.location.pathname === "/earnings"
            ? "sideBar-active-option"
            : ""
        }`}
        onClick={() =>{ setItem("Earning")
        props.setOpen(false)}}
      >
        {(window.location.pathname === "/earnings" && item === "earnings") ||
        window.location.pathname === "/earnings" ? (
          <EarningWhiteIcon />
        ) : (
          <EarningIcon />
        )}
        Earnings
      </Link>

      <Link
        to="/fundlist"
        className={`${
          (window.location.pathname === "/fundlist" && item === "Fundlist") ||
          window.location.pathname === "/fundlist" ||
          window.location.pathname === "/fundlist/funddetails" ||
          window.location.pathname === `/fundlist/funddetails/${params.isin}`
            ? "sideBar-active-option"
            : ""
        }`}
        onClick={() => {setItem("Fundlist")
        props.setOpen(false)}
      }
      >
        {(window.location.pathname === "/fundlist" && item === "Fundlist") ||
        window.location.pathname === "/fundlist" ||
        window.location.pathname === "/fundlist/funddetails" ||
        window.location.pathname === `/fundlist/funddetails/${params.isin}` ? (
          <AllFundWhiteIcon />
        ) : (
          <AllFundIcon />
        )}
        All funds
      </Link>

      {/* <Link
        to="/agent"
        className={`${
          (window.location.pathname === "/agent" && item === "Agent") ||
          window.location.pathname === "/agent" 
            ? "sideBar-active-option"
            : ""
        }`}
        onClick={() => setItem("Agent")}
      >
        Agent
      </Link> */}

      <Link
        to="/marketing"
        className={`${
          (window.location.pathname === "/marketing" && item === "Marketing") ||
          window.location.pathname === "/marketing"
            ? "sideBar-active-option"
            : ""
        }`}
        onClick={() => {setItem("Marketing")
        props.setOpen(false)}
      }
      >
        {(window.location.pathname === "/marketing" && item === "Marketing") ||
        window.location.pathname === "/marketing" ? (
          <MarketingWhiteIcon />
        ) : (
          <MarketingIcon />
        )}
        Marketing
      </Link>
      {/* <a  href={"https://support.kuants.in"} target="blank">
      <HelpAndSupport/>
      Help & Support
      </a> */}

      {/* <Link to="/" className="" onClick={logOutBtn}>
        <LogOutIcon/>
        Logout
        </Link> */}
    </div>
  );
}
