import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import config from "../../config";
import AboutCLient from "../Clients/ClientnDetails/AboutCLient";
import InfoSteper from "./InfoSteper";
import {Helmet} from "react-helmet"
import AgentReviewDetails from "./AgentReviewDetails";
// mf-app/src/Components/SignUpPage/KycDocument.jsx
import { decrypt } from "../TockenConversion/JwtConvert";
export default function Agent() {
  const [aboutAgent, setAboutAgent] = useState([]);
  const [agentReviewPage,setAgentReviewPage] =  useState(false) 
  useEffect(() => {
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };
    axios
      .get(config.agentProfile, { headers: headers })
      .then((res) => {
        console.log("agent profile", res);

        const agentProfileData = [
          {
            heading: "Status",

            heading_info: `${
              res.data.kyc_status === "False" ? "Pending" : "Completed"
            }`,
          },
          {
            heading: "Date of joining",

            heading_info: `${res.data.date_joined}`,
          },
          {
            heading: "Relationship manager",

            heading_info: `${res.data.agent_rm_name}`,
          },
          {
            heading: "Agent ID",

            heading_info: `${res.data.agent_id? res.data.agent_id:"-"}`,
          },
        ];
        setAboutAgent(agentProfileData);
      })
      .catch((error) => {
        // console.log("agent profile error", error);
      });
  }, []);

  return (
    <div className="agent-propfile-main-contanier">
      {/* {agentReviewPage ? <AgentReviewDetails aboutAgent={aboutAgent}/>:<> */}
      <Helmet>
        <title>Agent</title>
      </Helmet>
      <p className="agent-pro-text">Agent profile</p>
      <div
      className="about-agent-container"
      >
        {aboutAgent.map((data) => {
          return (
            <AboutCLient
              clientInfoHeading={data.heading}
              clientInfoHeadingData={data.heading_info}
            />
          );
        })}
      </div>
      <InfoSteper setAgentReviewPage={setAgentReviewPage} />
    </div>
  );
}
