import React, { useState } from "react";
import MoveToLogin from "./RedirectToLogin"
import SearchProfile from "./Dashboard/SearchProfile/SearchProfile";
import Sidebar from "./Dashboard/Sidebar/Sidebar";
import "./LayOut.css"
import { Outlet, useNavigate } from 'react-router-dom'


export default function Layout(props) {
  const navigate =  useNavigate()
  const [searchClientsName, setSeachClientsName] = useState("");
  // const [totalAum, setTotalAum] = useState("");

  // const[dashToCLientByViewALl,setDashToCLientByViewALl] = useState("false")

  return ( 
    <div>
      { props.isloged || props.token ? <>

        <Sidebar />
      <div className="dashbord-right-layout-part">
        <SearchProfile setSeachClientsName={setSeachClientsName}/>
     
        <Outlet context={[searchClientsName ]} />
     
      </div>
      </>:<MoveToLogin/>
}
    </div>
  );
}
