import React from "react";
// import {Digio} from "/Users/apple/partnerfrontend/Partner_Frontend/src/digio.js"


export default function OtherLinks(props) {


  // const OpenDgioWindow =()=>{
  //   var options = {
  //     environment : "production",
  //     callback : function (response){
  //     if(response.hasOwnProperty("error_code")){
  //     return console.log("error occurred in process");
  //     }
  //     console.log("Signing completed successfully");
  //     },
  //     logo : "https://www.mylogourl.com/image.jpeg",
  //     theme : {
  //     primaryColor : "#AB3498",
  //     secondaryColor : "#000000"
  //     }
  //     }
  //   var digio = new Digio(options);
  //   digio.init();
  //   digio.submit("DID230213102356561L16HYSTADM6RKW", "9980422825")
  // }

  return (
    <div  className="agent-personal-detail-main-container">

       {/* <button onClick={OpenDgioWindow}>Open Dgio</button> */}
   

        <input
          type="text"
          value={props.companyName}
          placeholder="Company name"
          onChange={(e)=>props.setCompanyName(e.target.value)}
          className="email-phone-add-input"
        />
  

        <input
          type="text"
          value={props.fbLink}
          placeholder="Facebook page"
          onChange={(e)=>props.setFbLink(e.target.value)}
          className="email-phone-add-input"
        />
  
   
        
          <input
          type="text"
          value={props.linkdin}
          placeholder="Linkedin page"
          onChange={(e)=>props.setLinkdin(e.target.value)}
          className="email-phone-add-input"
          
        />
      
   
          <input
          type="text"
          value={props.instLink}
          placeholder="Instagram page"
          onChange={(e)=>props.setInstaLink(e.target.value)}
          className="email-phone-add-input"
          
        />
    
          <input
          type="text"
          value={props.webLink}
          placeholder="Website"
          onChange={(e)=>props.setWebLink(e.target.value)}
          className="email-phone-add-input"
        />
       
     
          <input
          type="text"
          value={props.microSiteLink}
          placeholder="Microsite"
          onChange={(e)=>props.setMicroSiteLink(e.target.value)}
          className="email-phone-add-input"
          
        />

    </div>
  );
}
