import React, { useState, useEffect } from "react";

import { ReactComponent as ModalShare } from "../../Icons/marketingModalShare.svg";
import { ReactComponent as ModalDownload } from "../../Icons/marketingModalDld.svg";
import { ReactComponent as Fb } from "../../Icons/fb.svg";
import { ReactComponent as Linkedin } from "../../Icons/linkedin.svg";
import { ReactComponent as Whatsapp } from "../../Icons/whatsapp.svg";
import { ReactComponent as Twitter } from "../../Icons/twitter.svg";
import { ReactComponent as Telegram } from "../../Icons/telegram.svg";
import { ReactComponent as Email } from "../../Icons/mail.svg";
import { ReactComponent as CopyICon } from "../../Icons/copy-icon.svg";
import {
  // EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import Modal from "antd/lib/modal/Modal";
import { notification } from "antd";

export default function LinkShareModal(props) {
  // const [props.isModalOpen, props.setIsModalOpen] = useState(false);
  const [iconREsizer, setIconReSize] = useState(false);

  const copySuccessfull = (type) => {
    notification[type]({
      message: "Link copied",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const handleOk = () => {
    props.setIsModalOpen(false);
  };

  const handleCancel = () => {
    props.setIsModalOpen(false);
  };

  const copy = () => {
    setIconReSize(true);
    setTimeout(() => {
      const textField = document.createElement("textarea");
      textField.innerText = props.imageUrl;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      setIconReSize(false);
      textField.remove();
      copySuccessfull("success");
    }, 500);
  };

  return (
    <div>
      <Modal
        className="marketing-share-content-modal"
        open={props.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="content-theme-cont">
          <h1>Share Via</h1>
        </div>
        <div className="share-icon-cont">
          <FacebookShareButton url={props.imageUrl} title={props.tittleUrl}>
            <Fb />
          </FacebookShareButton>

          <TwitterShareButton url={props.imageUrl} title={props.tittleUrl}>
            <Twitter />
          </TwitterShareButton>
          <LinkedinShareButton url={props.imageUrl} title={props.tittleUrl}>
            <Linkedin />
          </LinkedinShareButton>

          <WhatsappShareButton
            url={props.imageUrl}
            title={props.tittleUrl}
            separator=":: "
          >
            <Whatsapp />
          </WhatsappShareButton>

          <TelegramShareButton url={props.imageUrl} title={props.tittleUrl}>
            <Telegram />
          </TelegramShareButton>
          {/* <EmailShareButton url={props.imageUrl} subject={props.tittleUrl} body="body">
              <Email />
            </EmailShareButton> */}
        </div>
        <div className="or-text-cont">
          <h1>or</h1>
        </div>
        <div className="marketing-copy-url-container">
          <div className="copy-url-container">
            <h6 className="copy-url-text">Copy URL</h6>
            <div className="input-copyIcon-cont">
              <input
                type="text"
                className="url-input"
                value={props.imageUrl}
                placeholder="https:www.kuants.in"
              />
              <div className="copy-icon-cont" onClick={copy}>
                <CopyICon
                  className={
                    iconREsizer === true ? "after-click-copy-icon" : "copy-icon"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
