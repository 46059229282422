import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../config";
import AboutCLient from "./AboutCLient";
// import AddEvent from "./AddEvent";
import ClientChart from "./ClientChart";
import "./ClientDetails.css";
import { CSVLink } from "react-csv";
// import DiversificationPieChart from "./DiversificationPieChart";
import TransactionTable from "./TransactionTable";
import { Button, message, Modal } from "antd";
import { ReactComponent as CsvDownLod } from "../../../Icons/csvColoredDownload.svg";
// import { ReactComponent as ColorDownload } from "../../../Icons/downloadColored.svg";

import AddEventModalContent from "./AddEventModalContent";
import WealthCalculator from "./WealthCalculator";
import ClientEventListCarousel from "./ClientEventListCarousel";
import Recommendation from "./Recommendation";
import InvestMentPortFolioTable from "./InvestMentPortFolioTable";
// import InvestMentPortFolioModalView from "./InvestMentPortFolioModalView";
// import ImageSlider from "../../Dashboard/OfferSlider/Slider";
// const shiftSize = 7;
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function ClientDetails(props) {
  const [clientsName, setCLientNme] = useState();
  // const [setShowClietnsDetails ] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [clientContactData, setClientContactData] = useState([]);
  const [portfolioSummary, setPortfolioSummary] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [clientGraphLevelsData, setClientLevelsGraphData] = useState([]);
  const [clienttGraphXaxisData, setClientGraphXaxisData] = useState([]);
  const [clientGraphPnlPercent, setClientGraphPnlPercent] = useState([]);
  const [eventHoderName, setEventHolderName] = useState("");
  // const [relationToHolder, setRealationToHolder] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [recommendationData, setRecommendationData] = useState([]);
  // const [upcomingTransaction, setUpcomingTransaction] = useState([]);
  const [investMentPortFolioData, setInvestMentPortFolioData] = useState([]);
  const [loder, setLoder] = useState(true);
  const params = useParams();
  // console.log("upcoming", clienttGraphXaxisData);

  const navigate = useNavigate();



  const handelBackClick = () => {
    if (window.location.pathname === `/dashboard/client/${params.id}`) {
      navigate("/dashboard");
    } else if (window.location.pathname === `/clients/client/${params.id}`) {
      navigate("/clients", { state: "" });
    }
    //  setShowClietnsDetails(false);
  };

  // let clientGraphLevelsData =[]
  // console.log("portfolio", portfolioSummary);

  useEffect(() => {
    // console.log("url inside clientdetails", window.location.pathname);
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "application/json",

      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };
    //  --------------------ClientDetails-----Api------------

    axios
      .get(`${config.singleCLientDetail}/${params.id}`, { headers: headers })
      .then((res) => {
        // console.log("single client data", res);
        if (res.data.data) {
          const clientInfo = [
            {
              clientInfoHeading: "Client name",

              clientInfoHeadingData: `${
                res.data.data.full_name
                  ? res.data.data.full_name.replace(
                      /(\w)(\w*)/g,
                      function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                      }
                    )
                  : "-"
              }`,
            },

            {
              clientInfoHeading: "Mobile",
              clientInfoHeadingData: `${
                res.data.data.mobile_number
                  ? "+91-" + res.data.data.mobile_number
                  : "-"
              }`,
            },
            // {
            //   clientInfoHeading: "Sagment",

            //   clientInfoHeadingData: `${res.data.client_profile.segment}`,
            // },
            // {
            //   clientInfoHeading: "Risk profile",

            //   clientInfoHeadingData: `${res.data.client_profile.risk_profile}`,
            // },
          ];
          setClientData(clientInfo);
          setCLientNme(
            res.data.data.full_name
              ? res.data.data.full_name.replace(
                  /(\w)(\w*)/g,
                  function (g0, g1, g2) {
                    return g1.toUpperCase() + g2.toLowerCase();
                  }
                )
              : "-"
          );

          const contactData = [
            {
              clientContact: "Status",

              clientContactData: `${
                res.data.data.client_status ? res.data.data.client_status : "-"
              }`,
            },
            // {
            //   clientContact: "Mobile",
            //   clientContactData: `+91-${res.data.data.mobile}`,
            // },
            {
              clientContact: "Email",
              clientContactData: `${
                res.data.data.email_id ? res.data.data.email_id : "-"
              }`,
            },
            // {
            //   clientContact: "Age",
            //   clientContactData: `${res.data.data.client_profile.age
            //   }`,
            // },
          ];
          setClientContactData(contactData);
        }
      })
      .catch((error) => {
        // console.log("singlr client data error", error);
      });
    // -----------------client---upcomming-transaction-------api---

    axios
      .get(`${config.upcomingtransaction}/${params.id}`, { headers: headers })
      .then((res) => {
        // console.log("client upcomming transaction ", res);
        // setUpcomingTransaction(res.data);
      })
      .catch((error) => {
        // console.log("client upcomming transaction error", error);
      });

    // -------------Client---portfolioSummary----Api ----------
    axios
      .get(`${config.clientPortfolioSummary}/${params.id}`, {
        headers: headers,
      })
      .then((res) => {
        // console.log("client portfolio sum", res);

        const clientPortfolioSummary = [
          {
            portFolioHeading: "Total investment",
            portFolioData: "₹" + res.data.total_aum,
          },
          {
            portFolioHeading: "Monthly SIP",
            portFolioData: `${res.data.monthly_sip}`,
          },
          {
            portFolioHeading: "Current value",
            portFolioData: `${res.data.current_value}`,
          },
          {
            portFolioHeading: "Total lumpsum investments",
            portFolioData: `${res.data.total_lumpsum}`,
          },
          {
            portFolioHeading: "Total returns (₹)",
            portFolioData: "₹" + res.data.total_return,
          },
          {
            portFolioHeading: "Last Transaction",
            portFolioData: `${res.data.last_transaction.split("T")[0]}`,
          },
          {
            portFolioHeading: "Taxable income from investments",
            portFolioData: "-",
          },
          // {
          //   portFolioHeading: "XIRR",
          //   portFolioData: (res.data.xirr * 100).toFixed(2) + "%",
          // },
        ];
        // console.log("qwertyui",clientPortfolioSummary)
        setPortfolioSummary(clientPortfolioSummary);
      })
      .catch((error) => {
        // console.log("client portfolio error", error);
        const clientPortfolioSummary = [
          {
            portFolioHeading: "Total investment",
            portFolioData: "-",
          },
          {
            portFolioHeading: "Monthly SIP",
            portFolioData: "-",
          },
          {
            portFolioHeading: "Current value",
            portFolioData: "-",
          },
          {
            portFolioHeading: "Total lumpsum investments",
            portFolioData: "-",
          },
          {
            portFolioHeading: "Total returns (₹)",
            portFolioData: "-",
          },
          {
            portFolioHeading: "Last Transaction",
            portFolioData: "-",
          },
          {
            portFolioHeading: "Taxable income from investments",
            portFolioData: "-",
          },
          // {
          //   portFolioHeading: "XIRR",
          //   portFolioData: "-",
          // },
        ];
        // console.log("qwertyui",clientPortfolioSummary)
        setPortfolioSummary(clientPortfolioSummary);
      });

    // -------------------InvestMent-portFolio--------Api-----

    axios
      .get(`${config.investMentPortFolio}/${params.id}`, { headers: headers })
      .then((res) => {
        // console.log("get INVESTEMENT DATA", res);

        setInvestMentPortFolioData(res.data);
        setLoder(false);
      })
      .catch((error) => {
        // console.log("GET INVESsTED DATA ERROR", error);
      });

    // ---------client-Transaction-----Api------------
    axios
      .get(`${config.clientsTransaction}/${params.id}`, { headers: headers })
      .then((res) => {
        // console.log("agent transaction detail", res);
        if (res.data.data) {
          setTransactionData(res.data.data);
        }
      })
      .catch((error) => {
        // console.log("agent transation detail error catch", error);
      });

    // ---------------Client----- Graph API ---------

    axios
      .get(`${config.clientGraph}/${params.id}`, { headers: headers })
      .then((res) => {
        // console.log("client graph data", res);
        if (res.data.data) {
          let clientGraphLevelsData = [];
          let clinetXaxisData = [];
          let clintPnlPercent = [];
          res.data.data.map(
            (data) => (
              clientGraphLevelsData.push(data.datetime),
              clinetXaxisData.push(data.current_amount),
              clintPnlPercent.push(data.total_invested_amount)
            )
          );
          // console.log("ayaa?")
          setClientLevelsGraphData(clientGraphLevelsData);
          setClientGraphXaxisData(clinetXaxisData);
          setClientGraphPnlPercent(clintPnlPercent);
        }
        // console.log("client graph levels data", clientGraphLevelsData);
      })
      .catch((error) => {
        // console.log("client graph data error", error);
      });
    // --------Special-----EVent ---Api--------------------------
    axios
      .get(`${config.eventList}/${params.id}`, { headers: headers })
      .then((res) => {
        // console.log("special event list", res);
      })
      .catch((error) => {
        // console.log("special event list error", error);
      });

    axios
      .get(config.recommendationFund, { headers: headers })
      .then((res) => {
        // console.log("get remndtion data", res);
        // ---------recommned----fund----api--------
        const recmndData = [
          {
            heading: `${res.data.lowest_risk[0].scheme_name}`,
            subheading: res.data.lowest_risk[0].subcategory,
            percent: res.data.lowest_risk[0].return1y,
            logo: res.data.lowest_risk[0].logo,
            isin_number: res.data.lowest_risk[0].isin,
          },
          {
            heading: `${res.data.lowest_risk[1].scheme_name}`,
            subheading: res.data.lowest_risk[1].subcategory,
            percent: res.data.lowest_risk[1].return1y,
            logo: res.data.lowest_risk[1].logo,
            isin_number: res.data.lowest_risk[1].isin,
          },
          {
            heading: `${res.data.most_popular[0].scheme_name}`,
            subheading: res.data.most_popular[0].subcategory,
            percent: res.data.most_popular[0].return1y,
            logo: res.data.most_popular[0].logo,
            isin_number: res.data.most_popular[0].isin,
          },
          {
            heading: `${res.data.most_popular[1].scheme_name}`,
            subheading: res.data.most_popular[1].subcategory,
            percent: res.data.most_popular[1].return1y,
            logo: res.data.most_popular[1].logo,
            isin_number: res.data.most_popular[1].isin,
          },
        ];
        setRecommendationData(recmndData);
      })
      .catch((error) => {
        // console.log("recmnd data error", error);
      });
  }, [params.id]);
  const showModal = () => {
    setEventDate("");
    setEventHolderName("");
    setEventType("");
    // setRealationToHolder("");
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    const headers = {
      "Content-Type": "application/json",

      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    };
    setLoading(true);
    setTimeout(() => {
      axios
        .post(
          config.eventList,
          {
            client_id: params.id,
            type_of_event: eventType,
            event_holder_name: eventHoderName ? eventHoderName : clientsName,
            // relationship_with_client: relationToHolder,
            event_date: eventDate,
          },
          { headers: headers }
        )
        .then((res) => {
          // console.log("add events sccs", res);
          message.success("Event Add successfully");
        })
        .catch((error) => {
          // console.log("failed add event", error);
        });

      setLoading(false);
      setIsModalOpen(false);
    }, 1000);
  };

  const csvHeaders = [
    { label: "Fund Name", key: "fund_name" },
    { label: "Total investment", key: "total_investments" },
    { label: "Current value", key: "current_value" },
    { label: "Return", key: "total_return" },
    { label: "Investment type", key: "investment_type" },
    { label: "Total SIPs", key: "total_sip" },
    { label: "Folio number", key: "folio_number" },
  ];
  const csvTrasactionHeaders = [
    { label: "Fund Name", key: "fund_scheme_name" },
    // { label: "Status", key: "total_investments" },
    { label: "Transaction Date", key: "created_at" },
    { label: "Amount", key: "amount" },
    { label: "Order Status", key: "order_state" },
    // { label: "Order Type", key: "total_sip" },
    // { label: "Folio number", key: "folio_number" },
  ];
  return (
    <div>
      <div
        className="client-details-main-container"
        // style={{
        //   paddingLeft: "3.33vw",
        //   marginTop: "2.22vw",
        //   paddingRight: "3.33vw",
        // }}
      >
        <div style={{ display: "flex", gap: "0.3vw", alignItems: "baseline" }}>
          <p className="clients-text" onClick={handelBackClick}>
            Clients{" "}
          </p>

          <p className="client-name disable-hover">/ {clientsName}</p>
        </div>
        <div
          className="client-details-and-upcoming-container"
          // style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h1 className="client-detail-heading">Client details</h1>
            <div className="client-details-container">
              <div
                className="details-heading-info-container"
                style={
                  {
                    // display: "flex",
                    // flexDirection: "column",
                    // rowGap: "1.22vw",
                  }
                }
              >
                {clientData.map((data) => {
                  return (
                    <AboutCLient
                      clientInfoHeading={data.clientInfoHeading}
                      clientInfoHeadingData={data.clientInfoHeadingData}
                    />
                  );
                })}
              </div>

              <div
                className="details-heading-info-container"
                // style={{
                //   display: "flex",
                //   flexDirection: "column",
                //   rowGap: "1.22vw",
                // }}
              >
                {clientContactData.map((data) => {
                  return (
                    <AboutCLient
                      clientInfoHeading={data.clientContact}
                      clientInfoHeadingData={data.clientContactData}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="upcomming-transactions-container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <h1 className="client-detail-heading">Upcoming events</h1>
              <h6 className="all-transaction-btn-text" onClick={showModal}>
                Add event
              </h6>
            </div>
            <div className="upcomming-transactions">
              {/* {upcomingTransaction.map((transaction) => {
                return (
                  <p className="first-para">
                    {transaction.mf_order_type} Investment of ₹
                    {transaction.amount} in {transaction.scheme_name} on
                    <span>
                      {" "}
                      {transaction.date.split(",")[0] +
                        ", " +
                        +transaction.date.split(",")[1]}
                    </span>
                  </p>
                );
              })} */}

              <div className="add-event-main-container">
                <Modal
                  title="Add event"
                  open={isModalOpen}
                  className="add-event-modal"
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={[
                    <Button
                      className="save-btn"
                      onClick={handleOk}
                      loading={loading}
                    >
                      Save
                    </Button>,
                    <Button className="cancel-btn" onClick={handleCancel}>
                      Cancel
                    </Button>,
                  ]}
                >
                  <AddEventModalContent
                    setEventType={setEventType}
                    setEventHolderName={setEventHolderName}
                    setEventDate={setEventDate}
                    eventType={eventType}
                    eventHoderName={eventHoderName}
                    eventDate={eventDate}
                    // setRealationToHolder={setRealationToHolder}
                    clientsName={clientsName}
                    clientContactData={clientContactData}
                    // relationToHolder={relationToHolder}
                  />
                </Modal>
                <div className="event-carousel">
                  {/* <div className="add-btn-spcl-text-cont">
            <h1>
            Special events
            </h1>
            <p onClick={showModal}>Add event</p>
          </div> */}
                  <ClientEventListCarousel />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-summry-container">
          <p className="portfolio-diversi-heading">Portfolio summary</p>
          <div className="portfolio-sumry-data-container">
            {portfolioSummary.map((data) => {
              return (
                <>
                  <AboutCLient
                    clientInfoHeading={data.portFolioHeading}
                    clientInfoHeadingData={data.portFolioData}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="investment-portf-cont">
          <div className="investment-portfo-heading">
            <h1>Investment portfolio</h1>
            <div className="downloadtxt-png-cont">
              <h6>Download all</h6>
              <CSVLink
                headers={csvHeaders}
                filename={clientsName + " investment-portfolio.csv"}
                data={investMentPortFolioData}
              >
                <CsvDownLod />
              </CSVLink>
              {/* <ColorDownload/> */}
            </div>
          </div>
          <div className="fundlist-table-container">
            <InvestMentPortFolioTable
              investMentPortFolioData={investMentPortFolioData}
              loder={loder}
            />
          </div>
        </div>
        <div className="client-graph-container">
          <ClientChart
            clientGraphLevelsData={clientGraphLevelsData}
            clienttGraphXaxisData={clienttGraphXaxisData}
            clientGraphPnlPercent={clientGraphPnlPercent}
            level={"Current amount"}
          />
        </div>
        {/* <div style={{ display: "flex", marginTop: "8.17vw" }}>
          <div>
            <p className="portfolio-diversi-heading">Portfolio summary</p>
            <div className="portfolio-sumry-data-container">
              {portfolioSummary.map((data) => {
                return (
                  <>
                    <AboutCLient
                      clientInfoHeading={data.portFolioHeading}
                      clientInfoHeadingData={data.portFolioData}
                    />
                  </>
                );
              })}
            </div>
          </div>
          <div>
            <p className="portfolio-diversi-heading">Diversification</p>
            <div style={{ width: "22.83vw" }}>
              <DiversificationPieChart />
            </div>
          </div>
        </div> */}
        <div className="transaction-container">
          <div className="download-icon-cont">
            <p className="transaction-text">Transactions</p>
            <div className="downloadtxt-png-cont">
              <h6>Download all</h6>
              <CSVLink
                headers={csvTrasactionHeaders}
                filename={clientsName + " all-transactions.csv"}
                data={transactionData}
              >
                <CsvDownLod />
              </CSVLink>
            </div>
          </div>
          <div className="transaction-table">
            <TransactionTable transactionData={transactionData} />
          </div>
        </div>
        <div className="recommendation-container">
          <p className="recommendation-heading">Recommendations</p>
          <div>
            <Recommendation relatedFundData={recommendationData} />
          </div>
        </div>
        <div className="wealth-calculator-container">
          <div className="calculator-main-container">
            <p className="client-detail-heading">Calculate estimated value</p>
            {/* <div className="cal-togle-btn-cont">
              {calToggelBtn.map((btn,index) => {
                return <p className={actvToogleBtn === index ? "toggle-btn-actv" :"toggle-btn" }onClick={()=>toggleHandel(index)}>{btn}</p>;
              })}
            </div> */}

            <WealthCalculator
              totalInv={portfolioSummary[0]}
              xirrVal={portfolioSummary[7]?.portFolioData.split(".")[0]}
            />
          </div>
          {/* <div className="add-event-main-container">
   
               <Modal
                title="Add event"
                open={isModalOpen}
                className="add-event-modal"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <Button
                    className="save-btn"
                    onClick={handleOk}
                    loading={loading}
                  >
                    Save
                  </Button>,
                  <Button className="cancel-btn" onClick={handleCancel}>
                    Cancel
                  </Button>,
                ]}
              >
                <AddEventModalContent
                  setEventDate={setEventDate}
                  setEventHolderName={setEventHolderName}
                  setEventType={setEventType}
                  setRealationToHolder={setRealationToHolder}
                  clientsName={clientsName}
                  clientContactData={clientContactData}
                  eventDate={eventDate}
                  eventType={eventType}
                  eventHoderName={eventHoderName}
                  relationToHolder={relationToHolder}
                />
              </Modal>
          <div className="event-carousel">
          <div className="add-btn-spcl-text-cont">
            <h1>
            Special events
            </h1>
            <p onClick={showModal}>Add event</p>
          </div>
                <ClientEventListCarousel />
              </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
