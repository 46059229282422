import React from "react";
import { useEffect, useState } from "react";
// import { } from "react";
import LoginLeftPart from "../login_Page/LoginLeftPart";
import Otp from "./Otp";
import axios from "axios";
import {Helmet}  from  "react-helmet"
// import "antd/dist/antd.min.css";
import config from "../../config"
import { notification ,message} from "antd";
import { useNavigate } from "react-router-dom";
// import config from "../../config"
export default function SignUp(props) {
  const navigate =  useNavigate()
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendOtp, setSendOtp] = useState(false);
  const [disable, setDisable] = useState(false);
  const [responseError, setResponseError] = useState()
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "OTP sent Successfully",
      duration: 2,
      closeIcon: <></>,
    });
  };

  const showErrorNumberExist =(type)=>{
    notification[type]({
      message: "This mobile number already exist",
      duration: 2,
      closeIcon: <></>,
    });
    // message.error({
    //   content: "This number already register",
    //   className: "custom-class",
    //   style: {
    //     marginTop: "5vh",
    //     marginLeft: "77vw",
    //   },
    // });
  }
  if(window.location.pathname ==="/signup"){
    localStorage.clear()
  }


  const handelEnterKey=(event)=>{
    if(event.keyCode === 13){
      if(phoneNumber.length === 10){
        // console.log("key press")
        sendOtpButton()
      }
 
    }
}
  // console.log(config)
  const sendOtpButton = async() => {
    // openNotificationWithIcon("success");
 //Api call ------------------------ 
    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
    };



    axios
      .post(
        config.signup,
        { contact_number:`${phoneNumber}` },
        { headers:headers }
      )
      .then((res) => {
        // console.log("response", res);

        // if(res.data.username){
        //   alert("user exist")
        // }
       if(res.status === 200){
          openNotificationWithIcon("success");
          setSendOtp(true);
        }
      
        
      })
      .catch((error) => {
        // console.log("signup otp send error", error);
        // setResponseError(error.response.data.message.non_field_errors[0])
        // setResponseError(error.response.data.message.non_field_errors[0])
        if(error.response.status === 400){
          showErrorNumberExist("error")
      
        }
        // if()
      });
    // let res = await fetch("http://172.31.99.206:5001/v1/api/signup",{method:"POST", headers:headers },{ contact_number:`+91${phoneNumber}` })

    // console.log(res.json())
   
  };
  // http://172.31.99.97:5002/api/v1/verify_login/

  useEffect(() => {
    setDisable(phoneNumber.length !== 10);
  }, [phoneNumber]);


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
      }}
    >
      <Helmet>
    <title>Signup</title>
  </Helmet>
      <LoginLeftPart />

      {sendOtp ? (
        <Otp setSendOtp={setSendOtp} phoneNumber={phoneNumber} setLoged={props.setLoged} />
      ) : (
        <div
        className="signup-container"
          // style={{
          //   display: "flex",
          //   flexDirection: "column",
          //   marginLeft: "5vw",
          // }}
        >
          <img className="logo-img" src="/assets/Logo.png" alt="Loding...." />
          <h1 className="sign_up_text">Sign up</h1>

          <span className="input-prefix">
            +91-{" "}
            <input
              className="number-input"
              type="number"
              value={phoneNumber}
              placeholder="Mobile no."
              onKeyDown={handelEnterKey}
              onChange={(event) => {
                if (event.target.value.length === 11) return false; //limits to 10 digit entry
                setPhoneNumber(event.target.value);
                if (phoneNumber.length === 9) {
                  setDisable(true);
                }
              }}
            />
        
          </span>
          <button
            disabled={disable}
            onClick={sendOtpButton}
            className={`reqst-btn ${
              phoneNumber.length === 10 ? "reqst-btn-active" : ""
            }`}
          >
            Request for OTP
          </button>
          <p className="signIn-text"  onClick={()=>navigate("/")}>Sign In</p>
        </div>
      )}
    </div>
  );
}
