import React, { useState, useEffect } from "react";
import "./Marketing";
// import { notification } from "antd";
import { ReactComponent as WhiteBookMark } from "../../Icons/whiteBookMark.svg";
import { ReactComponent as BookMark } from "../../Icons/marketingbookmark.svg";
// import { ReactComponent as ArrowDownload } from "../../Icons/arrowDownload.svg";
import { ReactComponent as Share } from "../../Icons/shareWhite.svg";

import axios from "axios";
import config from "../../config";
import ImageShareModal from "./ImageShareModal";
import LinkShareModal from "./LinkShareModal";
import { decrypt } from "../TockenConversion/JwtConvert";
export default function BlogCard(props) {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [marketingArticalData, setMarketingArticalData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [tittleUrl, setTittleUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [iconREsizer, setIconReSize] = useState(false);
  const [openImgMod, setOpenImgMod] = useState(false);
  // console.log("INSIDE BLOG CARD MARKETING DATA",props.marketingCardData);
  const decrypted_access_token = decrypt(
    "access_token",
    localStorage.getItem("access_token")
  );

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };
  useEffect(() => {
    const filter = props.marketingCardData.filter(
      (data) =>
        data.url_type === "Article" ||
        data.url_type === "Image" ||
        data.url_type === "PDF" ||
        data.url_type === "Latest news"
    );
    // console.log("FILTER ARTICAL URL ONLY",filter);
    setMarketingArticalData(filter);
  }, [props.marketingCardData]);

  // console.log(props.marketingCardData,":::::::::::::::");
  const showModal = (data) => {
    setIsModalOpen(true);
    // if (data.url_sub_category === "Latest news") {
    //   setImageUrl(data.news_image_url);
    // } else {
    //   setImageUrl(data.url_image);
    //   // console.log("INSIDE IF");
    // }
    setImageUrl(
      data.url_type === "Image"
        ? data.marketing_url
          ? data.url_image
          : data.news_image_url
        : data.marketing_url
        ? data.marketing_url
        : data.news_url
    );
    setTittleUrl(data.marketing_url ? data.url_heading : data.news_heading);
    // setImageUrl(data.url_image)
    // console.log(data,":::::::::::::::");
  };

  const updateBookmark = (data) => {
    const tempdataArray = marketingArticalData;
    if (data.url_bookmark_status === true) {
      data.url_bookmark_status = false;
    } else {
      data.url_bookmark_status = true;
    }
    setMarketingArticalData([...tempdataArray, data]);
  };

  const bookmarkDone = (data) => {
    updateBookmark(data);
    // console.log("BOOKMARK :", data);
    //  if(data.bookmark_status === true) {
    //   data.bookmark_status = false;
    //   console.log("BOOKMARK 1:", data);
    //  }
    //  else{
    //   data.bookmark_status = true;
    //   console.log("BOOKMARK 2:", data);
    //  }
    axios
      .post(
        config.marketingBookMark,
        { marketing_url: data.marketing_url },
        { headers: headers }
      )
      .then((res) => {
        // console.log(data,":::::::::::::::",res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const showModalImgShare = (data) => {
    if (data.url_image) {
      setOpenImgMod(true);
      setImageUrl(data);
    }

    // console.log("data",data)
  };

  return (
    <>
      {marketingArticalData.map((data) => {
        // console.log(marketingArticalData);
        return (
          <div className="card">
            <img
              src={data.marketing_url ? data.url_image : data.news_image_url}
              alt=""
              width={"100%"}
              height={"100%"}
              data-text={"Nice Sunset"}
              onClick={() => showModalImgShare(data)}
            />
            {data.news_image_url ? (
              ""
            ) : (
              <div className="bookMark">
                {data.url_bookmark_status === true ? (
                  <BookMark onClick={() => bookmarkDone(data)} />
                ) : (
                  <WhiteBookMark onClick={() => bookmarkDone(data)} />
                )}
              </div>
            )}
            <div className="downlod-and-share-icon-cont">
              {/* <div className="dl-icn-cont">
                <ArrowDownload />
              </div> */}
              <div className="share-icn-cont">
                <Share onClick={() => showModal(data)} />
              </div>
            </div>
            <div>
              <a
                target={"_blank"}
                href={data.marketing_url ? data.marketing_url : data.news_url}
              >
                <h1>
                  {data.marketing_url ? data.url_heading : data.news_heading}
                </h1>
              </a>
              <h6>
                Source:{" "}
                <spna className="date-heading">
                  {data.marketing_url ? data.source_of_url : data.news_keyword}
                </spna>
              </h6>
            </div>
          </div>
        );
        
      })}
      <div style={{display:"none"}}>
      <LinkShareModal
        imageUrl={imageUrl}
        tittleUrl={tittleUrl}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
      <ImageShareModal
        openImgMod={openImgMod}
        imageUrl={imageUrl}
        setOpenImgMod={setOpenImgMod}
      />
      </div>
    
    </>
  );
}
