import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

export default function MoveToLogin() {
    const navigate =   useNavigate ()
    useEffect(() => {
     navigate("/") 
    }, [])
    
  return (
    <div>
      <p>something went wrong</p>
    </div>
  )
}
