import React from "react";
import { useState } from "react";
import "./AddNewClients.css";
import NewClientsPersonalDetails from "./NewClientsPersonalDetails";
import OtpVerifiactionCompo from "./OtpVerifiactionCompo";
import { Helmet } from "react-helmet";
// import "antd/dist/antd.css"
import { notification } from "antd";
import axios from "axios";
import config from "../../../config";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function AddNewCLients() {
  // console.log("inside addnewclient :",window.location.pathname)

  const [email, setEmail] = useState();
  const [phone, setPhone] = useState([]);
  const [requestOtp, setRequestOtp] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [clientName, setClientName] = useState("");
  // const[disable,setDisable ] = useState(true)
  // const[message,setMessage] =  useState('')
  const [btndisable, setBtnDisable] = useState(false);
  const [error, setError] = useState(null);

  const sendNotificationToNewCLient = (type) => {
    notification[type]({
      message: "Client Notified",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const alertClientExist = (type) => {
    notification[type]({
      message: "Client already exists",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const alertWarning = (type) => {
    notification[type]({
      message: "Some error occurred",
      duration: 2,
      closeIcon: <></>,
    });
  };

  const rqstForOtp = () => {
    // openNotificationWithIcon("success");
    // setRequestOtp(true);
    // console.log("btndisable 1");
    setBtnDisable(true);
    // console.log("btndisable 2");
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };

    axios
      .post(
        config.addNewClient,
        { email_id: email, mobile_number: phone, full_name: clientName },
        { headers: headers }
      )
      .then((res) => {
        if (res.data) {
          sendNotificationToNewCLient("success");
        }
        setPhone("");
        setEmail("");
        setClientName("");
        // console.log("post add new client data", res);
      })
      .catch((error) => {
        // console.log("post add new client data eror", error);
        if (error.response.status === 409) {
          alertClientExist("warning");
          setBtnDisable(false);
        } else {
          alertWarning("warning");
          setBtnDisable(false);
        }
      });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
      // setDisable(false)
    }

    setEmail(event.target.value);
  };


  const onInputChange = e => {
    const { value } = e.target;
    
 
    const re = /^[a-zA-Z\s]*$/;
    if (value === "" || re.test(value)) {
      setClientName(value);
   
    }
  }




  return (
    <div
    className="addnewclients-main-container"
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   marginLeft: "3.33vw",
      //   marginTop: "2.77vw",
      //   // gap: "50px",
      // }}
    >
      <Helmet>
        <title>Add New Clients</title>
        <link rel="icon" href="/AddNewCLients/faveicon.ico" />
      </Helmet>
      <p className="new-client-text">New Client</p>
      <p className="basic-detail-text">Basic details</p>
      <div className="input-contianer">
        <input
          required
          className="gmail-number-text-pss-input"
          value={clientName} 
          type="text"
          placeholder="Client Name"
          onChange={onInputChange}
        />

        <input
          required
          className="gmail-number-text-pss-input"
          value={email}
          type="gmail"
          placeholder="Email"
          onChange={handleChange}
        />





        <span className="input-prefix">
          +91-{" "}
          <input
            value={phone}
            className="number-input"
            type="number"
            placeholder="Phone number"
            onChange={(event) => {
              if (event.target.value.length === 11) return false;
              setPhone(event.target.value);
              // setDisable(false)
            }}
          />
        </span>

        {/* <input
                  className="gmail-number-text-pss-input"
                  type="number"
                  placeholder="Phone number"
                  value={phone}
                  onChange={(event) => {
                    if (event.target.value.length === 11) return false;
                    setPhone(event.target.value);
                    // setDisable(false)
                  }} */}
        {/* /> */}

        <button
          disabled={
            phone.length === 10 && email && error === null && clientName && !btndisable
              ? false
              : true
          }
          type="submit"
          className={`reqst-btn ${
            phone.length === 10 && email && error === null && clientName && !btndisable
              ? "reqst-btn-active"
              : ""
          }`}
          style={{ marginLeft: "0px" }}
          onClick={(e) => rqstForOtp(e)}
        >
          Notify client
        </button>

      </div>
      {error && <span className="error-msg">{error}</span>}
    </div>
  );
}
