import React from "react";
import { useState } from "react";
import CreatePassword from "./CreatePassword";
import "./otp.css";
import "./SignUp.css";
// import "antd/dist/antd.min.css";
import { notification, message } from "antd";
import axios from "axios";
import config from "../../config";
import SignUp from "./SignUp";
import { Navigate, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
export default function Otp(props) {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  // const[backEndSendOtp,setBackEndSendOtp]= useState("")
  const [signUp, setSignUp] = useState(false);
  // const [email,setEmail] =  useState()
  // const [disable, setDisable] = useState(true);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "OTP sent Successfully",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const showError = () => {
    message.error({
      content: "Incorrect OTP",
      className: "custom-class",
      style: {
        marginTop: "5vh",
        marginLeft: "77vw",
      },
    });
  };

  const handelEnterKey = (event) => {
    if (event.keyCode === 13) {
      if (otp.length === 7) {
        // console.log("key press")
        handelSignUp();
      }
    }
  };

  //  --------------------------Resend OTP Function-------------------
  const resendOtp = () => {
    setOtp("");
    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
    };
    axios
      .post(
        config.resendOtp,
        { contact_number: props.phoneNumber, step: "Signup" },
        { headers: headers }
      )
      .then((res) => {
        // console.log("otp resend successfully", res);
        if (res) {
          openNotificationWithIcon("success");
        }
      })
      .catch((error) => {
        // console.log("otp resend error", error);
      });
  };
  // ----------------------SignUp Function--------------------
  const handelSignUp = () => {
    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
    };

    axios
      .post(
        config.signupOtp,
        {
          otp_generated: otp.split(" ").join(""),
          contact_number: props.phoneNumber,
        },
        { headers: headers }
      )
      .then((response) => {
        // console.log("otp send" , response)

        if (response.data.message) {
          setSignUp(true);
        }
      })
      .catch((error) => {
        // console.log("otp error",error)
        if (error.response.data.non_field_errors[0]) {
          // alert("otp not match")
          showError();
        }
      });
  };

  // ------------------------------OTP Formater Function----------------------
  const formatAndSetCcNumber = (e) => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 1) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 6);
    }
    // const inputNumbersOnly =  e.target.value
    const splits = inputNumbersOnly.match(/.{1,3}/g);
    // console.log("split otp",splits)
    let spacedNumber = "";

    if (splits) {
      spacedNumber = splits.join(" ");
    }

    setOtp(spacedNumber);
  };

  const redirectToSinUp = () => {
    // navigate("/signup")
    // console.log("Back to Sinup");
    props.setSendOtp(false)
  };
  return (
    <div className="otp-screen-main-cont">
      {signUp ? (
        <CreatePassword
          phoneNumber={props.phoneNumber}
          setLoged={props.setLoged}
        />
      ) : (
        <>
          <img className="logo-img" src="/assets/Logo.png" alt="Loding...." />
          <h1 className="sign_up_text">Sign up</h1>

          <span className="input-prefix ">
            +91-
            <input
              className="number-input"
              type="number"
              value={props.phoneNumber}
            />
              {" "}
              <span className="edit-number-btn" onClick={redirectToSinUp}>
                Edit
              </span>
         
          </span>

          <input
            className="otp-input"
            type="text"
            value={otp}
            onKeyDown={handelEnterKey}
            // onChange={(event) => {
            //   if (event.target.value.length === 7) return false; //limit only 6 digit
            //   setOtp(event.target.value);
            //   if(otp.length===5){
            //     setDisable(false)
            //   }
            // }}
            onChange={formatAndSetCcNumber}
            placeholder="Enter OTP"
          />
          <div
            className="resen-btn-cont"
            // style={{
            //   display: "flex",
            //   flexDirection: "row",
            //   marginLeft: "11vw",
            //   gap: "3px",
            //   // width: "400px",
            // }}
          >
            <p className="codeNot-send-text">Code not received?</p>
            <p className="resend-text-btn" onClick={resendOtp}>
              Resend
            </p>
          </div>

          <button
            disabled={otp.length === 7 ? false : true}
            className={`sign-up-btn ${
              otp.length === 7 ? "active-sign-up-btn" : ""
            }`}
            type="buttton"
            onClick={handelSignUp}
          >
            Sign up
          </button>
        </>
      )}
    </div>
  );
}
