import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function PublicFundShareGraph(props) {

  // console.log("nfv gfgfvfvhfgf",props)
  const labels = props.fundGraphDate;
 const options = {
    responsive: true,
    
    plugins: {
      legend: {
          display:false
      },
      title: {
        display: true,
      },
      labels:{
        display:false
      },
     
    },
    // scales: {
    //   xAxesID: {
    //     display: false
    //   },
    //   yAxes: {
    //     display: false
    //   },
    // },
    
    scales: {
      x: {
        grid: {
          display: false
        },
        display:false
      },
   
      y: {
        grid: {
          display: false
        },
        display:false
        
      }
    }
  }
 const data = {
    labels,
    datasets: [
      {
        label: '',
        data: props.fundGraphNav,
        borderColor: '#6D42DA',
        backgroundColor: '#6D42DA',
        borderWidth:3,
        tension:0.5,
        pointRadius: 0,
        hoverBackgroundColor: '#6D42DA',
        pointHoverRadius: 5,
        hoverBorderColor: '#6D42DA',
        boxShadow: "2px 12px 6px rgba(0, 0, 0, 0.15)"
      },
   
    ],
  };
  return (
    <div style={{width:"66.95vw",}}>
   <Line options={options} data={data} />
    </div>
  );
}
