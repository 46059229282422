import { Carousel } from "antd";
import React from "react";

export default function LoginLeftPart() {

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    // speed: 500,
    slidesToShow: 1,
    // slidesToScroll: 2,

    // touchMove: false,
  };
  const headingArray =  [
    "Partner with us to grow your wealth management business digitally",
    "100% paperless and power-packed with tools Empowering you with resources to build a brand for yourself and trust among your clients  ",
    "Get trained for a successful career in investments Access to the best-in-class research & insights and marketing collaterals",
  ]
  return (
    <div className="welcome-container">
      <h1 className="welcome-text">Welcome to Kuants</h1>
      {/* <p className="welcome-para">
        Invest in mutual funds and create your portfolio Invest in mutual funds
        and create your portfolio
      </p> */}
      <Carousel className="login-carousel" {...settings}>
        {headingArray.map((heading, index) =>{
          return (
          <p className="welcome-para">{heading}</p>
          )
        })

        }

      </Carousel>

      <div
        className="welcome-img"
        // style={{ width: "38.0vw",
        // //  height: "60.0vh",
        //  marginLeft: "10%" }}
      >
        {/* <img

            className="image"
            src="/assets/Group 319.png"
            alt="Loding...."
          /> */}
        {/* <div   className="container"> */}
        <img
            className="image"
            src="/assets/welcome-img.png"
            alt="Loding...."
          />
        {/* </div> */}
      </div>
    </div>
  );
}
