import React from 'react'
import "./FundDetails"
export default function FundMarketDetail(props) {
  return (
    <>
            {props.fundData.map((data) => {
            return (
              <div className="fund-market-detail-data">
                <h5>{data.heading}</h5>
                <h6>{data.subheading}</h6>
              </div>
            );
          })}
    </>
  )
}
