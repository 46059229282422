import { Modal } from "antd";
import React from "react";
import "./LogOutPopUp.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function LogOutPopUp(props) {
  const navigate = useNavigate();

  const handleOk = () => {
    props.setIsModalOpen(false);
  };

  const closeModal = () => {
    props.setIsModalOpen(false);
  };
  const handelCancel = () => {
    props.setIsModalOpen(false);
  };

  const logOutBtn = () => {
    console.log("remove me");
    const decrypted_access_token = decrypt(
      "access_token",
      localStorage.getItem("access_token")
    );
    const decrypted_refresh_token = decrypt(
      "refresh_token",
      localStorage.getItem("refresh_token")
    );

    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };
    axios
      .post(
        config.logOut,
        { refresh: `${decrypted_refresh_token}` },
        { headers: headers }
      )
      .then((res) => {
        console.log("log out ", res);

        if (res.status === 200) {
          localStorage.clear();
          // window.history.clear();
          navigate("/");
          window.history.clear();
          // console.log("ayaya")
        }
      })
      .catch((error) => {
        console.log(" log out error", error);
        // localStorage.clear();
        // navigate("/")
      });
  };

  return (
    <div className="logout-popup-main-container">
      <Modal
        className="logout-popup"
        open={props.isModalOpen}
        onOk={handleOk}
        onCancel={closeModal}
      >
        <div className="popup-content-cont">
          <h1>Are you sure you want to log out?</h1>
          <div className="btn-cont">
            <button className="cancel-btn" onClick={handelCancel}>
              Cancel
            </button>
            <button className="log-out-btn" onClick={logOutBtn}>
              Logout
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
