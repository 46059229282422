import { message, Spin } from "antd";
import React from "react";
import { useRef, useState,useEffect } from "react";
// import { Link } from "react-router-dom";
import axios, { Axios } from "axios";
import config from "../../../config";
import { ReactComponent as DoneTick } from "../../../Icons/uploadDone.svg";
import { decrypt } from "../../TockenConversion/JwtConvert";
function AgreementUpload(props) {
  const inputFile = useRef(null);
  const [agreementDoc, setAgreementDoc] = useState();
  const [loading, setLoading] = useState(false);
  const [requiredField, setRequiredField] = useState(false);
  const uploadFunction = () => {
    inputFile.current.click();
  };

  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin":"*",

    Authorization: `Bearer ${decrypted_access_token}`,
  };
  const nexthandel = () => {
    // props.setDetailView(true)
    if (agreementDoc) {
      setRequiredField(false);
      setLoading(true);
      uploadfile();
    } else setRequiredField(true);

    // props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

// useEffect(() => {
//   window.OpenDgioWindow()
// }, [])

 

  const uploadfile = () => {
    // console.log("PDF Document", agreementDoc);
    //
    axios
      .post(config.agreeMentDoc, agreementDoc, { headers: headers })
      .then((response) => {
        // console.log("post file", response);
        message.success("Submitted Successfully");
        setLoading(false);
        props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.handleComplete()
      })
      .catch((error) => {
        // console.log("post error", error);
      });
    
  };

  // useEffect(() => {
  //

  // }, [agreementDoc])

  const handleFileUpload = async (e) => {
    // console.log("opening file");
    // if (e.target.files) {
  
    let formdata = new FormData();
    setAgreementDoc(formdata.append("file", e.target.files[0]))
    setAgreementDoc(Object.fromEntries(formdata.entries()));
    message.success("Upload Successfully");
    // console.log("Pdf file", Object.fromEntries(formdata.entries()));
    uploadfile();
    // console.log("PDF Document", agreementDoc);

    // const fileArray = Array.from(e.target.files).map((file) =>
    //   URL.createObjectURL(file)
    // );
    // props.setAgreementDoc(fileArray);
    // uploadfile()
    // // message.success("Upload Successful");
    // localStorage.setAgreementDoc('agreementDoc',fileArray)
    // Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    //   setUploadDocument(true);
    //   setBtnDisable(false)
    // }
  };

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("/AgreementPdf/LegalAgreement for Distributors.docx").then(
      (response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "LegalAgreement-for-Distributors.docx";
          alink.click();
        });
      }
    );
  };

  return (
    <>
      <div className="agrement-upload-main-container">
        <p className="agreement-para">
          Download the{" "}
          <span className="agreement-text" onClick={onButtonClick}>
            agreement
          </span>{" "}
          and upload the signed copy
        </p>
        {agreementDoc ? (
          <DoneTick />
        ) : (
          <>
            <div className="document-upload-container" onClick={uploadFunction}>
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <img
                src="/assets/upload.png"
                style={{ width: "2.66vw" }}
                alt=""
              />
            </div>
            <div style={{ marginTop: "2.22vw" }}>
              {requiredField === true ? (
                <p className="error-msg-text"> * Mandatory</p>
              ) : (
                ""
              )}
            </div>
          </>
        )}
        {/* <div
          className={`doc-container ${
            props.agreementDoc ? "doc-container-visible" : ""
          }`}
        >
          <img
            src="/assets/Approved.png"
            className="approved-icon"
            style={{ width: "3.30vw", height: "3.30vw" }}
            alt=""
          />

          <div className="successfully-upload-conatiner">

            <object
              style={{ width: "100%", height: "100%" }}
              data={props.agreementDoc}
              type="application/pdf"
            >
              {" "}
            </object>
          </div>
        </div> */}
      </div>
      {/* style={{ marginTop: "2vw", marginLeft: "11vw" }} */}
      <div className="submit-btn-cont">
        <button className="submit-btn" onClick={nexthandel}>
          Submit
        </button>
        <Spin spinning={loading} />
      </div>

    </>
  );
}
export default AgreementUpload;
