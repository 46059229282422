// import { tuple } from "antd/es/_util/type";
import axios from "axios";
import React ,{memo, useEffect} from "react";

import { useState } from "react";
import config from "../../../config";

import  AgentPersonalOverView from "../../Clients/ClientnDetails/AboutCLient";
import "./AgentFormOverView.css"
import { decrypt } from "../../TockenConversion/JwtConvert";
 function PersonalOverViewForm(props) {

const [personalDetail,setPersonalDetail] =  useState([]) 
// console.log("render this cmpo")
const editFunction=()=>{
    // console.log("get false")
  props.setDetailView(false)
  props.setFillDetails(true)
 
}
const nexthandel=()=>{
    props.setDetailView(true)
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    props.handleComplete()
 
}
useEffect(() => {
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin":"*",
        Accept: "application/json",
        Authorization: `Bearer ${decrypted_access_token}`,
      };
  axios.get(
    config.agentPersonalDetail,
    {headers:headers}
  ).then((res)=>{
    props.setFillDetails(false)
    props.handleComplete()
    console.log("personal detail get request",res)
    if(res.request.status === 206){

        props.setEmail(res.data.agent_email_id);
        props.setNumber(res.data.contact_number);
        props.setArnNumber(res.data.arn_number)
        props.setDetailView(false)
        props.setFillDetails(true)
      
    }
   
    else  if(res.data){
      console.log(res.data,"personal");
        const AgentPersonalDetail = [
            {
              heading: "Email",
        
              heading_info: `${res.data.agent_email_id}`,
            },
            {
              heading: "Phone",
        
              heading_info: `+91-${res.data.contact_number}`,
            },
            {
              heading: "Address",
        
              heading_info: `${res.data.agent_address_line1 +  "," +res.data.agent_address_pincode

              }`,
            },
            {
              heading: "Language",
        
              heading_info: `${res.data.agent_selected_language
              }`,
            },
            {
              heading: "ARN-Number",
        
              heading_info: res.data.arn_number ? res.data.arn_number:"--",
            },
          ];
          setPersonalDetail(AgentPersonalDetail)
          props.setEmail(res.data.agent_email_id);
          props.setNumber(res.data.contact_number);
          props.setAdd01(res.data.agent_address_line1.split(",")[0]);
          props.setAdd02(res.data.agent_address_line1.split(",")[1].replace(" ",""));
          props.setCity(res.data.agent_address_line1.split(",")[2]);
          props.setState(res.data.agent_address_line1.split(",")[3]);
          props.setlanguage(res.data.agent_selected_language);
          props.setPinCode(res.data.agent_address_pincode) 
          props.setArnNumber(res.data.arn_number)
       

          
         
    }
  
  }).catch((error)=>{
    // console.log("personaldetail  get request error",error)
  })
},[])



  return (
    <div>
        <div className="overViewContainer">
        {personalDetail.map((data) => {
        return (
          <AgentPersonalOverView
            clientInfoHeading={data.heading}
            clientInfoHeadingData={data.heading_info}
          />
        );
      })}
      <div className="edit-next-btn-container">
      <p className="edit-btn" onClick={editFunction}>Edit</p> 
      <p className="edit-btn" onClick={nexthandel}>Next</p>
      </div>
     
        </div>
 

    </div>
  );
}
export default memo(PersonalOverViewForm)