import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import AboutCLient from "../Clients/ClientnDetails/AboutCLient";
import ClientChart from "../Clients/ClientnDetails/ClientChart";
import { ReactComponent as EmptySreen } from "../../Icons/emptyscreen.svg";
import { decrypt } from "../TockenConversion/JwtConvert";
// import TransactionTable from "../Clients/ClientnDetails/TransactionTable";
import "./Payment.css";
export default function Payment() {
  const navigate = useNavigate();
  const [paymentdata, setPyamentData] = useState([]);
  const [upComingTransaction, setUpcomingTransaction] = useState([]);
  const [transactionTableData, setTransactiontableData] = useState([]);
  const [earningAmout, setEarningAmout] = useState([]);
  const [earnigAmountDate, setEarningAmoutDate] = useState([]);
  

  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };


// console.log("totalAmount",totalAum);

  // This useEffect for call total income api
  useEffect(() => {
  
    axios
      .get(config.agentPayment, { headers: headers })
      .then((res) => {
        // console.log(("agent payment:", res));
        // ------payment detail--------------------
        const paymenDetails = [
          {
            paymentHeading: "Total earnings",

            paymentheadingInfo: res.data.total_income
              ? "₹" + res.data.total_income
              : "0",
          },
          {
            paymentHeading: "Total AUM",

            paymentheadingInfo: "₹" + localStorage.getItem("totalAum")
             
          },
          {
            paymentHeading: "Next Settlement Date",

            paymentheadingInfo: res.data.current_month_last_date
              ? res.data.current_month_last_date
              : "-",
          },
          {
            paymentHeading: "Previous Month",

            paymentheadingInfo: res.data.previous_month_income
              ? "₹" + res.data.previous_month_income
              : "0",
          },
        ];
        setPyamentData(paymenDetails);

  // --------graph----data--------------------      
    let amountTempArray = []
    let dateTempArray = []
       res.data.table.map((data) =>{
            amountTempArray.push(data.commision_value)
            dateTempArray.push(data.commision_month)
       })
       
        setEarningAmout(amountTempArray)
        setEarningAmoutDate(dateTempArray)
        // ------Upcoming transaction detail-----
        //   const upComingtransactionDetail = [
        //     {
        //       paymentHeading: "Settlement Date",

        //       paymentheadingInfo:
        //       `${res.data.agent_current_month_settlement_date}`,
        //     },
        //     {
        //       paymentHeading: "Amount",

        //       paymentheadingInfo:"₹"+"-"
        //     },
        //     {
        //       paymentHeading: "Bank name",

        //       paymentheadingInfo: `${res.data.agent_bank_name_1}`,
        //     },
        //     {
        //       paymentHeading: "Account number",

        //       paymentheadingInfo: `${res.data.agent_bank_account_number_1.replace(
        //         /.(?=.{4,}$)/g,
        //         "X"
        //       )}`,
        //     },
        //   ];
        //   setUpcomingTransaction(upComingtransactionDetail);
        // }
      })
      .catch((error) => {
        // console.log("agent payment error", error);
        //   const paymenDetails = [
        //     {
        //       paymentHeading: "Total Income",

        //       paymentheadingInfo: "-",
        //     },
        //     {
        //       paymentHeading: "Next Settlement Date",

        //       paymentheadingInfo: "-",
        //     },
        //     {
        //       paymentHeading: "Previous Month",

        //       paymentheadingInfo: "-",
        //     },
        //   ];
        //   setPyamentData(paymenDetails);
      });
    // const upComingtransactionDetail = [
    //   {
    //     paymentHeading: "Settlement Date",

    //     paymentheadingInfo: "-",
    //   },
    //   {
    //     paymentHeading: "Amount",

    //     paymentheadingInfo: "-",
    //   },
    //   {
    //     paymentHeading: "Bank name",

    //     paymentheadingInfo: "_",
    //   },
    //   {
    //     paymentHeading: "Account number",

    //     paymentheadingInfo: "-",
    //   },
    // ];
    // setUpcomingTransaction(upComingtransactionDetail);
  }, []);






  return (
    <div className="earning-main-container">
      <Helmet>
        <title>Earnings</title>
        <link rel="icon" href="faveicon.ico" />
      </Helmet>
      <div style={{ display: "flex", alignItems: "baseline", gap: "0.3vw" }}>
        <p className="clients-text" onClick={() => navigate("/dashboard")}>
          Dashboard{" "}
        </p>
        <p className="client-name disable-hover">/ {"Earnings"}</p>
      </div>
      <p className="client-detail-heading">Earnings</p>
      <div className="payment-detail-container">
        {paymentdata.map((data) => {
          return (
            <AboutCLient
              clientInfoHeading={data.paymentHeading}
              clientInfoHeadingData={data.paymentheadingInfo}
            />
          );
        })}
      </div>
      <div>
        <ClientChart
          clientGraphLevelsData={earnigAmountDate}
          clienttGraphXaxisData={earningAmout}
          clientGraphPnlPercent={[]}
          level={"Total earnings"}
        />
      </div>
      {/* <p className="upComing-transaction-text">Upcoming Transactions</p> */}
      <div className="upComing-transaction-container">
        {/* {upComingTransaction.map((data) => {
          return (
            <AboutCLient
              clientInfoHeading={data.paymentHeading}
              clientInfoHeadingData={data.paymentheadingInfo}
            />
          );
        })} */}
      </div>
      <p className="transaction-text">Payout Summary</p>
      <div style={{ paddingBottom: "34px" }}>
        <div className="transaction-table">
          {transactionTableData.length > 0 ? (
            <table className="table-style-one">
              <tr>
                <th>Bank name</th>
                <th>Account number</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Transaction date</th>
              </tr>
              {transactionTableData.map((data) => {
                return (
                  <tr>
                    <td>HDFC Bank</td>
                    <td>XXXXXXX-1458</td>
                    <td>671458</td>
                    <td>₹89,000.00</td>
                    <td>11-05-2022</td>
                  </tr>
                );
              })}
            </table>
          ) : (
            <div className="client-table-empty-screen">
              {/* {loder==true ? <Spin size="large" /> :  ""} */}
              <EmptySreen />
              <h1>No transactions executed</h1>
              <p>
                Start your business on Kuants today. Your monthly income summary
                can be viewed here.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
