import React, { useEffect } from "react";
// import TextField from "@material-ui/core/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";

function BankDetailForm(props) {
  // const { control } = useFormContext();
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);

  useEffect(() => {
    if (isCPasswordDirty) {
      if (props.accountNum === props.reAccountNum) {
        props.setShowErrorMessage(false);
        props.setRequiredFields(false);
      } else {
        props.setShowErrorMessage(true);
      }
    }
  }, [props.accountNum, isCPasswordDirty, props.reAccountNum]);

  const handelReAccntNum = (e) => {
    if (e.target.value.length > 16) return false;
    props.setReAccountNum(e.target.value);
    setIsCPasswordDirty(true);
    props.setRequiredFields(false);
    //  if(e.target.value.length > 8){
    //   props.setInvalideAccountNum(false)
    //  }222
  };
  const handelAccountNum = (e) => {
    if (e.target.value.length > 16) return false;
    props.setAccountNum(e.target.value);
    setIsCPasswordDirty(true);
    props.setRequiredFields(false);
  };
  //
  return (
    <div  className="agent-personal-detail-main-container"  
    // style={{ display: "flex", marginTop: "24px", marginLeft: "1.66vw" }}
    >
      {/* <form style={{ display: "flex", flexDirection: "column", gap: "2.22vw" }}> */}
        <input
          type="number"
          placeholder="Account number"
          value={props.accountNum}
          onChange={handelAccountNum}
          className="email-phone-add-input"
        />
        <div  className="error-message-div">
          {props.requiredFields === true && !props.accountNum ? (
            <p className="error-msg-text"> * Mandatory</p>
          ) : (
            <p className="error-msg-text">
              {props.invalideAccountNum === true && props.accountNum.length < 8
                ? "Invalid Account Number"
                : ""}
            </p>
          )}
        </div>
        {/* <div style={{ marginBottom: "-2.22vw" }}>
          {props.invalideAccountNum ===true  ? (
            <p className="error-msg-text">Invalid Account Number</p>
          ) : (
            ""
          )}
        </div> */}
        <input
          type="password"
          value={props.reAccountNum}
          placeholder="Re-enter account number"
          style={{ paddingBottom: "0.55vw" }}
          onChange={handelReAccntNum}
          // onChange={(e) => props.setReAccountNum(e.target.value)}
          required
          className="email-phone-add-input"
        />
        <div className="error-message-div">
          {props.requiredFields === true && !props.reAccountNum ? (
            <p className="error-msg-text"> * Mandatory</p>
          ) : (
            ""
          )}
        </div>
        <div className="error-message-div">
          {props.showErrorMessage && props.reAccountNum ? (
            <p className="error-msg-text">Account number does not match</p>
          ) : (
            ""
          )}
        </div>

        <input
          type="text"
          value={props.ifsc}
          placeholder="IFSC code"
          onChange={(e) => {
            if (e.target.value.length > 11) return false;
            props.setIfsc(e.target.value);
          }}
          required
          className="email-phone-add-input"
        />
           <div className="error-message-div">
          {props.requiredFields === true && !props.ifsc ? (
            <p className="error-msg-text"> * Mandatory</p>
          ) : (
            ""
          )}
        </div>
        <div className="error-message-div">
          {props.ifscCodeError === true  && props.ifsc.length >0 ? (
            <p className="error-msg-text"> IFSC Code is wrong</p>
          ) : (
            ""
          )}
        </div>
        <input
          type="text"
          value={props.holderName}
          placeholder="Account holder’s name"
          required
          onChange={(e) => props.setHolderName(e.target.value)}
          className="email-phone-add-input"
        />
        <div className="error-message-div">
          {props.requiredFields === true && !props.holderName ? (
            <p className="error-msg-text"> Please Enter Account Holder Name</p>
          ) : (
            ""
          )}
        </div>
        {/* <input type="text"  className="email-phone-add-input"/> */}
      {/* </form> */}
    </div>
  );
}

export default BankDetailForm;
