import { Modal } from 'antd';
import React from 'react'

export default function InvestMentPortFolioModalView(props) {

// console.log("props data from invesmentportFolio",props.investMentPortFolioModalData );
  
    const handleOk = () => {
      props.setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      props.setIsModalOpen(false);
    };


  return (
    <div >
      <Modal title="Investment Portfolio" className='investment-portfolio-modal-cont' open={props.isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
      <div className='invested-portFolio-table-cont'>
        
        <table className='table-style-one'>
            <tr>
                <th>Fund name</th>
                <th>Total investment</th>
                <th>Current value</th>
                <th>Return</th>
                <th>Investment type</th>
                <th>Total SIPs</th>
                <th>Folio number</th>
            </tr>

            {props.investMentPortFolioModalData.map((data)=>{
                return(
                    <tr>
                       
                        <td className="inv_port_fundname">{data.fund_scheme_name?data.fund_scheme_name.slice(0,20):"-"}</td>
                        <td>&#8377;{data.amount}</td>
                        <td>&#8377;{data.current_value}</td>
                        <td>&#8377;{data.total_return}</td>
                        <td>{data.mf_order_type}</td>
                        <td>{data.total_sip}</td>
                        <td>{data.folio_number}</td>
                    </tr>
                )
         })} 
        </table>
    </div>
      </Modal>
    </div>
  )
}
