import React, { useEffect, useState } from "react";
// import { Carousel } from "antd";
import { message } from "antd";
import "./ClientDetails.css";
import axios from "axios";
import config from "../../../config";
import { useParams } from "react-router-dom";
import { ReactComponent as AnniversaryIcon } from "../../../Icons/marriage.svg";
import { ReactComponent as BirthdayIcon } from "../../../Icons/birthday.svg";
import { ReactComponent as UpcomingSipIcon } from "../../../Icons/upcoming-sip-icon.svg";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function ClientEventListCarousel() {
  const params = useParams();
  const [eventData, setEventData] = useState([]);

  const chngObject = (data) => {
    const tempeventdata = eventData;
    if (data.mail_sent_status === false) {
      data.mail_sent_status = true;
    }
    setEventData([...tempeventdata, data]);
  };
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };
  useEffect(() => {
    axios
      .get(config.eventList + `/${params.id}`, { headers: headers })
      .then((res) => {
        // console.log("get event inside carousel", res);
        axios
          .get(config.upcomingSip + `/${params.id}`, { headers: headers })
          .then((response) => {
            //  console.log("upcomming sip received", response);
            setEventData([...response.data, ...res.data]);
          })
          .catch((err) => {
            // console.log("sip recieved err".err)
          });
        // setEventData(res.data);
      })
      .catch((error) => {
        // console.log("get event list failed inside carousel",error);
      });
  }, [params.id]);
  // console.log("get event",eventData)
  const sendWish = (data) => {
    axios
      .post(
        config.sendEvent,
        {
          client_id: data.client_id  ? data.client_id : data.sip_id,
          type_of_event: data.type_of_event,
          event_holder_name: data.event_holder_name? data.event_holder_name:data.client_name,
          // relationship_with_client: data.relationship_with_client,
        },
        { headers: headers }
      )
      .then((res) => {
        // console.log("send wish in client page", res);
        message.success("Mail Sent");
        chngObject(data);
      })
      .catch((error) => {
        // console.log("send wish failed in client page", error);

        message.success("Email allready sent for this event");
      });
  };

  return (
    <div className="carousel-container">
      {eventData.map((data) => {
        if (!data.mail_sent_status) {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // paddingTop: "0.9vw",
                  marginTop: "0.9vw",
                  paddingLeft: "0.9vw",
                  paddingRight: "0.9vw",
                }}
              >
                <div
                  className="icon-event-data-container"
                  // style={{ display: "flex", gap: "0.9vw", textAlign: "baseline" }}
                >
                  <div className="event-icon">
                    {data.type_of_event === "Wedding anniversary" ? (
                      <AnniversaryIcon />
                    ) : "" || data.type_of_event === "SIP Reminder" ? (
                      <UpcomingSipIcon />
                    ) : (
                      <BirthdayIcon />
                    )}
                  </div>
                  <div className="holder-name-date-container">
                    <p className="name">
                      {data.type_of_event === "SIP Reminder"
                        ? data.client_name
                        : data.event_holder_name}
                    </p>
                    <div className="evnt-date-type-contianer">
                      <p className="date">
                        {data.type_of_event === "SIP Reminder"
                          ? data.sip_start_day
                          : data.event_date}
                      </p>
                      <p className="date">|</p>
                      <p className="date">{data.type_of_event}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="send-wishes-btn"
                    onClick={() => sendWish(data)}
                  >
                    {data.type_of_event === "SIP Reminder"
                      ? "Send message"
                      : "Send wishes"}
                  </button>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}
