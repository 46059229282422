import React, { useState, useEffect } from "react";
import "./Marketing.css";
import CheckBoxFilter from "../Clients/Filter/CheckBoxFilter";
import BlogCard from "./BlogCard";
import VideoCard from "./VideoCard";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import config from "../../config";
import { decrypt } from "../TockenConversion/JwtConvert";
export default function Marketing() {
  const location = useLocation();
  const navigate =  useNavigate();
  // const locationState = location.state;
  // console.log(locationState,"****location");

  const [activeBtn, setActiveBtn] = useState(
    location.state?.index ? location.state.index : 0
  );
  const [selections, setSelections] = useState(
    // location.state?.locationFilter?[location.state.locationFilter]:
    []
  );
  const [allSelect,setAllSelect] = useState([])

  // console.log(selections,"--------selections");
  const [contentTypeBtnArr, setContentTypeBtnArr] = useState([]);
  const [marketingData, setMarketingData] = useState([]);
  const [marketingCardData, setMarketingCardData] = useState([]);
  // const [marketingArticalData, setMarketingArticalData] = useState([]);
  // const [marketingVideoData, setMarketingVideoData] = useState([]);
  const [selectedSipType, setSelectedSipType] = useState([]);
  const [selectedFundType, setSelectedFundType] = useState([]);
  const [selectedmarketType, setSelectedmarketType] = useState(
    // location.state?.locationFilter?[location.state.locationFilter]:
    []
  );
  const [selectedSortBy, setSelectedSortBy] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  // const contentTypeBtnArr = [
  //   "Promotional items",
  //   "Funds materials",
  //   "Market updates",
  // ];
  // const locations=[]

  const selectSip = [
    "SIP",
    "Retirement",
    "Children's Education",
    "Tax saving with MF",
    "Other goals",
    "Festival and Events",
  ];
  const fundCategories = ["Fund Factsheets", "Funds in focus", "All Funds"];
  const marketCategories = ["Upcoming NFOs", "Latest news"];
  const selectSortBy = [
    "Most Recent",
    "Most Popular",
    "Video",
    "Image",
    "Article",
    "PDF",
  ];
  const selectByLanguage = ["Hindi", "English"];
  const onclickhandel = (index, event) => {
    setActiveBtn(index);

    //  console.log("EVENT FIRE",index);
    // if(index === 0){
    //   navigate("/marketing/promotionalitems")
    // }
    // if(index === 1){
    //   navigate("/marketing/fundmaterials")
    // }
    // if(index === 2){
    //   navigate("/marketing/marketupdates")
    // }
  };
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };
  useEffect(() => {
    axios
      .get(config.marketing + activeBtn, { headers: headers })
      .then((res) => {
        if (res.data) {
          setContentTypeBtnArr(res.data.list);
          if (res.data.news_object) {
            let tempArr = res.data.data;
            let newsArr = res.data.news_object;
            // console.log([...tempArr,...newsArr]);
            setMarketingData([...tempArr, ...newsArr]);
            setMarketingCardData([...tempArr, ...newsArr]);
          } else {
            setMarketingData(res.data.data);
            setMarketingCardData(res.data.data);
          }
          // console.log("get MARkeTING", res);
        }
      })
      .catch((error) => {
        // console.log("GET MARKETING ERROR",error);
      });
    // console.log(location.state?.locationFilter,"location.state?.locationFilter");
    if (location.state?.locationFilter && activeBtn !== 2) {
      setSelections([]);
      // console.log("inside if of locationFilter");
    } else if (location.state?.locationFilter && activeBtn == 2)
      if (location.state?.flag) {
        setSelections(["Latest news"]);
        location.state.flag = false;
      }
  }, [activeBtn]);

  // useEffect(() => {
  //   const marketingArtical = marketingData.filter(
  //     (data) => data.url_type === "Article"
  //   );
  //   const marketingVideo = marketingData.filter(
  //     (data) => data.url_type === "Video"
  //   );
  //   // console.log("FILTER ARTICAL DAAT",marketingArtical);
  //   setMarketingArticalData(marketingArtical);
  //   // console.log("ARTICAL DATA",marketingArticalData);
  //   setMarketingVideoData(marketingVideo);
  //   // console.log("Video DATA",marketingVideo);
  // }, [marketingData]);

  useEffect(() => {
    let tempArr = selections;
    // console.log(tempArr,"temp arrrrrrrau",selections,":selections");
    let tempSelectSipArr = [];
    let tempSelectFundArr = [];
    ///hello
    let tempSelectMarketArr = [];
    let tempSelectShortByArr = [];
    let tempSelectLangArr = [];
    for (let i = 0; i < tempArr.length; i++) {
      for (let j = 0; j < selectSip.length; j++) {
        if (tempArr[i] === selectSip[j]) {
          tempSelectSipArr.push(tempArr[i]);
        }
      }
      for (let j = 0; j < fundCategories.length; j++) {
        if (tempArr[i] === fundCategories[j]) {
          tempSelectFundArr.push(tempArr[i]);
        }
      }
      for (let j = 0; j < marketCategories.length; j++) {
        if (tempArr[i] === marketCategories[j]) {
          // console.log(tempArr[i],marketCategories[j]);
          tempSelectMarketArr.push(tempArr[i]);
        }
      }
      for (let l = 0; l < selectSortBy.length; l++) {
        if (tempArr[i] === selectSortBy[l]) {
          tempSelectShortByArr.push(tempArr[i]);
        }
      }
      for (let m = 0; m < selectByLanguage.length; m++) {
        if (tempArr[i] === selectByLanguage[m]) {
          tempSelectLangArr.push(tempArr[i]);
        }
      }
    }
    // console.log("SELECTED SIP VALUE", tempSelectSipArr);
    // console.log("SELECTED shortby VALUE", tempSelectShortByArr);
    // console.log("SELECTED lang VALUE", tempSelectLangArr);
    setSelectedSipType(tempSelectSipArr);
    setSelectedFundType(tempSelectFundArr);
    // if(locationState){
    //   console.log("mission success",[...tempSelectMarketArr,locationState.locationFilter],locationState.locationFilter);
    //   setSelectedmarketType([...tempSelectMarketArr,locationState.locationFilter]);
    //   location.state=null
    // }
    // else
    // console.log(tempSelectMarketArr,"tempSelectMarketArr");
    setSelectedmarketType(tempSelectMarketArr);
    setSelectedSortBy(tempSelectShortByArr);
    setSelectedLanguage(tempSelectLangArr);
  }, [selections, location.state]);
  /////////////
  useEffect(() => {
    let marketingMainData = marketingData;
    // console.log(marketingData);
    if (selectedSipType.length > 0) {
      const sipFilter = marketingMainData.filter((data) => {
        for (let i = 0; i < selectedSipType.length; i++) {
          if (selectedSipType[i] === data.url_sub_category) {
            return data;
          }
          if (selectedSipType[i] === "SIP") {
            if (data.url_sub_category === "SIP/ About Mutual Funds") {
              return data;
            }
          }
        }
      });
      marketingMainData = sipFilter;
    }

    if (selectedFundType.length > 0) {
      const fundFilter = marketingMainData.filter((data) => {
        for (let i = 0; i < selectedFundType.length; i++) {
          if (selectedFundType[i] === data.url_sub_category) {
            return data;
          }
        }
      });
      marketingMainData = fundFilter;
    }
    if (selectedmarketType.length > 0) {
      // console.log(selectedmarketType,"selectedmarketType inside",marketingMainData);

      const marketFilter = marketingMainData.filter((data) => {
        // console.log(data,"*********",);

        for (let i = 0; i < selectedmarketType.length; i++) {
          if (selectedmarketType[i] === data.url_sub_category) {
            // console.log(data)
            return data;
          }
        }
      });
      marketingMainData = marketFilter;
    }

    if (selectedSortBy.length > 0) {
      // console.log("SELECTED VALUE SHORT BY",selectedSortBy.length);
      const shortByFilter = marketingMainData.filter((data) => {
        for (let i = 0; i < selectedSortBy.length; i++) {
          if (selectedSortBy[i] === data.url_type) {
            // console.log("FILTER DATA SHORT BY",data);
            return data;
          }
        }
      });
      marketingMainData = shortByFilter;
    }
    if (selectedLanguage.length > 0) {
      const languageFilter = marketingMainData.filter((data) => {
        for (let i = 0; i < selectedLanguage.length; i++) {
          if (selectedLanguage[i] === data.content_language) {
            return data;
          }
        }
      });
      marketingMainData = languageFilter;
    }

    setMarketingCardData(marketingMainData);
    // console.log("FIRST RUN THIS", marketingCardData);
    // console.log(selections, "selections******");
    // console.log("AFTER FILTER MAIN DATA",marketingCardData);
  }, [
    selectedSipType,
    selectedSortBy,
    selectedLanguage,
    selectedFundType,
    selectedmarketType,
    marketingData,
  ]);

  return (
    <div className="marketing-main-cont">
      <Helmet>
        <title>Marketing</title>
        <link rel="icon" href="faveicon.ico" />
      </Helmet>
      <div className="naviagte-to-dashboard-cont" >
        <p className="clients-text" onClick={() => navigate("/dashboard")}>
          Dashboard{" "}
        </p>
        <p className="client-name disable-hover">/ {"Marketing"}</p>
      </div>
      <div className="marketing-heading-cont">
        <h1>Marketing</h1>
      </div>
      <div className="toggel-btn-cont">
        {contentTypeBtnArr.map((btn, index) => {
          return (
            <div>
              <h4
                className={`${
                  index === activeBtn ? "btn-type-active" : "btn-type"
                }`}
                onClick={() => onclickhandel(index)}
              >
                {btn}
              </h4>
            </div>
          );
        })}
      </div>
      <div className="filter-cont">
        <CheckBoxFilter
          filterName={"Category"}
          filterValue={
            activeBtn === 0
              ? selectSip
              : activeBtn === 1
              ? fundCategories
              : marketCategories
          }
          selections={selections}
          searchPlaceholder={"Search by category"}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={
            activeBtn === 0
              ? selectedSipType
              : activeBtn === 1
              ? selectedFundType
              : selectedmarketType
          }
        />
        <CheckBoxFilter
          filterName={"Sort by"}
          filterValue={selectSortBy}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={selectedSortBy}
        />
        <CheckBoxFilter
          filterName={"Select language"}
          filterValue={selectByLanguage}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          searchPlaceholder={"Search by language"}
          filterSelectedValue={selectedLanguage}
          setSelections={setSelections}
        />
      </div>
      <div className="card-cont">
        <BlogCard
          marketingCardData={marketingCardData}
          setMarketingCardData={setMarketingCardData}
        />
        <VideoCard
          marketingCardData={marketingCardData}
          setMarketingCardData={setMarketingCardData}
        />
        {/* <BlogCard />  */}
      </div>
      {/* <div className="card-cont">
        <BlogCard />
        <VideoCard />
        <BlogCard />
      </div> */}
    </div>
  );
}
