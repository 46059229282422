import React,{useState} from "react";
import { Modal, Button ,notification} from "antd";
import AboutCLient from "../ClientnDetails/AboutCLient";
import axios from "axios";
import config from "../../../config";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function SendEmailModal(props) {
  const [loading, setLoading] = useState(false);
const [message,setMessage] =  useState("")
const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };

const sendMailNotification=(type)=>{
  notification[type]({
    message: "Mail sent successfully",
    duration: 3,
    closeIcon: <></>,
  });
}
// console.log("CLIENT ID",props.clientIdForModal);
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
  axios.post(
    config.customEmail,
    {client_id:props.clientIdForModal,client_email_message:message},
    {headers:headers}
  ).then((res)=>{
    if(res.data){
      setLoading(false);
      setMessage("")
      props.setIsModalOpen(false);
      sendMailNotification("success")

    }
    // console.log("post email succ",res);
  }).catch((error)=>{
    // console.log("post email error",error);
  })

    }, 1000);
  };


  const handleCancel = () => {
    props.setIsModalOpen(false);
  };



  const data = [
    { heading: "Client name", subheading:props.clientNameInModal },
    { heading: "Email", subheading: props.clientEmailInModal },
  ];

  return (
    <div className="modal-cont">
      <Modal
        className="client-table-modal"
        title="Send email"
        open={props.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            className="save-btn"
            onClick={handleOk}
              loading={loading}
          >
        Send
          </Button>,
          <Button className="cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className="content-container">
          <div style={{display:"flex",flexDirection:"column",rowGap:"1vw",marginTop:"1.22vw"}}>
            {data.map((data) => {
              return (
                <AboutCLient
                  clientInfoHeading={data.heading}
                  clientInfoHeadingData={data.subheading}
                />
              );
            })}
          </div>

          <div className="text-area-cont">
            <h5>Message</h5>
            <textarea
              // style={{ width: "38.95vw", height: "16.11vw" }}
              placeholder="Type message..."
              onChange={(e)=>setMessage(e.target.value)}
              value={message}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
