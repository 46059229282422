import React from "react";
import { useNavigate } from "react-router-dom";
import "./DataCard.css";
// import { ReactComponent as ActiveCLientIcon } from "../../../Icons/Active-clients-icon.svg";
export default function DataCard(props) {
  const navigate = useNavigate();
  const redirectPage = (event) => {
    // console.log("dskfgejkfgewkfgef",event);
    if (event.target.classList[1] === "active_clients") {
      // console.log(JSON.stringify(event.target.classList[1]));
      //  console.log("dskfgejkfgewkfgef",event);
      navigate("/clients", {
        state: {
          myName: [
            "Active client",
            "Dormant client",
          ],
        },
      });
    } else if (event.target.classList[1] === "earnings") {
      // console.log(JSON.stringify(event.target.classList[1]));
      navigate("/earnings");
    }
    else if(event.target.classList[1] === "aum"){
      navigate("/clients", { state: {myName:["Active client","Dormant client"]}});
    }
  };

  return (
    <div
      className={`card-container ${props.identiFier}`}
      onClick={redirectPage}
    >
   {props.icon}
      <div
        className={`card-heading-value-cont ${props.identiFier}`}
        onClick={redirectPage}
      >
        <h1 className="active-clients-num"> {props.abc}</h1>
        <p className="heading-clients">{props.heading}</p>
      </div>
    </div>
  );
}
