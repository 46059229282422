


let config =  {}
let baseApiAgentsUrl = process.env.REACT_APP_AGENT_API_BASE_URL

config.signup = `${baseApiAgentsUrl}/v1/api/agent/signup`
config.signupOtp = `${baseApiAgentsUrl}/v1/api/agent/otp_verification`
config.email = `${baseApiAgentsUrl}/v1/api/agent/email_register`
config.login = `${baseApiAgentsUrl}/v1/api/agent/login`
config.resendOtp =`${baseApiAgentsUrl}/v1/api/agent/resend_otp`
config.refreshToken = `${baseApiAgentsUrl}/v1/api/refresh_token`
config.logOut = `${baseApiAgentsUrl}/v1/api/agent/logout`
config.agentName = `${baseApiAgentsUrl}/v1/api/agent/signup_details`
config.agentProfile = `${baseApiAgentsUrl}/v1/api/agent/agent_profile`
config.agentPersonalDetail = `${baseApiAgentsUrl}/v1/api/agent/personal_information`
config.agentBankingDetail = `${baseApiAgentsUrl}/v1/api/agent/banking_details`
config.otherLinks = `${baseApiAgentsUrl}/v1/api/agent/social_accounts_url`
config.agentPayment = `${baseApiAgentsUrl}/v1/api/agent/payment`
config.contactRm =  `${baseApiAgentsUrl}/v1/api/agent/contact_rm`
config.eventList = `${baseApiAgentsUrl}/v1/api/agent/event_list`
config.sendEvent = `${baseApiAgentsUrl}/v1/api/agent/mail_event`
config.emailFunds =  `${baseApiAgentsUrl}/v1/api/agent/email_funds`
config.smsFunds =  `${baseApiAgentsUrl}/v1/api/agent/sms_funds`
config.dashboardNews =`${baseApiAgentsUrl}/v1/api/agent/dashboard_news/`
config.clientTags =`${baseApiAgentsUrl}/v1/api/agent/client_tags`
config.leedsSendEmail =`${baseApiAgentsUrl}/v1/api/agent/leads_email`
config.leadsSendSms =`${baseApiAgentsUrl}/v1/api/agent/leads_sms`
config.customEmail  =`${baseApiAgentsUrl}/v1/api/agent/custom_email`
config.marketing =`${baseApiAgentsUrl}/v1/api/agent/marketing_screen/`
config.meetingscheduleMail =`${baseApiAgentsUrl}/v1/api/agent/send_gmeet_mail`
config.meetingscheduleLink =`${baseApiAgentsUrl}/v1/api/agent/google_meet`
config.marketingBookMark =`${baseApiAgentsUrl}/v1/api/agent/bookmark_marketing`
config.coBrandImg =`${baseApiAgentsUrl}/v1/api/user/agent_co_brand_image`

// --------------Clients ------_API-------------------

config.activeClient_totalAum_totalIncome =`${baseApiAgentsUrl}/v1/api/clients/total_amount`
config.allClientTableData = `${baseApiAgentsUrl}/v1/api/user/get`
config.singleCLientDetail = `${baseApiAgentsUrl}/v1/api/clients/detail`
config.upcomingtransaction = `${baseApiAgentsUrl}/v1/api/user/upcoming_transaction`
config.clientPortfolioSummary =  `${baseApiAgentsUrl}/v1/api/user/portfolio_status`
config.clientsTransaction =  `${baseApiAgentsUrl}/v1/api/clients/order_status`
config.clientGraph =  `${baseApiAgentsUrl}/v1/api/clients/equity_status`
config.recommendationFund =  `${baseApiAgentsUrl}/v1/api/user/recommendation_fund`
config.fundlisting =  `${baseApiAgentsUrl}/v1/api/user/allfunddetails`
config.fundDetail =  `${baseApiAgentsUrl}/v1/api/user/fund_status/`

config.fundPublicShare = `${baseApiAgentsUrl}/v1/api/user/public_fund_detail_share/`
config.shareUrl = baseApiAgentsUrl

config.fundGraph =`${baseApiAgentsUrl}/v1/api/user/fund_graph/`
config.fundProsCons =`${baseApiAgentsUrl}/v1/api/user/fund_pros_cons/`
config.fintechFund =`${baseApiAgentsUrl}/v1/api/user/fintech_fund/`
config.similarFund =`${baseApiAgentsUrl}/v1/api/user/similar_fund/`
config.fundtaxInfo =`${baseApiAgentsUrl}/v1/api/user/fund_tax_info/`
config.aboutFundAmc =`${baseApiAgentsUrl}/v1/api/user/fund_get_amc/`
config.currentInvestment =`${baseApiAgentsUrl}/v1/api/user/current_invested/`
config.activeLeeds  =`${baseApiAgentsUrl}/v1/api/user/active_leeds`
config.transactions  =`${baseApiAgentsUrl}/v1/api/user/order_details`
config.clientName =`${baseApiAgentsUrl}/v1/api/user/client_name`

config.investMentPortFolio = `${baseApiAgentsUrl}/v1/api/user/portfolio_invested`
config.singleInvestMentPortFolio = `${baseApiAgentsUrl}/v1/api/user/sip_transaction_portfolio`
config.addNewClient =`${baseApiAgentsUrl}/v1/api/user/agent_client_onboarding`
config.fundBookMark =`${baseApiAgentsUrl}/v1/api/user/fund_add_bookmark`
config.agreeMentDoc =`${baseApiAgentsUrl}/v1/api/agent/agent_agreement_upload`
config.forgotPassword =`${baseApiAgentsUrl}/v1/api/agent/forget_password`
config.setPassword =`${baseApiAgentsUrl}/v1/api/agent/reset_password`
config.upcomingSip =`${baseApiAgentsUrl}/v1/api/agent/upcoming_sip`
















// if(process.env.REACT_APP_ENV === "development"){


    // config.signup = "${baseApiAgentsUrl}/v1/api/agent/signup"
    // config.signupOtp = "${baseApiAgentsUrl}/v1/api/agent/otp_verification"
    // config.email = "${baseApiAgentsUrl}/v1/api/agent/email_register"
    // config.login = "${baseApiAgentsUrl}/v1/api/agent/login"
    // config.resendOtp ="${baseApiAgentsUrl}/v1/api/agent/resend_otp"
    // config.refreshToken = "${baseApiAgentsUrl}/v1/api/refresh_token"
    // config.logOut = "${baseApiAgentsUrl}/v1/api/agent/logout"
    // config.agentName = "${baseApiAgentsUrl}/v1/api/agent/signup_details"
    // config.agentProfile = "${baseApiAgentsUrl}/v1/api/agent/agent_profile"
    // config.agentPersonalDetail = "${baseApiAgentsUrl}/v1/api/agent/personal_information"
    // config.agentBankingDetail = "${baseApiAgentsUrl}/v1/api/agent/banking_details"
    // config.otherLinks = "${baseApiAgentsUrl}/v1/api/agent/social_accounts_url"
    // config.agentPayment = "${baseApiAgentsUrl}/v1/api/agent/payment"
    // config.contactRm =  "${baseApiAgentsUrl}/v1/api/agent/contact_rm"
    // config.eventList = "${baseApiAgentsUrl}/v1/api/agent/event_list"
    // config.sendEvent = "${baseApiAgentsUrl}/v1/api/agent/mail_event"
    // config.emailFunds =  "${baseApiAgentsUrl}/v1/api/agent/email_funds"
    // config.smsFunds =  "${baseApiAgentsUrl}/v1/api/agent/sms_funds"
    // config.dashboardNews ="${baseApiAgentsUrl}/v1/api/agent/dashboard_news/"
    // config.clientTags ="${baseApiAgentsUrl}/v1/api/agent/client_tags"
    // config.leedsSendEmail ="${baseApiAgentsUrl}/v1/api/agent/leads_email"
    // config.leadsSendSms ="${baseApiAgentsUrl}/v1/api/agent/leads_sms"
    // config.customEmail  ="${baseApiAgentsUrl}/v1/api/agent/custom_email"
    // config.marketing ="${baseApiAgentsUrl}/v1/api/agent/marketing_screen/"
    // config.meetingscheduleMail ="${baseApiAgentsUrl}/v1/api/agent/send_gmeet_mail"
    // config.meetingscheduleLink ="${baseApiAgentsUrl}/v1/api/agent/google_meet"
    // config.marketingBookMark ="${baseApiAgentsUrl}/v1/api/agent/bookmark_marketing"
    // // --------------Clients ------_API-------------------
    
    // config.activeClient_totalAum_totalIncome ="${baseApiAgentsUrl}/v1/api/clients/total_amount"
    // config.allClientTableData = "${baseApiAgentsUrl}/v1/api/user/get"
    // config.singleCLientDetail = "${baseApiAgentsUrl}/v1/api/clients/detail"
    // config.upcomingtransaction = "${baseApiAgentsUrl}/v1/api/user/upcoming_transaction"
    // config.clientPortfolioSummary =  "${baseApiAgentsUrl}/v1/api/user/portfolio_status"
    // config.clientsTransaction =  "${baseApiAgentsUrl}/v1/api/clients/order_status"
    // config.clientGraph =  "${baseApiAgentsUrl}/v1/api/clients/equity_status"
    // config.recommendationFund =  "${baseApiAgentsUrl}/v1/api/user/recommendation_fund"
    // config.fundlisting =  "${baseApiAgentsUrl}/v1/api/user/allfunddetails"
    // config.fundDetail =  "${baseApiAgentsUrl}/v1/api/user/fund_status/"

    // config.fundPublicShare = "${baseApiAgentsUrl}/v1/api/user/public_fund_detail_share/"
    // config.shareUrl = "https://ht${baseApiAgentsUrl}/"

    // config.fundGraph ="${baseApiAgentsUrl}/v1/api/user/fund_graph/"
    // config.fundProsCons ="${baseApiAgentsUrl}/v1/api/user/fund_pros_cons/"
    // config.fintechFund ="${baseApiAgentsUrl}/v1/api/user/fintech_fund/"
    // config.similarFund ="${baseApiAgentsUrl}/v1/api/user/similar_fund/"
    // config.fundtaxInfo ="${baseApiAgentsUrl}/v1/api/user/fund_tax_info/"
    // config.aboutFundAmc ="${baseApiAgentsUrl}/v1/api/user/fund_get_amc/"
    // config.currentInvestment ="${baseApiAgentsUrl}/v1/api/user/current_invested/"
    // config.activeLeeds  ="${baseApiAgentsUrl}/v1/api/user/active_leeds"
    // config.transactions  ="${baseApiAgentsUrl}/v1/api/user/order_details"
    // config.clientName ="${baseApiAgentsUrl}/v1/api/user/client_name"
    // // config.investMentPortFolio = "${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
    // config.investMentPortFolio = "${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
    // config.singleinvestMentPortFolio = "${baseApiAgentsUrl}/v1/api/user/sip_transaction_portfolio"

    // config.addNewClient ="${baseApiAgentsUrl}/v1/api/user/agent_client_onboarding"
    // config.fundBookMark ="${baseApiAgentsUrl}/v1/api/user/fund_add_bookmark"
    // config.agreeMentDoc ="${baseApiAgentsUrl}/v1/api/agent/agent_agreement_upload"
    // config.forgotPassword ="${baseApiAgentsUrl}/v1/api/agent/forget_password"
    // config.setPassword ="${baseApiAgentsUrl}/v1/api/agent/reset_password"



//  --------------Agent development--------API-------------------   
    
//     config.signup = "http${baseApiAgentsUrl}/v1/api/agent/signup"
//     config.signupOtp = "http${baseApiAgentsUrl}/v1/api/agent/otp_verification"
//     config.email = "http${baseApiAgentsUrl}/v1/api/agent/email_register"

//     config.login = "http${baseApiAgentsUrl}/v1/api/agent/login"
//     // config.login = "${baseApiAgentsUrl}/v1/api/agent/login"
//     config.resendOtp ="http${baseApiAgentsUrl}/v1/api/agent/resend_otp"
//     config.refreshToken = "http${baseApiAgentsUrl}/v1/api/refresh_token"
//     config.logOut = "http${baseApiAgentsUrl}/v1/api/agent/logout"
//     config.agentName = "http${baseApiAgentsUrl}/v1/api/agent/signup_details"
//     config.agentProfile = "http${baseApiAgentsUrl}/v1/api/agent/agent_profile"
//     config.agentPersonalDetail = "http${baseApiAgentsUrl}/v1/api/agent/personal_information"
//     config.agentBankingDetail = "http${baseApiAgentsUrl}/v1/api/agent/banking_details"
//     config.otherLinks = "http${baseApiAgentsUrl}/v1/api/agent/social_accounts_url"
//     config.agentPayment = "http${baseApiAgentsUrl}/v1/api/agent/payment"
//     config.contactRm =  "http${baseApiAgentsUrl}/v1/api/agent/contact_rm"
//     config.eventList = "http${baseApiAgentsUrl}/v1/api/agent/event_list"
//     config.sendEvent = "http${baseApiAgentsUrl}/v1/api/agent/mail_event"
//     config.emailFunds =  "http${baseApiAgentsUrl}/v1/api/agent/email_funds"
//     config.smsFunds =  "http${baseApiAgentsUrl}/v1/api/agent/sms_funds"
//     config.dashboardNews ="http${baseApiAgentsUrl}/v1/api/agent/dashboard_news/"
//     config.clientTags ="http${baseApiAgentsUrl}/v1/api/agent/client_tags"
//     config.leedsSendEmail ="http${baseApiAgentsUrl}/v1/api/agent/leads_email"
//     config.leadsSendSms ="http${baseApiAgentsUrl}/v1/api/agent/leads_sms"
//     // config.leedsSendEmail ="${baseApiAgentsUrl}/v1/api/agent/leads_email"
//     // config.leadsSendSms ="${baseApiAgentsUrl}/v1/api/agent/leads_sms"
//     config.customEmail  ="http${baseApiAgentsUrl}/v1/api/agent/custom_email"
//     config.marketing ="http${baseApiAgentsUrl}/v1/api/agent/marketing_screen/"
//     config.meetingscheduleMail ="http${baseApiAgentsUrl}/v1/api/agent/send_gmeet_mail"
//     config.meetingscheduleLink ="http${baseApiAgentsUrl}/v1/api/agent/google_meet"
//     config.marketingBookMark ="http${baseApiAgentsUrl}/v1/api/agent/bookmark_marketing"
//     config.coBrandImg ="http${baseApiAgentsUrl}/v1/api/user/agent_co_brand_image"

//     // config.email
     
//     // --------------Clients ------_API-------------------

   

//     // config.activeClient_totalAum_totalIncome ="${baseApiAgentsUrl}/v1/api/clients/total_amount"
    
//     config.activeClient_totalAum_totalIncome ="http${baseApiAgentsUrl}/v1/api/clients/total_amount"
//     config.allClientTableData = "http${baseApiAgentsUrl}/v1/api/user/get"
//     config.singleCLientDetail = "http${baseApiAgentsUrl}/v1/api/clients/detail"
//     config.upcomingtransaction = "http${baseApiAgentsUrl}/v1/api/user/upcoming_transaction"
//     config.clientPortfolioSummary =  "http${baseApiAgentsUrl}/v1/api/user/portfolio_status"
//     config.clientsTransaction =  "http${baseApiAgentsUrl}/v1/api/clients/order_status"
//     config.clientGraph =  "http${baseApiAgentsUrl}/v1/api/clients/equity_status"
//     config.recommendationFund =  "http${baseApiAgentsUrl}/v1/api/user/recommendation_fund"
//     config.fundlisting =  "http${baseApiAgentsUrl}/v1/api/user/allfunddetails"
//     config.fundDetail =  "http${baseApiAgentsUrl}/v1/api/user/fund_status/"
//     config.fundGraph ="http${baseApiAgentsUrl}/v1/api/user/fund_graph/"
//     config.fundProsCons ="http${baseApiAgentsUrl}/v1/api/user/fund_pros_cons/"
//     config.fintechFund ="http${baseApiAgentsUrl}/v1/api/user/fintech_fund/"
//     config.similarFund ="http${baseApiAgentsUrl}/v1/api/user/similar_fund/"
//     config.fundtaxInfo ="http${baseApiAgentsUrl}/v1/api/user/fund_tax_info/"
//     config.aboutFundAmc ="http${baseApiAgentsUrl}/v1/api/user/fund_get_amc/"
//     config.currentInvestment ="http${baseApiAgentsUrl}/v1/api/user/current_invested/"
//     config.activeLeeds  ="http${baseApiAgentsUrl}/v1/api/user/active_leeds"
//     config.transactions  ="http${baseApiAgentsUrl}/v1/api/user/transaction_detail"
//     config.clientName ="http${baseApiAgentsUrl}/v1/api/user/client_name"
//     config.investMentPortFolio = "http${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
//     config.addNewClient ="http${baseApiAgentsUrl}/v1/api/user/agent_client_onboarding"
//     config.fundBookMark ="http${baseApiAgentsUrl}/v1/api/user/fund_add_bookmark"
//     // config.activeClient_totalAum_totalIncome ="http${baseApiAgentsUrl}/v1/api/clients/total_amount"
//     // config.allClientTableData = "http${baseApiAgentsUrl}/v1/api/user/get"
//     config.singleCLientDetail = "http${baseApiAgentsUrl}/v1/api/clients/detail"
//     config.upcomingtransaction = "http${baseApiAgentsUrl}/v1/api/user/upcoming_transaction"
//     config.clientPortfolioSummary =  "http${baseApiAgentsUrl}/v1/api/user/portfolio_status"
//     config.clientsTransaction =  "http${baseApiAgentsUrl}/v1/api/clients/order_status"
//     // config.clientGraph =  "http${baseApiAgentsUrl}/v1/api/clients/equity_status"
//     config.recommendationFund =  "http${baseApiAgentsUrl}/v1/api/user/recommendation_fund"
//     config.fundlisting =  "http${baseApiAgentsUrl}/v1/api/user/allfunddetails"
//     config.fundDetail =  "http${baseApiAgentsUrl}/v1/api/user/fund_status/"
//     // /new added for public fund details share

//     config.fundPublicShare = "http${baseApiAgentsUrl}/v1/api/user/public_fund_detail_share/"
//     // config.shareUrl = "172.31.99.155:3000/"
//     config.shareU${baseApiAgentsUrl}/"
//     config.fundGraph ="http${baseApiAgentsUrl}/v1/api/user/fund_graph/"
//     config.fundProsCons ="http${baseApiAgentsUrl}/v1/api/user/fund_pros_cons/"
//     config.fintechFund ="http${baseApiAgentsUrl}/v1/api/user/fintech_fund/"
//     config.similarFund ="http${baseApiAgentsUrl}/v1/api/user/similar_fund/"
//     config.fundtaxInfo ="http${baseApiAgentsUrl}/v1/api/user/fund_tax_info/"
//     config.aboutFundAmc ="http${baseApiAgentsUrl}/v1/api/user/fund_get_amc/"
//     config.currentInvestment ="http${baseApiAgentsUrl}/v1/api/user/current_invested/"
//     config.activeLeeds  ="http${baseApiAgentsUrl}/v1/api/user/active_leeds"
//     // config.activeLeeds  ="${baseApiAgentsUrl}/v1/api/user/active_leeds"
//     // config.transactions  ="http${baseApiAgentsUrl}/v1/api/user/transaction_detail"
//     config.transactions  ="http${baseApiAgentsUrl}/v1/api/user/order_details"
//     // config.clientName ="http${baseApiAgentsUrl}/v 1/api/user/client_name"
//     // config.investMentPortFolio = "http${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
//     config.investMentPortFolio = "http${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
//     config.singleInvestMentPortFolio ="http${baseApiAgentsUrl}/v1/api/user/sip_transaction_portfolio"
//     config.addNewClient ="http${baseApiAgentsUrl}/v1/api/user/agent_client_onboarding"
//     config.fundBookMark ="http${baseApiAgentsUrl}/v1/api/user/fund_add_bookmark"
//     config.agreeMentDoc ="http${baseApiAgentsUrl}/v1/api/agent/agent_agreement_upload"
//     config.forgotPassword ="http${baseApiAgentsUrl}/v1/api/agent/forget_password"
//     config.setPassword ="http${baseApiAgentsUrl}/v1/api/agent/reset_password"
//     config.upcomingSip ="http${baseApiAgentsUrl}/v1/api/agent/upcoming_sip"

// //    config.shareFundDetailsVia ="http${baseApiAgentsUrl}/agent/
  
    
    
   
// }
// else if(process.env.REACT_APP_ENV==="staging"){
//     config.signup = "https://${baseApiAgentsUrl}/v1/api/agent/signup"
//     config.signupOtp = "https://${baseApiAgentsUrl}/v1/api/agent/otp_verification"
//     config.email = "https://${baseApiAgentsUrl}/v1/api/agent/email_register"
//     config.login = "https://${baseApiAgentsUrl}/v1/api/agent/login"
//     config.resendOtp ="https://${baseApiAgentsUrl}/v1/api/agent/resend_otp"
//     config.refreshToken = "https://${baseApiAgentsUrl}/v1/api/refresh_token"
//     config.logOut = "https://${baseApiAgentsUrl}/v1/api/agent/logout"
//     config.agentName = "https://${baseApiAgentsUrl}/v1/api/agent/signup_details"
//     config.agentProfile = "https://${baseApiAgentsUrl}/v1/api/agent/agent_profile"
//     config.agentPersonalDetail = "https://${baseApiAgentsUrl}/v1/api/agent/personal_information"
//     config.agentBankingDetail = "https://${baseApiAgentsUrl}/v1/api/agent/banking_details"
//     config.otherLinks = "https://${baseApiAgentsUrl}/v1/api/agent/social_accounts_url"
//     config.agentPayment = "https://${baseApiAgentsUrl}/v1/api/agent/payment"
//     config.contactRm =  "https://${baseApiAgentsUrl}/v1/api/agent/contact_rm"
//     config.eventList = "https://${baseApiAgentsUrl}/v1/api/agent/event_list"
//     config.sendEvent = "https://${baseApiAgentsUrl}/v1/api/agent/mail_event"
//     config.emailFunds =  "https://${baseApiAgentsUrl}/v1/api/agent/email_funds"
//     config.smsFunds =  "https://${baseApiAgentsUrl}/v1/api/agent/sms_funds"
//     config.dashboardNews ="https://${baseApiAgentsUrl}/v1/api/agent/dashboard_news/"
//     config.clientTags ="https://${baseApiAgentsUrl}/v1/api/agent/client_tags"
//     config.leedsSendEmail ="https://${baseApiAgentsUrl}/v1/api/agent/leads_email"
//     config.leadsSendSms ="https://${baseApiAgentsUrl}/v1/api/agent/leads_sms"
//     config.customEmail  ="https://${baseApiAgentsUrl}/v1/api/agent/custom_email"
//     config.marketing ="https://${baseApiAgentsUrl}/v1/api/agent/marketing_screen/"
//     config.meetingscheduleMail ="https://${baseApiAgentsUrl}/v1/api/agent/send_gmeet_mail"
//     config.meetingscheduleLink ="https://${baseApiAgentsUrl}/v1/api/agent/google_meet"
//     config.marketingBookMark ="https://${baseApiAgentsUrl}/v1/api/agent/bookmark_marketing"
//     config.coBrandImg ="https://${baseApiAgentsUrl}/v1/api/user/agent_co_brand_image"
//     // --------------Clients ------_API-------------------
    
//     config.activeClient_totalAum_totalIncome ="https://${baseApiAgentsUrl}/v1/api/clients/total_amount"
//     config.allClientTableData = "https://${baseApiAgentsUrl}/v1/api/user/get"
//     config.singleCLientDetail = "https://${baseApiAgentsUrl}/v1/api/clients/detail"
//     config.upcomingtransaction = "https://${baseApiAgentsUrl}/v1/api/user/upcoming_transaction"
//     config.clientPortfolioSummary =  "https://${baseApiAgentsUrl}/v1/api/user/portfolio_status"
//     config.clientsTransaction =  "https://${baseApiAgentsUrl}/v1/api/clients/order_status"
//     config.clientGraph =  "https://${baseApiAgentsUrl}/v1/api/clients/equity_status"
//     config.recommendationFund =  "https://${baseApiAgentsUrl}/v1/api/user/recommendation_fund"
//     config.fundlisting =  "https://${baseApiAgentsUrl}/v1/api/user/allfunddetails"
//     config.fundDetail =  "https://${baseApiAgentsUrl}/v1/api/user/fund_status/"

//     config.fundPublicShare = "https://${baseApiAgentsUrl}/v1/api/user/public_fund_detail_share/"
//     config.shareU${baseApiAgentsUrl}/"

//     config.fundGraph ="https://${baseApiAgentsUrl}/v1/api/user/fund_graph/"
//     config.fundProsCons ="https://${baseApiAgentsUrl}/v1/api/user/fund_pros_cons/"
//     config.fintechFund ="https://${baseApiAgentsUrl}/v1/api/user/fintech_fund/"
//     config.similarFund ="https://${baseApiAgentsUrl}/v1/api/user/similar_fund/"
//     config.fundtaxInfo ="https://${baseApiAgentsUrl}/v1/api/user/fund_tax_info/"
//     config.aboutFundAmc ="https://${baseApiAgentsUrl}/v1/api/user/fund_get_amc/"
//     config.currentInvestment ="https://${baseApiAgentsUrl}/v1/api/user/current_invested/"
//     config.activeLeeds  ="https://${baseApiAgentsUrl}/v1/api/user/active_leeds"
//     config.transactions  ="https://${baseApiAgentsUrl}/v1/api/user/order_details"
//     config.clientName ="https://${baseApiAgentsUrl}/v1/api/user/client_name"
//     // config.investMentPortFolio = "httsp://${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
//     config.investMentPortFolio = "https://${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
//     config.singleInvestMentPortFolio = "https://${baseApiAgentsUrl}/v1/api/user/sip_transaction_portfolio"
//     config.addNewClient ="https://${baseApiAgentsUrl}/v1/api/user/agent_client_onboarding"
//     config.fundBookMark ="https://${baseApiAgentsUrl}/v1/api/user/fund_add_bookmark"
//     config.agreeMentDoc ="https://${baseApiAgentsUrl}/v1/api/agent/agent_agreement_upload"
//     config.forgotPassword ="https://${baseApiAgentsUrl}/v1/api/agent/forget_password"
//     config.setPassword ="https://${baseApiAgentsUrl}/v1/api/agent/reset_password"
//     config.upcomingSip ="https://${baseApiAgentsUrl}/v1/api/agent/upcoming_sip"
    
// }
// else{

//     config.signup = "${baseApiAgentsUrl}/v1/api/agent/signup"
//     config.signupOtp = "${baseApiAgentsUrl}/v1/api/agent/otp_verification"
//     config.email = "${baseApiAgentsUrl}/v1/api/agent/email_register"
//     config.login = "${baseApiAgentsUrl}/v1/api/agent/login"
//     config.resendOtp ="${baseApiAgentsUrl}/v1/api/agent/resend_otp"
//     config.refreshToken = "${baseApiAgentsUrl}/v1/api/refresh_token"
//     config.logOut = "${baseApiAgentsUrl}/v1/api/agent/logout"
//     config.agentName = "${baseApiAgentsUrl}/v1/api/agent/signup_details"
//     config.agentProfile = "${baseApiAgentsUrl}/v1/api/agent/agent_profile"
//     config.agentPersonalDetail = "${baseApiAgentsUrl}/v1/api/agent/personal_information"
//     config.agentBankingDetail = "${baseApiAgentsUrl}/v1/api/agent/banking_details"
//     config.otherLinks = "${baseApiAgentsUrl}/v1/api/agent/social_accounts_url"
//     config.agentPayment = "${baseApiAgentsUrl}/v1/api/agent/payment"
//     config.contactRm =  "${baseApiAgentsUrl}/v1/api/agent/contact_rm"
//     config.eventList = "${baseApiAgentsUrl}/v1/api/agent/event_list"
//     config.sendEvent = "${baseApiAgentsUrl}/v1/api/agent/mail_event"
//     config.emailFunds =  "${baseApiAgentsUrl}/v1/api/agent/email_funds"
//     config.smsFunds =  "${baseApiAgentsUrl}/v1/api/agent/sms_funds"
//     config.dashboardNews ="${baseApiAgentsUrl}/v1/api/agent/dashboard_news/"
//     config.clientTags ="${baseApiAgentsUrl}/v1/api/agent/client_tags"
//     config.leedsSendEmail ="${baseApiAgentsUrl}/v1/api/agent/leads_email"
//     config.leadsSendSms ="${baseApiAgentsUrl}/v1/api/agent/leads_sms"
//     config.customEmail  ="${baseApiAgentsUrl}/v1/api/agent/custom_email"
//     config.marketing ="${baseApiAgentsUrl}/v1/api/agent/marketing_screen/"
//     config.meetingscheduleMail ="${baseApiAgentsUrl}/v1/api/agent/send_gmeet_mail"
//     config.meetingscheduleLink ="${baseApiAgentsUrl}/v1/api/agent/google_meet"
//     config.marketingBookMark ="${baseApiAgentsUrl}/v1/api/agent/bookmark_marketing"
//     config.coBrandImg ="${baseApiAgentsUrl}/v1/api/user/agent_co_brand_image"
    
//     // --------------Clients ------_API-------------------
    
//     config.activeClient_totalAum_totalIncome ="${baseApiAgentsUrl}/v1/api/clients/total_amount"
//     config.allClientTableData = "${baseApiAgentsUrl}/v1/api/user/get"
//     config.singleCLientDetail = "${baseApiAgentsUrl}/v1/api/clients/detail"
//     config.upcomingtransaction = "${baseApiAgentsUrl}/v1/api/user/upcoming_transaction"
//     config.clientPortfolioSummary =  "${baseApiAgentsUrl}/v1/api/user/portfolio_status"
//     config.clientsTransaction =  "${baseApiAgentsUrl}/v1/api/clients/order_status"
//     config.clientGraph =  "${baseApiAgentsUrl}/v1/api/clients/equity_status"
//     config.recommendationFund =  "${baseApiAgentsUrl}/v1/api/user/recommendation_fund"
//     config.fundlisting =  "${baseApiAgentsUrl}/v1/api/user/allfunddetails"
//     config.fundDetail =  "${baseApiAgentsUrl}/v1/api/user/fund_status/"

//     config.fundPublicShare = "${baseApiAgentsUrl}/v1/api/user/public_fund_detail_share/"
//     config.shareU${baseApiAgentsUrl}/"

//     config.fundGraph ="${baseApiAgentsUrl}/v1/api/user/fund_graph/"
//     config.fundProsCons ="${baseApiAgentsUrl}/v1/api/user/fund_pros_cons/"
//     config.fintechFund ="${baseApiAgentsUrl}/v1/api/user/fintech_fund/"
//     config.similarFund ="${baseApiAgentsUrl}/v1/api/user/similar_fund/"
//     config.fundtaxInfo ="${baseApiAgentsUrl}/v1/api/user/fund_tax_info/"
//     config.aboutFundAmc ="${baseApiAgentsUrl}/v1/api/user/fund_get_amc/"
//     config.currentInvestment ="${baseApiAgentsUrl}/v1/api/user/current_invested/"
//     config.activeLeeds  ="${baseApiAgentsUrl}/v1/api/user/active_leeds"
//     config.transactions  ="${baseApiAgentsUrl}/v1/api/user/order_details"
//     config.clientName ="${baseApiAgentsUrl}/v1/api/user/client_name"
//     // config.investMentPortFolio = "${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
//     config.investMentPortFolio = "${baseApiAgentsUrl}/v1/api/user/portfolio_invested"
//     config.singleInvestMentPortFolio = "${baseApiAgentsUrl}/v1/api/user/sip_transaction_portfolio"
//     config.addNewClient ="${baseApiAgentsUrl}/v1/api/user/agent_client_onboarding"
//     config.fundBookMark ="${baseApiAgentsUrl}/v1/api/user/fund_add_bookmark"
//     config.agreeMentDoc ="${baseApiAgentsUrl}/v1/api/agent/agent_agreement_upload"
//     config.forgotPassword ="${baseApiAgentsUrl}/v1/api/agent/forget_password"
//     config.setPassword ="${baseApiAgentsUrl}/v1/api/agent/reset_password"
//     config.upcomingSip ="${baseApiAgentsUrl}/v1/api/agent/upcoming_sip"

// }
export default config