import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
// import { set } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config"

import WealthCalculator from "../Clients/ClientnDetails/WealthCalculator";
import "../Clients/FundList/FundDetails/FundDetails.css"
import FundGraph from "../Clients/FundList/FundDetails/FundDetails";
import FundMarketDetail from "../Clients/FundList/FundDetails/FundMarketDetail";
import PublicFundShareGraph from "./PublicFundShareGraph";

export default function PublicShareFundDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const [fundDetail, setFundDetail] = useState({});
  const [fundYearlyPerc, setFundYearlyPerc] = useState([]);
  // const [fundProsCons, setFundProsCons] = useState({});
  const [fundPros, setFundPros] = useState({});
  const [fundCons, setFundCons] = useState({});
  const [similarFundData, setSimilarFundData] = useState([]);
  const [aboutAmc, setAboutAmc] = useState("");
  const [fundGraphNav, setFundGraphNav] = useState([]);
  const [fundGraphDate, setFundGraphDate] = useState([]);
  const [fundGraphSliceNav, setFundGraphSliceNav] = useState([]);
  const [fundGraphSliceDate, setFundGraphSliceDate] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [minSipLump, setMinSipLump] = useState([]);
  const [currentInvesment, setCurrentInvesment] = useState([]);
  const [listOfCLient, setListOfClient] = useState([]);
  const[fundBookmark,setFundBookMark] =  useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const[nav,setNav] =  useState(Number)
  // const[aum,setAum] =  useState("")

// console.log("MIN SIP MIN aum",aum);
const redirectToCLientDetailPage=(data)=>{
  // navigate(`/clients/client/${data.user_id}`);
}


  const chartTimerButton = [
    {
      btn: "All time",
    },
    {
      btn: "5Y",
    },
    {
      btn: "3Y",
    },
    {
      btn: "1Y",
    },
    {
      btn: "6M",
    },
    {
      btn: "3M",
    },
    {
      btn: "1M",
    },
  ];

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  };

  useEffect(() => {
//  console.log("cgfhfxsjtukygjfhljkvtf.n utgjhvb");
    axios
      .get(config.fundPublicShare + params.isin, { headers: headers })
      .then((res) => {
        // console.log("get public fund details", res);
        setFundDetail(res.data.data);
        // setNav(res.data.data.latest_nav)
        
        // setAum(res.data.data.aum)
        // setFundBookMark(res.data.fund_bookmark_status)

        const fundYearlyPercnt = [
          {
            heading: "2018",
            subheading: `${res.data.data.return2018 ? res.data.data.return2018 + "%" : "0"  + "%" }`,
          },
          {
            heading: "2019",
            subheading: `${res.data.data.return2019 ? res.data.data.return2019  + "%" : "0"  + "%" }`,
          },
          {
            heading: "2020",
            subheading: `${res.data.data.return2020 ? res.data.data.return2020  + "%" : "0"  + "%" }`,
          },
          {
            heading: "2021",
            subheading: `${res.data.data.return2021 ? res.data.data.return2021  + "%" : "0"  + "%" }`,
          },
          {
            heading: "2022",
            subheading: `${res.data.data.return2022 ? res.data.data.return2022  + "%" : "0"  + "%" }`,
          },
        ];
        setFundYearlyPerc(fundYearlyPercnt);
      })
      .catch((error) => {
        //  console.log("get fund error details",error);
      });

    // ---------fintech-fund--api----------------------------
    axios
      .get(config.fintechFund + params.isin, { headers: headers })
      .then((res) => {

        const fundpricing = [
          {
            heading: "NAV",
            subheading: "₹" + `${res.data.latest_nav.toFixed(2)}`,
          },
          {
            heading: "Total AUM(cr)",
            subheading: "₹" + (res.data.total_aum/ 10000000).toFixed(2),
          },
          {
            heading: "Min. SIP Investment",
            subheading: "₹" + res.data.min_sip_amount,
          },
          {
            heading: "Min. lumpsum",
            subheading: "₹" + res.data.min_initial_investment,
          },
        ];
        setMinSipLump(fundpricing);
        // console.log("get fintech amrv57tbgynlop", res);
        // console.log("FUND DEATIL",fundDetail);
      })
      .catch((error) => {
        // console.log("get fund fintech", error);
      });

    // ------------fund--pros--cons---Api calll----------
    axios
      .get(config.fundProsCons + params.isin, { headers: headers })
      .then((res) => {
        // console.log("get fund proscons", res);
        setFundPros(res.data.pros);
        setFundCons(res.data.cons);
      })
      .catch((error) => {
        // console.log("get proscons error", error);
      });

    // ------------similar--fund----api-----------
    
    axios
      .get(config.similarFund + params.isin, { headers: headers })
      .then((res) => {
        // console.log("get similar fund", res);

        const similarfund = [
          {
            heading: `${res.data[0].scheme_name}`,
            subheading: res.data[0].subcategory,
            percent: res.data[0].return1y,
            logo: res.data[0].logo,
          },
          {
            heading: `${res.data[1].scheme_name}`,
            subheading: res.data[1].subcategory,
            percent: res.data[1].return1y,
            logo: res.data[1].logo,
          },
          {
            heading: `${res.data[0].scheme_name}`,
            subheading: res.data[0].subcategory,
            percent: res.data[0].return1y,
            logo: res.data[0].logo,
          },
          {
            heading: `${res.data[1].scheme_name}`,
            subheading: res.data[1].subcategory,
            percent: res.data[1].return1y,
            logo: res.data[1].logo,
          },
        ];
        setSimilarFundData(similarfund);
      })
      .catch((error) => {
        // console.log("get similar fund error", error);
      });

    // ----------------about----fund---amc----api-------

    axios
      .get(config.aboutFundAmc + params.isin, { headers: headers })
      .then((res) => {
        // console.log("get fund amc", res);
        setAboutAmc(res.data.aboutamc);
      })
      .catch((error) => {
        // console.log("get fund amcerror", error);
      });

    // ------------fund---graph----api-----
    axios
      .get(config.fundGraph + params.isin, { headers: headers })
      .then((res) => {
        let graphnav = [];
        let graphdate = [];
        // console.log("get fund grsaph data", res);
        res.data.map(
          (data) => (graphnav.push(data.nav), graphdate.push(data.date))
        );
        setFundGraphSliceDate(graphdate);
        setFundGraphSliceNav(graphnav);
        setFundGraphNav(graphnav);
        setFundGraphDate(graphdate);
      })
      .catch((error) => {
        // console.log("get fund graph error", error);
      });

   
  }, []);

  const showGraphData = (d, index) => {

    setActiveIndex(index);
    if (d.btn === "1M") {

      setFundGraphSliceDate(
        fundGraphDate.length>20?
        fundGraphDate.slice(fundGraphDate.length - 20, fundGraphDate.length):fundGraphDate
      );
      setFundGraphSliceNav(
        fundGraphNav.length>20?
        fundGraphNav.slice(fundGraphNav.length - 20, fundGraphNav.length):fundGraphNav
      );
    } else if (d.btn === "3M") {
   
      setFundGraphSliceDate(
        fundGraphDate.length>60?
  
        fundGraphDate.slice(fundGraphDate.length - 60, fundGraphDate.length):fundGraphDate
      );
      setFundGraphSliceNav(
        fundGraphNav.length>60?
               
        fundGraphNav.slice(fundGraphNav.length - 60, fundGraphNav.length):fundGraphNav
      );
    } else if (d.btn === "6M") {
  

      setFundGraphSliceDate(
        fundGraphDate.length>120?
        fundGraphDate.slice(fundGraphDate.length - 120, fundGraphDate.length):fundGraphDate
      );
      setFundGraphSliceNav(
        fundGraphNav.length>120?
        fundGraphNav.slice(fundGraphNav.length - 120, fundGraphNav.length):fundGraphNav
      );
    } else if (d.btn === "1Y") {
  

      setFundGraphSliceDate(
        fundGraphDate.length >240?
     
        fundGraphDate.slice(fundGraphDate.length - 240, fundGraphDate.length):fundGraphDate
      );
      setFundGraphSliceNav(
        fundGraphNav.length >240?
      
        fundGraphNav.slice(fundGraphNav.length - 240, fundGraphNav.length):fundGraphNav
      );
    } 
    else if (d.btn === "3Y") {
   

      setFundGraphSliceDate(
  
        fundGraphDate.length > 720? fundGraphDate.slice(fundGraphDate.length -720, fundGraphDate.length):fundGraphDate
        
      );
      setFundGraphSliceNav(
        fundGraphNav.length>720?
        fundGraphNav.slice(fundGraphNav.length - 720, fundGraphNav.length):fundGraphNav
      );
    } 
    else if (d.btn === "5Y") {
      setFundGraphSliceDate(
        fundGraphDate.length > 1200?
        fundGraphDate.slice(fundGraphDate.length - 400, fundGraphDate.length):fundGraphDate
      );
      setFundGraphSliceNav(
        fundGraphNav.length > 1200?
        fundGraphNav.slice(fundGraphNav.length - 400, fundGraphNav.length):fundGraphNav
      );
    } 
    else if (d.btn === "All time") {
      setFundGraphSliceDate(fundGraphDate);
      setFundGraphSliceNav(fundGraphNav);
    }
  };

  const handelback = () => {
    // navigate("/fundlist");
  };





  return (
    <div className="fund-details-main-container">
      <Helmet>
        <title>Fund Details</title>
        <link rel="icon" href="faveicon.ico" />
      </Helmet>
    
      <div className="fund-logo">
        <img src={fundDetail.logo} style={{ width: "4.72vw" }} alt="" />
      </div>
      <div className="fund-name-type-cont">
        <div style={{ display: "flex", gap: "1.59vw" }}>
          <h1 className="fund-name">{fundDetail.scheme_name}</h1>
        </div>
        <div style={{ display: "flex", gap: "1vw", marginTop: "0.55vw" }}>
          <h6 className="fund-type">{fundDetail.category}</h6>
          <h6 className="fund-type">|</h6>
          <h6 className="fund-type">{fundDetail.broadcategorygroup}</h6>
        </div>
        <div className="fund-return-cont">
          <h1 className="yearly-return-per">
            {(fundDetail.return5y*100).toFixed(2)}% <span>(5Y absolute returns)</span>
          </h1>
          <span>|</span>
          <h1 className="one-day-return">
            {fundDetail.return1d}% <span>(1D returns)</span>
          </h1>
        </div>
      </div>
      <div className="about-fund-cont">
        <p className="about-fund-para">{aboutAmc}</p>
      </div>
      <div className="fund-market-detail-cont">
        <FundMarketDetail fundData={minSipLump} />
      </div>
      <div className="fund-graph-con">
        <PublicFundShareGraph
          fundGraphDate={fundGraphSliceDate}
          fundGraphNav={fundGraphSliceNav}
        />
        <div className="graph-btn-cont">
          {chartTimerButton.map((d, index) => {
            return (
              <div className="graph-btn">
                <div
                  onClick={() => showGraphData(d, index)}
                  className={`${
                    activeIndex === index
                      ? "graph-btn-active"
                      : "graph-btn-inactive"
                  }`}
                >
                  {d.btn}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* --------year wise performance------------ */}
      <div className="fund-performance-cont">
        <h1>Year wise performance</h1>
        <div className="fund-market-detail-cont">
          <FundMarketDetail fundData={fundYearlyPerc} />
        </div>
      </div>
      <div className="fund-pros-cons-cont">
        <h1>Pros</h1>
        <div style={{ marginTop: "1.66vw" }}>
          {fundPros.length > 0 ? fundPros.map((data) => <p>{data}</p>) : ""}
        </div>
      </div>
      <div className="fund-pros-cons-cont">
        <h1>Cons</h1>
        <div style={{ marginTop: "1.66vw" }}>
          {fundCons.length > 0 ? fundCons.map((data) => <p>{data}</p>) : ""}
        </div>
      </div>
      <div className="calculator-cont">
        <h1>Calculator</h1>
        <div className="wealth_cal-in-public-share">
        <WealthCalculator fundCategory={fundDetail.category} annualizedreturn5y={fundDetail.annualized_return_5y*100}/>
        </div>
       
      </div>
      {/* <div className="similar-fund-cont">
        <h1>Similar funds</h1>
        <div style={{ marginTop: "2.56vw" }}>
          <Recommendation relatedFundData={similarFundData} />
        </div>
      </div> */}
      {/* <div className="current-investment-cont">
        <h1>Current investment</h1>
        <div className="current-investment-data">
          <FundMarketDetail fundData={currentInvesment} />
        </div>
      </div> */}
      {/* <div className="client-list-cont">
        <h1>List of clients</h1>
        <div className="fundlist-table-container">
          <table className="table-style-one">
            <tr>
              <th>Name</th>
              <th>Returns till date</th>
              <th>Total AUM</th>
            </tr>
            {listOfCLient.map((data) => {
              return (
                <tr onClick={()=>redirectToCLientDetailPage(data)}>
                  <td>{data.full_name}</td>
                  <td>40%</td>
                  <td>{data.total_aum}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div> */}
    </div>
  );
}
