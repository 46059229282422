import React from "react";
import { useState, useEffect } from "react";
// import {  useNavigate } from "react-router-dom";
import "./CreatePassword.css";
import PersonalDetail from "./PersonalDetail";
import validator from "validator";
import axios from "axios";
import config from "../../config";
import { message } from "antd";
import { crypt } from "../TockenConversion/JwtConvert";
export default function CreatePassword(props) {
// const navigate =  useNavigate()
  const [valid, setValid] = useState(false);
  // console.log(valid);

const showErrorEmailExist =()=>{
  message.error({
    content: "This email already exists",
    className: "custom-class",
    style: {
      marginTop: "5vh",
      marginLeft: "77vw",
    },
  });
}

const handelEnterKey=(event)=>{
  if(event.keyCode === 13){
    if( password.length >= 8 &&
      reEnterPass.length >= 8 && 
      showErrorMessage === false && email){
      // console.log("key press")
      handleContinue()
    }

  }
}
  const handleContinue = () => {

    
    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
    }
    axios.post(
      config.email,
      { agent_email_id:email,
        password:password,
        contact_number:props.phoneNumber,
     
      },
      {headers:headers}

    ).then((res)=>{
      if(res.data.message){
        setValid(true)
      }
      const encrypted_access_tocken = crypt("access_token", res.data.access);
      const encrypted_refresh_tocken = crypt("refresh_token", res.data.refresh);
      localStorage.setItem("access_token", encrypted_access_tocken);
      localStorage.setItem("refresh_token",  encrypted_refresh_tocken);
    }).catch((error)=>{
      if(error.response.data.non_field_errors){
        showErrorEmailExist()
      }
    })

    // setValid(true);
  };
  // const handelSkip = () => {
  //   setValid(true);
  // };
  const [password, setParole] = useState("");
  const [reEnterPass, setReEnPss] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState('')
  useEffect(() => {
    if (isCPasswordDirty) {
      if (password === reEnterPass) {
        setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    }
  }, [reEnterPass, isCPasswordDirty, password]);

  const handleCPassword = (e) => {
    setReEnPss(e.target.value);
    setIsCPasswordDirty(true);
  };
  const validateEmail = (e) => {
    const email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError(null);
      // console.log("singUp EMail :", email)
      setEmail(email)
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {valid ? (
        <PersonalDetail  setLoged={props.setLoged}/>
      ) : (
        <>
          <img className="logo-img" src="/assets/Logo.png" alt="Loding...." />
          <h1 className="heading-set-pss">Set up your password</h1>
          <div
            style={{
              display:"flex",
              flexDirection: "column",
              justifyContent: "center",
              rowGap:"0.55vw",
              // width: "342px",
              // height: "72px",
              // marginLeft: "5vw",
              marginTop: "0.55vw",
            }}
          >
            <input
              // value={password}\
              className="password-input"
              type="gmail"
              placeholder="Email"
              onChange={validateEmail}
            />
            {  emailError ? <p className="email-error">Email is not Valid!</p>:""}
            <input
              value={password}
              className="password-input"
              type="password"
              placeholder="Create password"
              onChange={(e) => {
                setParole(e.target.value);
              }}
            />
           
            <input
              value={reEnterPass}
              className="password-input"
              type="password"
              placeholder="Re-type password"
              onChange={handleCPassword}
              onKeyDown={handelEnterKey}
            />
          </div>
          <div>
          <p className="suggest-para">
              Your password should have minimum 8 digits
            </p>
  
            {showErrorMessage && isCPasswordDirty ? (
              <span className="password-error">
                {" "}
                Passwords did not match{" "}
              </span>
            ) : (
              ""
            )}
          </div>
          <div>
            
              <button
                disabled={
                  showErrorMessage === false &&
                  password.length >= 8 && 
                  reEnterPass.length >= 8 && email
                    ? false
                    : true
                }
                className={`continue-btn ${
                  password.length >= 8 &&
                  reEnterPass.length >= 8 && 
                  showErrorMessage === false && email
                    ? "active-continue-btn"
                    : ""
                }`}
                onClick={handleContinue}
              >
                Continue
              </button>
     
            {/* <p className="do-later" onClick={handelSkip}>
              I’ll do later
              </p>*/}
           </div> 
        </>
      )}
    </div>
  );
}
