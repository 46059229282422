import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import Pagination from "../ClientnDetails/Pagination";
// import Checkbox from "../ClientsDataTAble/Checkbox";
import CheckBoxFilter from "../Filter/CheckBoxFilter";
// import CkeckBoxFilter from "../Filter/CheckBoxFilter";
// import Filters from "../Filter/Filters";
import { CSVLink } from "react-csv";
import { ReactComponent as CsvDownload } from "../../../Icons/csvColoredDownload.svg";
import { ReactComponent as Download } from "../../../Icons/downloadColored.svg";
import { ReactComponent as UpArrow } from "../../../Icons/upArrow.svg";
import { ReactComponent as DownArrow } from "../../../Icons/downArrow.svg";
import { ReactComponent as UnBookMark } from "../../../Icons/unBookMark.svg";
import { ReactComponent as BookMark } from "../../../Icons/bookMark.svg";
import { ReactComponent as Share } from "../../../Icons/shareUnColored.svg";
import { ReactComponent as FilterIcon } from "../../../Icons/filter-icon.svg";
import { ReactComponent as EmptySreen } from "../../../Icons/emptyscreen.svg";
import "./FundListing.css";
import { Tooltip, Button, Modal, Spin } from "antd";
import FundDetailShareModal from "./FundDetailShareModal";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function FundListing(props) {
  const navigate = useNavigate();
  // const val = props.filterSelectedValue;
  const [loder, setLoder] = useState(true);
  const [fundlistData, setFundListData] = useState([]);
  const [fundListTableData, setFundListTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fundSortingData, setFundSortingData] = useState([]);
  const [selections, setSelections] = useState([]);
  const [allSelect, setAllSelect] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amcName, setAmcName] = useState([]);
  const [fundType, setFundType] = useState([]);
  const [fundCategory, setFundCategory] = useState([]);
  const [returnRange, setReturnRaneg] = useState([]);
  const [activate, setActivate] = useState(false);
  const [order, setOrder] = useState("DSC");
  const [columnName, setColumnName] = useState("");
  // const [fundBookMark, setFundBookMark] = useState({});
  const [isinForModal, setIsInNForModal] = useState("");
  const [fundNameForModal, setFundNameForModal] = useState("");
  const [byFundType, setByFundType] = useState([]);
  const [senderData, setSenderData] = useState([]);
  // console.log("VALUE FROM PROPS", selections);
  const [isFilterInMobile, setIsFilterInMobile] = useState(false);

  const itemPerPage = 10;
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;

  const byAmcName = [
    "Aditya Birla Sun Life AMC Limited",
    "DSP Investment Managers Private Limited",
    "HDFC Asset Management Company Limited (Corporate Identification Number - L65991MH1999PLC123027)",
    "Kotak Mahindra Asset Management Company Limited(KMAMCL)",
    "ICICI Prudential Asset Management Company Limited",
    "Nippon Life India Asset Management Limited",
    "Taurus Asset Management Company Limited",
    "Franklin Templeton Asset Management (India) Private Limited",
    "Canara Robeco Asset Management Company Limited",
    "Sundaram Asset Management Company Limited",
    "SBI Funds Management Private Limited",
    "UTI Asset Management Company Ltd",
    "Tata Asset Management Limited",
    "Quantum Asset Management Company Private Limited",
    "Invesco Asset Management (India) Private Limited",
    "Mirae Asset Investment Managers (India) Pvt. Ltd.",
    "IDFC Asset Management Company Limited",
    "Edelweiss Asset Management Limited",
    "Axis Asset Management Company Ltd.",
    "Navi AMC Limited",
    "IDBI Asset Management Limited",
    "Motilal Oswal Asset Management Company Limited",
    "BNP Paribas Asset Management India Private Limited",
    "Union Asset Management Company Private Limited (formerly Union KBC Asset Management Co. Pvt. Ltd)",
    "PPFAS Asset Management Pvt. Ltd.",
    "IIFL Asset Management Ltd. (Formerly known as India Infoline Asset Management Co. Ltd.)",
    "Mahindra Manulife Investment Management Pvt Ltd (Formerly Mahindra Asset Management Company Pvt Ltd)",
    "ITI Asset Management Limited",
    "WhiteOak Capital Asset Management Limited",
  ];

  const fundTypeDebt = [
    "Medium to Long Duration Fund",
    "Liquid Fund",
    "Gilt Fund",
    "Medium Duration Fund",
    "Money Market Fund",
    "Long Duration Fund",
    "Corporate Bond Fund",
    "Overnight Fund",
    "Floater Fund",
    "Ultra Short Duration Fund",
    "Dynamic Bond",
    "Short Duration Fund",
    "Low Duration Fund",
    "Credit Risk Fund",
    "Banking and PSU Fund",
    "Gilt Fund with 10 year constant duration",
  ];
  const fundTypeEquity = [
    "Large & Mid Cap Fund",
    "Sectoral/ Thematic",
    "ELSS",
    "Flexi Cap Fund",
    "Large Cap Fund",
    "Mid Cap Fund",
    "Value Fund",
    "Multi Cap Fund",
    "Focused Fund",
    "Dividend Yield Fund",
    "Small Cap Fund",
    "Contra Fund",
  ];
  const fundTypeHybrid = [
    "Aggressive Hybrid Fund",
    "Dynamic Asset Allocation or Balanced Advantage",
    "Conservative Hybrid Fund",
    "Balanced Hybrid Fund",
    "Multi Asset Allocation",
    "Equity Savings",
    "Arbitrage Fund",
  ];
  const fundTypeOthers = [
    "Index Funds",
    "FoF Domestic",
    "FoF Overseas",
    "Gold ETF",
    "Other  ETFs",
  ];
  const fundTypeSolutionOriented = ["Children s Fund", "Retirement Fund"];
  const allFundType = [
    "Medium to Long Duration Fund",
    "Large & Mid Cap Fund",
    "Liquid Fund",
    "Gilt Fund",
    "Sectoral/ Thematic",
    "ELSS",
    "Medium Duration Fund",
    "Flexi Cap Fund",
    "Aggressive Hybrid Fund",
    "Money Market Fund",
    "Dynamic Asset Allocation or Balanced Advantage",
    "Index Funds",
    "Small Cap Fund",
    "Large Cap Fund",
    "Value Fund",
    "Long Duration Fund",
    "Mid Cap Fund",
    "Dynamic Bond",
    "Low Duration Fund",
    "Corporate Bond Fund",
    "Retirement Fund",
    "Conservative Hybrid Fund",
    "Balanced Hybrid Fund",
    "Children s Fund",
    "Overnight Fund",
    "Multi Cap Fund",
    "Multi Asset Allocation",
    "Floater Fund",
    "Short Duration Fund",
    "Ultra Short Duration Fund",
    "Focused Fund",
    "Equity Savings",
    "Credit Risk Fund",
    "Other  ETFs",
    "Dividend Yield Fund",
    "FoF Domestic",
    "FoF Overseas",
    "Contra Fund",
    "Banking and PSU Fund",
    "Arbitrage Fund",
    "Gold ETF",
    "Gilt Fund with 10 year constant duration",
  ];

  const byFundCategory = [
    "Equity",
    "Debt",
    "Hybrid",
    "Other Balanced Solution",
    "Oriented Gilt Growth",
    "Income",
    "Other",
    "Solution Oriented",
    "Liquid Floating Rate",
    "Assured Return",
    "Money Market",
    "Fund of Funds",
  ];
  const byBookMark = [
    "ICICI",
    "Kotak",
    "IDBI",
    "CA/CS",
    "Professional",
    "Retired",
    "Married",
    "Single",
    "CA/CS",
  ];
  const byReturnRanges = [
    "0% to 10%",
    "10% to 20%",
    "20% to 35%",
    "35% and above",
  ];

  let currentItems;
  if (fundListTableData.length > 0) {
    currentItems = fundListTableData.slice(indexOfFirstItem, indexOfLastItem);
  } else {
    currentItems = fundListTableData;
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const decrypted_access_token = decrypt(
    "access_token",
    localStorage.getItem("access_token")
  );

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };

  useEffect(() => {
    axios
      .get(config.fundlisting, { headers: headers })
      .then((res) => {
        // console.log("get fundlist", res);
        setFundListData(res.data.data);
        setFundListTableData(res.data.data);
        setLoder(false);
      })
      .catch((error) => {
        // console.log("get fundlist failed", error);
      });
  }, []);

  let tempArray = selections;

  // This useEffect use for value selection
  useEffect(() => {
    // const fllterdata = fundlistData.filter((data) => {
    if (selections.length > 0) {
      setActivate(false);
    }
    let tempAmcNameArr = [];
    let tempFundCategoryArr = [];
    let tempFundTypeArr = [];
    let tempReturnRangeArr = [];
    // console.log("TEMP ARRAY", tempArray);

    for (let i = 0; i < tempArray.length; i++) {
      for (let j = 0; j < byAmcName.length; j++) {
        if (tempArray[i] === byAmcName[j]) {
          tempAmcNameArr.push(tempArray[i]);
        }
      }
      for (let l = 0; l < byFundCategory.length; l++) {
        if (tempArray[i] === byFundCategory[l]) {
          tempFundCategoryArr.push(tempArray[i]);
        }
      }
      for (let k = 0; k < byFundType.length; k++) {
        if (tempArray[i] === byFundType[k]) tempFundTypeArr.push(tempArray[i]);
      }

      for (let m = 0; m < byReturnRanges.length; m++) {
        if (tempArray[i] === byReturnRanges[m]) {
          tempReturnRangeArr.push(tempArray[i]);
        }
      }
    }
    setAmcName(tempAmcNameArr);
    setFundType(tempFundTypeArr);
    setFundCategory(tempFundCategoryArr);
    setReturnRaneg(tempReturnRangeArr);
    // console.log("VALUE FROM COTEGORY", tempFundCategoryArr);
    // console.log("AFTER ADDINF AMC NAME IN TEMP ARR",tempAmcNameArr);
    // console.log("AFTER ADING FUND TYPE VALUE", tempFundTypeArr);
    // console.log("AFTER ADING RETURN RANGE VALUE",tempReturnRangeArr);
    // });

    // setTabledata(fllterdata);
    // console.log(fllterdata);
    // if (selections.length === 0) {
    //   setTabledata(fundlistData);
    // }
  }, [selections]);

  // This useEffect use for fillter data
  useEffect(() => {
    let fundListMainData = fundlistData;

    if (amcName.length > 0) {
      const amcNameFilter = fundListMainData.filter((data) => {
        for (let i = 0; i < amcName.length; i++) {
          if (amcName[i] === data.amc_name) {
            return data;
          }
        }
      });
      fundListMainData = amcNameFilter;
    }

    if (fundType.length > 0) {
      const fundTypeFilter = fundListMainData.filter((data) => {
        for (let i = 0; i < fundType.length; i++) {
          if (fundType[i] === data.subcategory) {
            // console.log("length of subcategory", data.length, data.subcategory);
            return data;
          }
        }
      });
      fundListMainData = fundTypeFilter;
    }

    if (fundCategory.length > 0) {
      const categoryFilter = fundListMainData.filter((data) => {
        for (let i = 0; i < fundCategory.length; i++) {
          if (fundCategory[i] === data.category) {
            return data;
          }
        }
      });
      fundListMainData = categoryFilter;
    }

    if (returnRange.length > 0) {
      const returnRangeFilter = fundListMainData.filter((data) => {
        for (let i = 0; i < returnRange.length; i++) {
          if (returnRange[i] === "0% to 10%") {
            if (data.return1y >= "0.0" && data.return1y <= "0.10") {
              // console.log("RETURN 1Y 0 to 10", data.return1y);
              return data;
            }
          }
          if (returnRange[i] === "10% to 20%") {
            if (data.return1y >= "0.10" && data.return1y <= "0.20") {
              // console.log("RETURN 1Y 10 to 20", data.return1y);
              return data;
            }
          }
          if (returnRange[i] === "20% to 35%") {
            if (data.return1y >= "0.20" && data.return1y <= "0.35") {
              // console.log("RETURN 1Y 20 to 35 ", data.return1y);
              return data;
            }
          }
          if (returnRange[i] === "35% and above") {
            if (data.return1y >= "0.35") {
              // console.log("RETURN 1Y above 35", data.return1y);
              return data;
            }
          }
        }
      });
      fundListMainData = returnRangeFilter;
    }
    if (activate === false) {
      setFundListTableData(fundListMainData);
    }
    // console.log("FINAL TABLE DATA AFTER FILTER", fundListTableData);
  }, [amcName, fundCategory, fundType, returnRange]);

  const updateFundlist = (data) => {
    // console.log("UDATING BOOKMARk",data);

    let temptable = fundListTableData;

    if (data.fund_bookmark_status == true) {
      data.fund_bookmark_status = false;
    } else {
      data.fund_bookmark_status = true;
    }

    setFundListTableData([...temptable, data]);
  };
  // This function used for bookmarks
  const bookMarkFund = (data) => {
    updateFundlist(data);

    // setIsnInN(data.isin)

    axios
      .post(config.fundBookMark, { isin: data.isin }, { headers: headers })
      .then((res) => {
        if (res.data) {
        }
      })
      .catch((error) => {
        // console.log("post bookmark error", error);
      });
  };
  //  console.log("post bookmark error", fundBookMark);
  const showDetails = (data) => {
    navigate(`/fundlist/funddetails/${data.isin}`);
  };
  //this function used for sorting
  const sorting = (col) => {
    setActivate(true);
    setColumnName(col);

    let fundMain =
      fundListTableData.length <= 0 ? fundlistData : fundListTableData;
    if (order === "ASC") {
      // console.log("ASCD");
      const sorted1 = [...fundMain].sort((a, b) => a[col] - b[col]);
      setOrder("DSC");
      fundMain = sorted1;
      // console.log("ASD SHORTING",sorted1);
      // console.log("First STEP !1");
    }

    if (order === "DSC") {
      // console.log("DSJASCD");
      const sorted2 = [...fundMain].sort((a, b) => b[col] - a[col]);
      setOrder("ASC");
      fundMain = sorted2;
      // console.log(" DSC SORTED",sorted2);
      // console.log("Second STEP !2");
    }

    //  console.log("AFTER SHORTING FINAL DATA",fundMain);

    setFundListTableData(fundMain);
    // console.log("AFTER FINAL DATA IN TABLE",fundListTableData);
    // console.log("CURRENT ITEM",currentItems);
  };
  // console.log("COLUMN NAME",columnName);

  // this is use for download table data is csv Format
  const csvHeaders = [
    { label: "Fund Name", key: "scheme_name" },
    // {label:"AMC Name",key:"amc_name" },
    // {label:"ISIN Number",key:"isin" },
    { label: "Type", key: "subcategory" },
    { label: "Category", key: "category" },
    { label: "Min. SIP", key: "min_sip" },
    { label: "Min. Lumpsum", key: "min_lumpsum" },
    { label: "1Y", key: "return1y" },
    { label: "3Y", key: "return3y" },
    { label: "5Y", key: "return5y" },
    { label: "Bookmark", key: "fund_bookmark_status" },
  ];

  const shareDetailFunction = (data) => {
    setIsModalOpen(true);
    setIsInNForModal(data.isin);
    setFundNameForModal(data.scheme_name);
    // console.log("DATA MODDAL", fundNameForModal);

    //  ---------get sender data---------
    axios
      .get(config.emailFunds, { headers: headers })
      .then((response) => {
        // console.log(response,"success get sender data");
        // console.log("full name", response.data[0].mobile);
        setSenderData(response.data);
      })
      .catch((error) => {
        // console.log("get sender data error");
      });
    // console.log("sender data",senderData)
  };

  //this useEffect use for rednder values on two checkbox filter fundtypes values depending on fund category values
  useEffect(() => {
    // let byFundTypeArray = []
    if (fundCategory.length > 0) {
      let byFundTypeArray = [];
      for (let i = 0; i < fundCategory.length; i++) {
        for (let j = 0; j < fundTypeDebt.length; j++) {
          if (fundCategory[i] === "Debt") {
            byFundTypeArray.push(fundTypeDebt[j]);
          }
        }
        // console.log("Value from fundCategory",byFundTypeArray[i])
        // setByFundType(byFundTypeArray)

        for (let k = 0; k < fundTypeEquity.length; k++) {
          if (fundCategory[i] === "Equity") {
            byFundTypeArray.push(fundTypeEquity[k]);
          }
        }

        for (let l = 0; l < fundTypeHybrid.length; l++) {
          if (fundCategory[i] === "Hybrid") {
            byFundTypeArray.push(fundTypeHybrid[l]);
          }
        }
        for (let m = 0; m < fundTypeOthers.length; m++) {
          if (fundCategory[i] === "Other") {
            byFundTypeArray.push(fundTypeOthers[m]);
          }
        }
        for (let n = 0; n < fundTypeSolutionOriented.length; n++) {
          if (fundCategory[i] === "Solution Oriented") {
            byFundTypeArray.push(fundTypeSolutionOriented[n]);
          }
        }
        setByFundType(byFundTypeArray);
      }
    } else {
      setByFundType(allFundType);
    }
  }, [fundCategory]);

  const clearALlFilter = () => {
    setSelections([]);
    setAllSelect = [];
  };
  const showFiltersInMobile = () => {
    setIsFilterInMobile(!isFilterInMobile);
  };
  return (
    <div className="funding-list-container">
      <Helmet>
        <title>FundList</title>
        <link rel="icon" href="faveicon.ico" />
      </Helmet>
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "baseline",
          gap: "0.3vw",
          marginTop: "-1.32vw",
          marginBottom: "1.22vw",
        }}
      >
        <p className="clients-text" onClick={() => navigate("/dashboard")}>
          Dashboard{" "}
        </p>
        <p className="client-name disable-hover">/ {"Allfunds"}</p>
      </div>
      <div className="filter-text-icon-cont">
        <FilterIcon onClick={showFiltersInMobile} />
        <p className="filter-heading">Filters</p>
        <p className="clear-all-filter-text" onClick={clearALlFilter}>
          Clear all filters
        </p>
      </div>

      <div
        className={
          isFilterInMobile
            ? "checkbox-filter-container-in-mob"
            : "checkbox-filter-container"
        }

        // style={{
        //   display: "flex",
        //   gap: "1.66vw",
        //   flexWrap: "wrap",
        //   width: "75vw",
        //   rowGap: "2.24vw",
        // }}
      >
        <CheckBoxFilter
          filterValue={byAmcName}
          filterName={"Select AMC"}
          searchPlaceholder={"Search by AMC"}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={amcName}
          setActivate={setActivate}
        />
        <CheckBoxFilter
          filterValue={byFundCategory}
          filterName={"Select Fund Category"}
          searchPlaceholder={"Search by category"}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={fundCategory}
        />
        <CheckBoxFilter
          filterValue={byFundType}
          filterName={"Select Fund Type"}
          searchPlaceholder={"Search by fund type"}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={fundType}
        />

        <CheckBoxFilter
          filterValue={byReturnRanges}
          filterName={"Select Return Ranges"}
          searchPlaceholder={"Search by return ranges"}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={returnRange}
        />
        {/* <CheckBoxFilter
          filterValue={byBookMark}
          filterName={"By Bookmark"}
          selections={selections}
          setSelections={setSelections}
        /> */}
      </div>
      <div
        className="csvfile-download-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3.33vw",
        }}
      >
        <p className="all-fund-text">All funds</p>
        <div className="download-txt-png-cont">
          <h6>Download all</h6>

          <CSVLink
            headers={csvHeaders}
            filename={"all-funds-list.csv"}
            data={fundListTableData}
          >
            <CsvDownload />
          </CSVLink>
          {/* <Download /> */}
        </div>
      </div>
      <div className="fundlist-table-container">
        {currentItems.length > 0 ? (
          <div className="table-style-one">
          <table >
            <tr>
              <th>AMC</th>
              <th>Fund name</th>

              <th>Category</th>
              <th>Type</th>
              {/* <th
              style={{  }}
              onClick={() => sorting("Commision")}
            >
              <div style={{ display: "flex", gap: "0.45vw",cursor: "pointer" }}>
                {order === "DSC" && columnName==="Commision" ? <UpArrow /> : <DownArrow />}
                Commision
              </div>
            </th> */}
              <th
                onClick={() => sorting("min_sip")}
                // style={{ cursor: "pointer" }}
              >
                <div
                  className="shorting-arrow-container"
                  // style={{ display: "flex", gap: "0.45vw", cursor: "pointer" }}
                >
                  {order === "DSC" && columnName === "min_sip" ? (
                    <UpArrow />
                  ) : (
                    <DownArrow />
                  )}
                  Min. SIP
                </div>
              </th>
              <th
                onClick={() => sorting("min_lumpsum")}
                // style={{ cursor: "pointer" }}
              >
                <div
                  // style={{ display: "flex", gap: "0.45vw", cursor: "pointer" }}
                  className="shorting-arrow-container"
                >
                  {order === "DSC" && columnName === "min_lumpsum" ? (
                    <UpArrow />
                  ) : (
                    <DownArrow />
                  )}
                  Min. Lumpsum
                </div>
              </th>

              <th
                // style={{ cursor: "pointer" }}
                onClick={() => sorting("return1y")}
              >
                <div
                  // style={{ display: "flex", gap: "0.45vw", cursor: "pointer" }}
                  className="shorting-arrow-container"
                >
                  {order === "DSC" && columnName === "return1y" ? (
                    <UpArrow />
                  ) : (
                    <DownArrow />
                  )}{" "}
                  1Y
                </div>
              </th>

              <th
                // style={{ cursor: "pointer" }}
                onClick={() => sorting("annualized_return_3y")}
              >
                <div
                  // style={{ display: "flex", gap: "0.45vw", cursor: "pointer" }}
                  className="shorting-arrow-container"
                >
                  {order === "DSC" && columnName === "annualized_return_3y" ? (
                    <UpArrow />
                  ) : (
                    <DownArrow />
                  )}{" "}
                  3Y
                </div>
              </th>
              <th
                // style={{ cursor: "pointer" }}
                onClick={() => sorting("annualized_return_5y")}
              >
                <div
                  // style={{ display: "flex", gap: "0.45vw", cursor: "pointer" }}
                  className="shorting-arrow-container"
                >
                  {order === "DSC" && columnName === "annualized_return_5y" ? (
                    <UpArrow />
                  ) : (
                    <DownArrow />
                  )}{" "}
                  5Y
                </div>
              </th>
              <th></th>
            </tr>
            {currentItems.map((data) => {
              return (
                <tr>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={(e) => showDetails(data)}
                  >
                    <img
                      className="amc-logo"
                      // style={{ width: "2.27vw", height: "2.27vw" }}
                      src={data.logo}
                      alt=""
                    />
                  </td>
                  <Tooltip placement="right" title={data.scheme_name}>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        fontWeight: "400",
                      }}
                      onClick={(e) => showDetails(data)}
                    >
                      {" "}
                      {data.scheme_name.slice(0, 20)}...
                    </td>
                  </Tooltip>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={(e) => showDetails(data)}
                  >
                    {data.category}
                  </td>

                  <Tooltip placement="right" title={data.subcategory}>
                    <td
                      style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                      onClick={(e) => showDetails(data)}
                    >
                      {data.subcategory.slice(0, 10)}...
                    </td>
                  </Tooltip>
                  {/* <td
                  style={{ textAlign: "center" ,cursor:"pointer"}}
                  onClick={(e) => showDetails(data)}
                >
                  {"5.0"}
                </td> */}
                  <td
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={(e) => showDetails(data)}
                  >
                    {data.min_sip}
                  </td>
                  <td
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={(e) => showDetails(data)}
                  >
                    {data.min_lumpsum}
                  </td>

                  <td
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={(e) => showDetails(data)}
                  >
                    {(data.return1y * 100).toFixed(2)}%
                  </td>
                  <td
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={(e) => showDetails(data)}
                  >
                    {(data.annualized_return_3y * 100).toFixed(2)}%
                  </td>
                  <td
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={(e) => showDetails(data)}
                  >
                    {(data.annualized_return_5y * 100).toFixed(2)}%
                  </td>
                  <td>
                    <div
                      className="share-bookmark-container"
                      // style={{
                      //   display: "flex",
                      //   gap: "1.5vw",
                      //   paddingRight: "0.8vw",
                      //   justifyContent: "space-evenly",
                      //   cursor: "pointer",
                      // }}
                    >
                      {data.fund_bookmark_status === true ? (
                        <BookMark
                          onClick={() => bookMarkFund(data)}
                          width="1.086vw"
                          height="1.457vw"
                        />
                      ) : (
                        <UnBookMark
                          onClick={() => bookMarkFund(data)}
                          width="1.086vw"
                          height="1.457vw"
                        />
                      )}
                      <Share
                        onClick={() => shareDetailFunction(data)}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
          </div>
        ) : (
          <div
            className={
              loder === true
                ? "client-table-loder-screen"
                : "client-table-empty-screen"
            }
          >
            {loder == true ? (
              <Spin size="large" />
            ) : (
              <>
                <EmptySreen />
                <h1>No transactions executed</h1>
                <p>
                  Start investing for your clients and view their transaction
                  summary here.
                </p>
              </>
            )}
          </div>
        )}
        {currentItems.length > 0 ? (
          <Pagination
            itemPerPage={itemPerPage}
            totalUsers={fundListTableData.length}
            paginate={paginate}
            currentPage={currentPage}
            handleNextPage={() =>
              setCurrentPage(
                currentPage <= fundListTableData.length
                  ? currentPage + 1
                  : currentPage
              )
            }
            handlePrevPage={() => setCurrentPage(currentPage - 1)}
          />
        ) : (
          ""
        )}
      </div>
      <div className="pagination-in-mob">
        <Pagination
          itemPerPage={itemPerPage}
          totalUsers={fundListTableData.length}
          paginate={paginate}
          currentPage={currentPage}
          handleNextPage={() =>
            setCurrentPage(
              currentPage <= fundListTableData.length
                ? currentPage + 1
                : currentPage
            )
          }
          handlePrevPage={() => setCurrentPage(currentPage - 1)}
        />
      </div>
      <FundDetailShareModal
        setIsModalOpen={setIsModalOpen}
        isinForModal={isinForModal}
        fundNameForModal={fundNameForModal}
        isModalOpen={isModalOpen}
        senderData={senderData}
      />
    </div>
  );
}
