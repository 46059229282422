import React, { useState } from 'react'
import "./SendOtp.css"
// import "./login.css"
// import { ReactNotifications } from 'react-notifications-component'
// import 'react-notifications-component/dist/theme.css'
// import "antd/dist/antd.css";111
import {notification } from "antd";
import axios from 'axios';
import config from '../../config';
export default function SendOtp(props) {
 
 const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "OTP sent Successfully",
    duration:2,
    closeIcon:<></>
  });
};
const resendOtp=()=>{
  props.setUserOtp("")

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
  }
  axios.post(
    config.resendOtp,
    {contact_number:props.mobileNumber,step:"Signin"},
    {headers:headers}
  ).then((res)=>{
    if(res.data){
      openNotificationWithIcon("success")
    }
    //  console.log("login resnd otp",res)
  }).catch((error)=>{
    // console.log("login otpresend error",error)
  })



}
const formatAndSetCcNumber = e => {
  const inputVal = e.target.value.replace(/ /g, "");
  let inputNumbersOnly = inputVal.replace(/\D/g, "");

  if (inputNumbersOnly.length > 1) {
    inputNumbersOnly = inputNumbersOnly.substr(0, 6);
  }

  const splits = inputNumbersOnly.match(/.{1,3}/g);

  let spacedNumber = "";
  if (splits) {
    spacedNumber = splits.join(" ");
  }

  props.setUserOtp(spacedNumber);
};


  return (
    <div style={{width:"45.83vw"}}>
        <div className="login-input-container">
                  <span  className="input-pre">+91-
                  <input
                    className="number-props-input"
                    type="number"
                   value={props.mobileNumber}
                    placeholder="Mobile no"
                    onKeyDown={props.handelEnterKey}
                  /></span>
                  <br />
                  {/* <input
              
                    className="password-input"
                    type="password"
                    placeholder="Password"
                   
                  /> */}
                  <input
                
                    className="password-input"
                    type="text"
                    placeholder="OTP"
                    value={props.userOtp}
                    onChange={formatAndSetCcNumber}
                    onKeyDown={props.handelEnterKey}
                   
                  />
                </div>
                <div className='resend-text-cont'>
                  <p className='code-not-send-text'>Code not received?</p>
                  <p className='resend-text' onClick={resendOtp}>Resend</p>
                </div>
    </div>
  )
}
