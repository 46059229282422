import React, { useEffect } from "react";
import DataTable from "./ClientsDataTAble/DataTable";
import Filters from "./Filter/Filters";
import "./Clients.css";
import { Helmet } from "react-helmet";
// import 'antd/dist/antd.min.css'
import { useState } from "react";
import {
  Link,
  useLocation,
  useLastLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import CheckBoxfilter from "./Filter/CheckBoxFilter";
import CheckBoxFilter from "./Filter/CheckBoxFilter";
import { ReactComponent as FilterIcon } from "../../Icons/filter-icon.svg";
export default function Clients(props) {
  const [searchClientsName] = useOutletContext();
  const navigate = useNavigate();
  const [addnewClientsPage, setAddNewclientsPage] = useState(false);
  const [filterSelectedValue, setFilterValue] = useState("");
  const [selections, setSelections] = useState([]);
  const [allSelect, setAllSelect] = useState([]);
  const [firstFilterSelctedValue, setFistFilterSelctedValue] = useState([]);
  const [scndFilterSelctedValue, setScndFilterSelctedValue] = useState([]);
  const [thirdFilterSelctedValue, setThirdFilterSelctedValue] = useState([]);
  const [fourthFilterSelctedValue, setFourthFilterSelctedValue] = useState([]);
  const [isFilterInMobile, setIsFilterInMobile] = useState(false);

  const location = useLocation();
  // const locations=[]
  const showOnlyActiveLeads = location.state;

  const Selectclientstatus = [
    "KYC not started",
    "Not Signed Up",
    "Address pending",
    "Bank verification pending",
    "Nominee pending",
    "Signature pending",
    "Video verification pending",
    "No investments done",
    "Dormant client",
    "Active client",
    "KYC Pending",
    "KYC Rejected",
  ];
  const transactionPeriod = [
    "Last 1 month",
    "Last 3 months",
    "Last 6 months",
    "Last year",
    "All time",
  ];
  const AUMrange = [
    "< ₹5,000",
    "₹5,000 - ₹10,000",
    "₹10,000 - ₹50,000",
    "₹50,000 - ₹1,00,000",
    ">₹1,00,000",
  ];
  const clientTags = [
    "Software Engineer",
    "Doctor",
    "CA/Finance",
    "Businessman",
    "LIC Agent",
    "Married",
    "Single",
    "Senior Citizen",
    "First Time Investor",
    "Aggressive Investor",
  ];

  useEffect(() => {
    window.addEventListener("popstate", function () {
      if (window.location.pathname === "/user/clients") {
        setAddNewclientsPage(true);
      } else if (window.location.pathname === "/user/clients/addnewclients") {
        setAddNewclientsPage(false);
      }
    });
    // console.log("inside clients compo :", window.location.pathname);
  }, []);

  const addNewCLients = () => {
    setAddNewclientsPage(true);
  };
  // console.log("filter Value", filterSelectedValue);
  const clearALlFilter = () => {
    // selections.length = 0;
    setSelections([]);
    setAllSelect([]);
    // console.log("clicked")
  };
  const showFiltersInMobile = () => {
    setIsFilterInMobile(!isFilterInMobile);
  };

  return (
    <div className="client-main-container">
      <Helmet>
        <title>Clients</title>
        <link rel="icon" href="faveicon.ico" />
      </Helmet>
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "baseline",
          gap: "0.3vw",
          marginLeft: "3.33vw",
          marginTop: "2.22vw",
        }}
      >
        <p className="clients-text" onClick={() => navigate("/dashboard")}>
          Dashboard{" "}
        </p>
        <p className="client-name disable-hover">/ {"Clients"}</p>
      </div>
      <div className="filter-text-cont">
        <FilterIcon onClick={showFiltersInMobile} />
        <p className="filter-heading">Filters</p>
        <p className="clear-all-filter-text" onClick={clearALlFilter}>
          Clear all filters
        </p>
      </div>
      {/* <p className="filter-text">Filters</p> */}
      <div
        className={
          isFilterInMobile
            ? "clients-filter-container-in-mob"
            : "clients-filter-container"
        }
      >
        <CheckBoxFilter
          filterName={"Select Client Status"}
          filterValue={Selectclientstatus}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={firstFilterSelctedValue}
        />
        <CheckBoxFilter
          filterName={"Select Transaction Period"}
          filterValue={transactionPeriod}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={scndFilterSelctedValue}
        />
        <CheckBoxFilter
          filterName={"Select AUM Range"}
          filterValue={AUMrange}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={thirdFilterSelctedValue}
        />
        <CheckBoxFilter
          filterName={"Select Tags"}
          filterValue={clientTags}
          selections={selections}
          allSelect={allSelect}
          setAllSelect={setAllSelect}
          setSelections={setSelections}
          filterSelectedValue={fourthFilterSelctedValue}
        />
      </div>

      <div>
        <div className="client-text-add-btn-container">
          <p className="cients-text">Clients</p>
          <Link to="/clients/addnewclients">
            <button className="add-new-clients-btn" onClick={addNewCLients}>
              + Add new client
            </button>
            <button
              className="add-new-clients-btn-mobile"
              onClick={addNewCLients}
            >
              +
            </button>
          </Link>
        </div>

        <div className="use-for-mobile">
          <DataTable
            Selectclientstatus={Selectclientstatus}
            transactionPeriod={transactionPeriod}
            AUMrange={AUMrange}
            clientTags={clientTags}
            selections={selections}
            setSelections={setSelections}
            showOnlyActiveLeads={showOnlyActiveLeads}
            setFistFilterSelctedValue={setFistFilterSelctedValue}
            setScndFilterSelctedValue={setScndFilterSelctedValue}
            setThirdFilterSelctedValue={setThirdFilterSelctedValue}
            setFourthFilterSelctedValue={setFourthFilterSelctedValue}
          />
        </div>
      </div>
    </div>
  );
}
