import { Modal, notification ,Button} from "antd";
import axios from "axios";
import React from "react";
import { useState,useEffect } from "react";
import config from "../../../config";
import "./FundListing.css";
import CheckBoxFilter from "../Filter/CheckBoxFilter";
import { ReactComponent as Fb } from "../../../Icons/fb.svg";
import { ReactComponent as Linkedin } from "../../../Icons/linkedin.svg";
import { ReactComponent as Whatsapp } from "../../../Icons/whatsapp.svg";
import { ReactComponent as Twitter } from "../../../Icons/twitter.svg";
import { ReactComponent as Telegram } from "../../../Icons/telegram.svg";
import { ReactComponent as CopyICon } from "../../../Icons/copy-icon.svg";
import { decrypt } from "../../TockenConversion/JwtConvert";
import {
  // EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  // EmailShareButton,
} from "react-share";
export default function FundDetailShareModal(props) {
  const [selections, setSelections] = useState([]);
  const [allSelect,setAllSelect] = useState([])
  const [iconREsizer, setIconReSize] = useState(false);
  const[senderName,setSenderName] =  useState()
  const[sendType,setSendType] = useState([])
  const[sendersDetails,setSendersDetails] = useState([])
  const[selectedClientName,setSelectedClientName] = useState([])
  const [loading, setLoading] = useState(false);

  // const url = "mdgcfhjdgf";
  const copySuccessfull = (type) => {
    notification[type]({
      message: "Link copied",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const smsSendSuccess = (type) => {
    notification[type]({
      message: "SMS sent successfully",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const emailSendSuccess = (type) => {
    notification[type]({
      message: "Email sent successfully",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const handleCancel = () => {
    selections.length = 0;
    sendType.length=0
    selectedClientName.length=0
    props.setIsModalOpen(false);
  };
  // console.log("DATA INSIDE MODDAL", props.isinForModal);
  const viaSms = ["Email"];
  const copy = () => {
    setIconReSize(true)
    setTimeout(() => {
      const textField = document.createElement('textarea');
      textField.innerText = config.shareUrl + props.isinForModal;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      setIconReSize(false)
      textField.remove();
      copySuccessfull("success")
    }, 500);
  
  };

useEffect(() => {
  let tempArray = [];

    props.senderData.map((data) => {
      tempArray.push(data.full_name);
      // console.log(data.full_name,"selecions in ")
    })
    setSenderName(tempArray)
  // }


}, [props.senderData])

// ----------------useEffect for filter selected value----------------------------
let tempArray = selections;
useEffect(() => {

let sendTypeTempArray = [];
let sendersDetailsTempArray = [];
let clientNameTempArray = [];
 for (let i = 0; i < tempArray.length; i++) {
      for (let j = 0; j < viaSms.length; j++) {
         if(tempArray[i] === viaSms[j]){
          sendTypeTempArray.push(tempArray[i])
         }
      }
      for (let k = 0; k <  props.senderData.length; k++) {

        if(tempArray[i] === props.senderData[k].full_name){
          sendersDetailsTempArray.push(props.senderData[k])
          clientNameTempArray.push(props.senderData[k].full_name)
          // console.log("aaaya?????");
          //  console.log("senderData details",props.senderData[k]);
        }
        
      }

  
 }
 setSendType(sendTypeTempArray)
  setSendersDetails(sendersDetailsTempArray)
  setSelectedClientName(clientNameTempArray)
//  console.log("send detail",sendersDetailsTempArray);

},[selections])




const sendFundDetailFun=() => {
  // console.log("send type",sendType)
  setLoading(true);
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));
  
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };




    if(sendType == 'SMS'){
 console.log("If sms selected")

      axios.post(
        config.smsFunds,
        {client_object:sendersDetails,fund_page:config.shareUrl + props.isinForModal},
        {headers:headers}
      ).then((response) => {
        smsSendSuccess("success")
        selections.length = 0;
        sendType.length=0
        selectedClientName.length=0
        props.setIsModalOpen(false);
        setLoading(false);
        

      
        // console.log("success sms send",response)
      }).catch((error)=>{
        // console.log("error send sms",error)
      })
    }
    else if(sendType == 'Email'){
      console.log("If Email selected")

      axios.post(
        config.emailFunds,
        {client_object:sendersDetails,fund_page:config.shareUrl + props.isinForModal},
        {headers:headers}
      ).then((response) => {
        emailSendSuccess("success")
        selections.length = 0;
        sendType.length=0
        selectedClientName.length=0
        props.setIsModalOpen(false);
        setLoading(false);
      
        // console.log("success email send",response)
      }).catch((error)=>{
        // console.log("error send email",error)
      })
    }

    else if(sendType[0] == 'SMS' && sendType[1] == 'Email' ||sendType[1] == 'SMS' && sendType[0] == 'Email'){
      console.log("If both selected")

      axios.post(
        config.smsFunds,
        {client_object:sendersDetails,fund_page:config.shareUrl + props.isinForModal},
        {headers:headers}
      ).then((response) => {
        smsSendSuccess("success")
        selections.length = 0;
        sendType.length=0
        selectedClientName.length=0
        props.setIsModalOpen(false);
        setLoading(false);
        

      
        // console.log("success sms send",response)
      }).catch((error)=>{
        // console.log("error send sms",error)
      })


      axios.post(
        config.emailFunds,
        {client_object:sendersDetails,fund_page:config.shareUrl + props.isinForModal},
        {headers:headers}
      ).then((response) => {
        emailSendSuccess("success")
        selections.length = 0;
        sendType.length=0
        selectedClientName.length=0
        props.setIsModalOpen(false);
        setLoading(false);
      
        // console.log("success email send",response)
      }).catch((error)=>{
        // console.log("error send email",error)
      })
    }
    else{
      setLoading(false);
    }
  

}

// console.log("selections checked",selections)

  return (
    <div>
      <Modal
        className="fund-share-modal"
        title="Share"
        open={props.isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        // footer={[<Button className="copy-btn">Copy</Button>]}
      >
        <div style={{}}>
          <div className="fund-share-top">
            <div className="share-modal-checkbox-filter">
              <CheckBoxFilter
                filterName={"Send via"}
                filterValue={viaSms}
                setSelections={setSelections}
                allSelect={allSelect}
                setAllSelect={setAllSelect}

                selections={selections}
                filterSelectedValue={sendType}
              />
              <CheckBoxFilter
                filterName={"Send to"}
                filterValue={senderName}
                setSelections={setSelections}
                allSelect={allSelect}
                setAllSelect={setAllSelect}
                selections={selections}
                filterSelectedValue={selectedClientName}
              />
              <Button className="share-send-btn" loading={loading} onClick={sendFundDetailFun}>Send</Button>
            </div>
            <div className="share-social">
              <p>Broadcast to social network</p>
              <div className="icons-container">
                <FacebookShareButton
                  url={config.shareUrl + props.isinForModal}
                  title={props.fundNameForModal}
                >
                  <Fb />
                </FacebookShareButton>
                <TwitterShareButton
                  url={config.shareUrl + props.isinForModal}
                  title={props.fundNameForModal}
                >
                  <Twitter />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={config.shareUrl + props.isinForModal}
                  title={props.fundNameForModal}
                >
                  <Linkedin />
                </LinkedinShareButton>
                <WhatsappShareButton
                  url={config.shareUrl + props.isinForModal}
                  title={props.fundNameForModal}
                  separator=":: "
                >
                  <Whatsapp />
                </WhatsappShareButton>
                <TelegramShareButton
                  url={config.shareUrl + props.isinForModal}
                  title={props.fundNameForModal}
                >
                  <Telegram />
                </TelegramShareButton>
                {/* <EmailShareButton
                  url={config.shareUrl + props.isinForModal}
                  title={props.fundNameForModal}
                >
                  <Email />
                </EmailShareButton> */}
              </div>
            </div>
          </div>
          <div className="copy-url-container">
            <h6 className="copy-url-text">Copy URL</h6>
            <div className="input-copyIcon-cont">
              <input
                type="text"
                className="url-input"
                placeholder="https:www.kuants.in"
                  value={config.shareUrl + props.isinForModal}
              />
              <div className="copy-icon-cont" onClick={copy}> 
               <CopyICon  className={iconREsizer === true? "after-click-copy-icon":"copy-icon"}/>
              </div>
              {/* <img  src="/assets/Copy-icon.png" onClick={copy}/> */}
            </div>
            
          </div>
        </div>
      </Modal>
    </div>
  );
}
