// import React, { useState } from 'react'
// import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { memo } from "react";
import "./ActiveLeadsTable.css";
import config from "../../../config";
// import { Link, useNavigate } from "react-router-dom";
import { message, Spin, Tooltip } from "antd";
import { ReactComponent as SMS } from "../../../Icons/message.svg";
import { ReactComponent as Email } from "../../../Icons/email.svg";
import { ReactComponent as EmptySreen } from "../../../Icons/emptyscreen.svg";
import { decrypt } from "../../TockenConversion/JwtConvert";
function ActiveLeadsTable(props) {
  // const navigate = useNavigate();
  const [loder, setLoder] = useState(true);
  const [clientsData, setClientdata] = useState([]);

  const showDetail = (data) => {
    // props.setClientName(data.full_name);
    // navigate(`/dashboard/client/${data.user_id}`);
  };

  // console.log("inside activeleads", props.searchClientsName.toLowerCase());
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };

  useEffect(() => {
    axios
      .get(config.activeLeeds, { headers: headers })
      .then((res) => {
        // console.log("active leeds table", res);
        if (res.data.data) {
          setClientdata(res.data.data);
          setLoder(false);
        }
      })
      .catch((error) => {
        // console.log("active leeds  error", error);
      });
  }, []);
  // console.log("inside activeleads", props.searchClients)
  const sendSms = (data) => {
    axios
      .post(
        config.leadsSendSms,
        {
          client_id: data.client_id,
          client_name: data.full_name,
          client_status: data.client_status,
          mobile: data.mobile_number,
        },
        { headers: headers }
      )
      .then((res) => {
        // console.log("leeds sms scc ", res);
        message.success("SMS sent successfully");
      })
      .catch((error) => {
        // console.log("leads SMS   error", error);
      });
  };

  const sendMail = (data) => {
    // console.log("DONME");
    axios
      .post(
        config.leedsSendEmail,
        {
          client_status: data.client_status,
          client_id: data.client_id,
          client_email_id: data.email_id,
          client_name: data.full_name,
        },
        { headers: headers }
      )
      .then((res) => {
        // console.log("leeds mail scc ", res);
        message.success("Mail sent successfully");
      })
      .catch((error) => {
        // console.log("leeds mail   error", error);
      });
  };

  return (
    <div className="actv-lds-main-cont">
      {clientsData.length > 0 ? (
        <div className="active-leads-table">
        <table >
          <thead>
            <tr>
              <th className="s-no-head">Sno.</th>
              <th style={{justifyContent:"flex-start"}}>Client name</th>
              <th >Status</th>
              <th style={{paddingLeft:"70px"}}>Contact</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {clientsData
              .filter((data) => {
                if (props.searchClientsName === "") {
                  return data;
                } else if (
                  data.full_name
                    ? data.full_name
                        .toLowerCase()
                        .includes(props.searchClientsName.toLowerCase())
                    : ""
                ) {
                  return data;
                }
              })
              .map((data, index) => {
                return (
                  <tr>
                    <td style={{ cursor: "default" }}>{`${index + 1}`}</td>

                    <td
                      style={{ cursor: "default", paddingLeft: "0" }}
                      onClick={() => showDetail(data)}
                    >
                      {data.full_name ? data.full_name : "--"}
                    </td>

                    <td
                      onClick={() => showDetail(data)}
                      style={{ paddingLeft: "0px", cursor: "default" }}
                    >
                      {data.client_status}
                    </td>
                    <td
                      onClick={() => showDetail(data)}
                      style={{ whiteSpace: "nowrap", cursor: "default" }}
                    >
                      {data.mobile_number ? "+91-" + data.mobile_number : "--"}
                    </td>
                    <td className="actvlds-icn">
                      <div className="communication-icon-container">
                        <div className="icon-cont">
                          <Tooltip title="Via Sms">
                            <SMS onClick={() => sendSms(data)} />
                          </Tooltip>
                        </div>

                        <div className="icon-cont">
                          <Tooltip title="Via E-mail">
                            <Email onClick={() => sendMail(data)} />
                          </Tooltip>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        </div>
      ) : (
        <div
          className={
            loder === true ? "loder-screen-activeleads" : "empty-activeleads"
          }
        >
          {loder === true ? (
            <Spin size="large" />
          ) : (
            <>
              <EmptySreen></EmptySreen>
              <h1>No active leads</h1>
              <p>
                Get your clients to download Kuants app and set up their
                investment account in just few simple steps.
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ActiveLeadsTable;
