import React, { useState, useEffect } from "react";
import { ReactComponent as WhiteBookMark } from "../../Icons/whiteBookMark.svg";
import { ReactComponent as BookMark } from "../../Icons/marketingbookmark.svg";
import { ReactComponent as Share } from "../../Icons/shareWhite.svg";
import { ReactComponent as Fb } from "../../Icons/fb.svg";
import { ReactComponent as Linkedin } from "../../Icons/linkedin.svg";
import { ReactComponent as Whatsapp } from "../../Icons/whatsapp.svg";
import { ReactComponent as Twitter } from "../../Icons/twitter.svg";
import { ReactComponent as Telegram } from "../../Icons/telegram.svg";
import { ReactComponent as Email } from "../../Icons/mail.svg";
import { ReactComponent as CopyICon } from "../../Icons/copy-icon.svg";
// import { ReactComponent as ArrowDownload } from "../../Icons/arrowDownload.svg";
// import { ReactComponent as Share } from "../../Icons/shareWhite.svg";
import { ReactComponent as Play } from "../../Icons/play.svg";
import axios from "axios";
import Modal from "antd/lib/modal/Modal";
import {  notification } from "antd";
import config from "../../config";

import {
  // EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import { decrypt } from "../TockenConversion/JwtConvert";
export default function VideoCard(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [marketingVideoData, setMarketingVideoData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [tittleUrl, setTittleUrl] = useState("");
  const [iconREsizer, setIconReSize] = useState(false);

  // console.log("INSIDE VideoCARD CARD MARKETING DATA",props.marketingCardData);
  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };
  useEffect(() => {
    const filter = props.marketingCardData.filter(
      (data) => data.url_type === "Video"
    );
    // console.log("FILTER VIDEO URL ONLY",filter);
    setMarketingVideoData(filter);
  }, [props.marketingCardData]);

  const updateBookmark = (data) => {
    const tempdataArray = props.marketingCardData;
    if (data.url_bookmark_status === true) {
      data.url_bookmark_status = false;
    } else {
      data.url_bookmark_status = true;
    }
    props.setMarketingCardData([...tempdataArray, data]);
  };
console.log("before bookmark update",marketingVideoData.length);
  const bookmarkDone = (data) => {
    updateBookmark(data);
    console.log("BOOKMARK", data);
    console.log("after bookmark update",marketingVideoData.length);
    axios
      .post(
        config.marketingBookMark,
        { marketing_url: data.marketing_url },
        { headers: headers }
      )
      .then((res) => {
        // console.log(data,":::::::::::::::",res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const copySuccessfull = (type) => {
    notification[type]({
      message: "Link copied",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const showModal = (data) => {
    setIsModalOpen(true);
    setVideoUrl(data.marketing_url);
    setTittleUrl(data.url_heading);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // const copy=()=>{
  //   navigator.clipboard.writeText("text")
  //   console.log("copy");
  // }     
  const copy = () => {
    setIconReSize(true)
    setTimeout(() => {
      const textField = document.createElement('textarea');
      textField.innerText = videoUrl;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      setIconReSize(false)
      textField.remove();
      copySuccessfull("success")
    }, 500);
 
  };
  return (
    <>
      {marketingVideoData.map((data ,index) => {
        return (
          <div className="video-card-cont" key={index}>
            <div className="card">
              <iframe
                width="100%"
                height="100%"
                src={data.marketing_url}
                title="YouTube video player"
                frameborder="0"
                allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullScreen
              ></iframe>
              {/* <div className="play-icn-cnt">
                <Play />
              </div> */}
              <div className="bookMark">
                {data.url_bookmark_status === true ? (
                  <BookMark onClick={() => bookmarkDone(data)} />
                ) : (
                  <WhiteBookMark onClick={() => bookmarkDone(data)} />
                )}
              </div>
              <div className="downlod-and-share-icon-cont">
                <div className="share-icn-cont">
                  <Share onClick={() => showModal(data)} />
                </div>
              </div>
          

              <div>
                <h1>{data.url_heading.slice(0, 30)}...</h1>
  
                <h6>
                  Source::{" "}
                  <spna className="date-heading">{data.source_of_url}</spna>
                </h6>
              </div>
            </div>
          </div>
        );
      })}
      {/* <div className="marketing-modal-cont"> */}
        <Modal
          className="marketing-share-content-modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="content-theme-cont">
            <h1>Share Via</h1>
          </div>
          <div className="share-icon-cont">
            <FacebookShareButton url={videoUrl} title={tittleUrl}>
              <Fb />
            </FacebookShareButton>

            <TwitterShareButton url={videoUrl} title={tittleUrl}>
              <Twitter />
            </TwitterShareButton>
            <LinkedinShareButton url={videoUrl} title={tittleUrl}>
              <Linkedin />
            </LinkedinShareButton>

            <WhatsappShareButton
              url={videoUrl}
              title={tittleUrl}
              separator=":: "
            >
              <Whatsapp />
            </WhatsappShareButton>

            <TelegramShareButton url={videoUrl} title={tittleUrl}>
              <Telegram />
            </TelegramShareButton>
            {/* <EmailShareButton url={videoUrl} subject={tittleUrl} body="body">
              <Email />
            </EmailShareButton> */}
          </div>
          <div className="or-text-cont">
            <h1>or</h1>
          </div>
          <div className="marketing-copy-url-container">
            <div className="copy-url-container">
              <h6 className="copy-url-text">Copy URL</h6>
              <div  className="input-copyIcon-cont">
                <input
                  type="text"
                  className="url-input"
                  value={videoUrl}
                  placeholder="https:www.kuants.in"
                  //   value={getUrl}
                />
               <div className="copy-icon-cont" onClick={copy}> 
               <CopyICon  className={iconREsizer === true? "after-click-copy-icon":"copy-icon"}/>
              </div>
              </div>
            </div>
          </div>
        </Modal>
      {/* </div> */}
    </>
  );
}
