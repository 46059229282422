// import axios from 'axios'
import React from "react";
import { useState } from "react";
import Checkbox from "../ClientsDataTAble/Checkbox";
import { ReactComponent as EmptySreen } from "../../../Icons/emptyscreen.svg";

// import config from '../../../config'
import "./ClientDetails.css";
import Pagination from "./Pagination";
export default function TransactionTable(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;

  let currentItems;
  if (props.transactionData.length !== 1) {
    currentItems = props.transactionData.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
  } else {
    currentItems = props.transactionData;
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="client-detl-pg-trnstn-cont">
      {currentItems.length > 0 ? (
        <div className="table-style-one">
          <table >
            <tr>
              <th>Fund name</th>
              <th>Status</th>
              <th>Transaction</th>
              <th>Amount</th>
              <th>Order State</th>
              <th>Order Type</th>
            </tr>

            {currentItems.map((data) => {
              return (
                <tr>
                  <td>{data.fund_scheme_name.slice(0, 16)}...</td>
                  <td>{data.payment_completed}</td>
                  <td>{data.created_at.split("T")[0]}</td>
                  <td>{data.amount}</td>
                  <td>{data.order_state}</td>
                  <td>{data.mf_order_type}</td>
                </tr>
              );
            })}
          </table>

          <Pagination
            itemPerPage={itemPerPage}
            totalUsers={props.transactionData.length}
            paginate={paginate}
            currentPage={currentPage}
            handleNextPage={() =>
              setCurrentPage(
                currentPage <= props.transactionData.length
                  ? currentPage + 1
                  : currentPage
              )
            }
            handlePrevPage={() => setCurrentPage(currentPage - 1)}
          />
          <div className="pagination-in-mob">
            {currentItems.length > 0 ? (
              <Pagination
                itemPerPage={itemPerPage}
                totalUsers={props.transactionData.length}
                paginate={paginate}
                currentPage={currentPage}
                handleNextPage={() =>
                  setCurrentPage(
                    currentPage <= props.transactionData.length
                      ? currentPage + 1
                      : currentPage
                  )
                }
                handlePrevPage={() => setCurrentPage(currentPage - 1)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="client-detail-transaction-empty-scrn">
          <EmptySreen />
          <h1>No Transactions</h1>
          <p>
            Get your clients to download Kuants app and set up
            their investment account in just few simple steps.
          </p>
        </div>
      )}
    </div>
  );
}
