import React, { useState, useEffect, useRef } from "react";
import ActiveLeadsTable from "./ActiveLeads/ActiveLeadsTable";
import DataCard from "./Card/DataCard";
import "./Dashboard.css";
import ImageSlider from "./OfferSlider/Slider";
import { Helmet } from "react-helmet";
// import { PieChart } from "react-minimal-pie-chart";
// import ClientDetails from "../Clients/ClientnDetails/ClientDetails";
// import { Link } from "@material-ui/core";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import Event from "./UpcomingEvents/Event";
// import AddNewCLients from "../Clients/AddNewCLients/AddNewCLients";
// import Clients from "../Clients/Clients";
import { ReactComponent as ActiveCLientIcon } from "../../Icons/Active-clients-icon.svg";
import { ReactComponent as TotalAumIcon } from "../../Icons/total-aum-icon.svg";
import { ReactComponent as TotalIncomeIcon } from "../../Icons/total-income-icon.svg";
import { ReactComponent as Comingsoon } from "../../Icons/Coming soon.svg";
import { decrypt } from "../TockenConversion/JwtConvert";
export default function Dashboard(props) {
  const [searchClientsName ] = useOutletContext();     
  // const [totalAum,setTotalAum] = useOutletContext();     

  const [accesToken, setAccessToken] = useState();

  const navigate = useNavigate();
  // const [showClientsDetails, setShowClietnsDetails] = useState(false);
  const [clientsName, setClientName] = useState();
  const [activeCLients, setActiveClients] = useState("");
  const [totalAum, setTotalAum] = useState("");
  const [totalIncome, setTotalIncome] = useState("");
  const [rmName, setRmName] = useState("");
  const [rmEmail, setRmEmail] = useState("");
  const [rmNumber, setRmNumber] = useState("");
  const [latestNewsData, setLatestNewsData] = useState([]);

  const [eventData, setEventData] = useState([]);
  // console.log("news fbdjhsgfshjdata",searchClientsName)
  const data = [
    { title: "Active", value: 40, color: "#6D42DA" },
    { title: "Inactive", value: 55, color: "#5985FC" },
  ];
  // const moveToClientPage = () => {
  // setNewclients(true)
  // props.setViewAll(newClients)
  // };

  const moveToLatestNews = () => {
    navigate("/dashboard/latestnews");
  };
  const refresh_token = () => {
    const header = {
      "Content-Type": "application/json",
      // Accept :"application/json"
    };
    const decrypted_refresh_token = decrypt("refresh_token",localStorage.getItem("refresh_token"));
    const data = { refresh: `${decrypted_refresh_token}` };
    axios
      .post(config.refreshToken, data, { headers: header })
      .then((res) => {
        // console.log("refresh token", res);
        if (res.data.access) {
          localStorage.setItem("access_token", res.data.access);
          setAccessToken(res.data.access);
        }
      })
      .catch((error) => {
        // console.log("refrsh token error", error);
        localStorage.clear();
        navigate("/");
      });
  };
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(0);
  };
  //
  useEffect(() => {
    // console.log(localStorage.getItem("access_token"))
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));
    const headers = {
      "Content-Type": "Application/json",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };
    axios
      .get(config.activeClient_totalAum_totalIncome, { headers: headers })
      .then((res) => {
        // console.log("active clients ", res);
        if (res.data) {
          setActiveClients(res.data.active_client);
          setTotalAum(res.data.total_invested_amount);
          setTotalIncome(res.data.total_income);
          localStorage.setItem("totalAum", res.data.total_invested_amount);
          // props.setAgentName(res.data.agent_name)
        }
      })
      .catch((error) => {
        // console.log("active clients error",error)

        // console.log("active clients error", error);
        if (error.response.data.errors) {
          setActiveClients("0");
          setTotalAum("0");
          setTotalIncome("0");
        } else if (error.response.data.code) {
          refresh_token();
        }
      });
    // -----------Rm------Api--------------
    axios
      .get(config.contactRm, { headers: headers })
      .then((res) => {
        // console.log("response contact RM", res);
        setRmName(res.data.rm_name);
        setRmEmail(res.data.rm_email);
        setRmNumber(res.data.rm_phone);
      })
      .catch((error) => {
        // console.log("contact rm error", error);
      });

    //  ---------Event list APi----------
    axios
      .get(config.eventList, { headers: headers })
      .then((res) => {
        // console.log("event list response", res);
        axios
        .get(config.upcomingSip, { headers: headers })
        .then((response) => {
          setEventData([...response.data,...res.data]);
          // console.log("upcommingSip", response);
        })
        .catch((err) => {
          // console.log("upcomming sip failed", err);
        });
  

      })
      .catch((error) => {
        // console.log("event list error", error);
      });

    // -------------news-----api------call----------
    axios
      .get(config.dashboardNews + "1", { headers: headers })
      .then((res) => {
        // console.log("news data", res);
        setLatestNewsData(res.data);
      })
      .catch((error) => {
        // console.log("news error", error);
      });
  }, [accesToken]);

  const moveToClientPage = () => {
    // setDashToCLientByViewALl("true")
    // console.log("MVE TO CLIENT",dashToCLientByViewALl);
  };

  const usePrevLocation = (location) => {
    const prevLocRef = useRef(location);

    useEffect(() => {
      prevLocRef.current = location;
    }, [location]);

    return prevLocRef.current;
  };

  return (
    <div>
      <Helmet>
        <title>Dashboard</title>
        <link rel="icon" href="%PUBLIC_URL%/public/faveicon.ico" />
      </Helmet>
      <div className="dashboard-main-container">
        {/* ---------------------------------Top card------------------------------------- */}
        <div
          className="dashborad-top-card-container"
          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          //   gap: "1.66vw",
          //   marginTop: "3.34vh",
          //   marginLeft: "3.33vw",
          // }}
        >
          <DataCard
            abc={activeCLients}
            heading={"Total Clients"}
            identiFier={"active_clients"}
            icon={<ActiveCLientIcon />}
          />
          <DataCard
            abc={
              totalAum < 50000
                ?"₹" + totalAum
                : totalAum > 50000 && totalAum < 100000
                ? "₹" + (totalAum / 1000).toFixed(2) + "K"
                : totalAum > 100000
                ? "₹" + (totalAum / 100000).toFixed(2) + "L"
                : "₹" + totalAum
              // "₹" + (totalAum / 100000).toFixed(2) + "L":totalAum
            }
            heading={"Total AUM"}
            identiFier={"aum"}
            icon={<TotalAumIcon />}
          />
          <DataCard
            abc={
              totalIncome < 50000
              ? "₹" + totalIncome
              : totalIncome > 50000 && totalIncome < 100000
              ? "₹" + (totalIncome / 1000).toFixed(2) + "K"
              : totalIncome > 100000
              ? "₹" + (totalIncome / 100000).toFixed(2) + "L"
              : "₹" + totalIncome
            
            }
            heading={"Total Earnings"}
            identiFier={"earnings"}
            icon={<TotalIncomeIcon />}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            gap: "1.66vw",
            marginLeft: "3.33vw",
            marginTop: "3.89vh",
          }}
        ></div> */}
        {/* --------------------Active--Leads----------------------------------------------------------------         */}
        <div
          className="activeleads-upcomingevents-container"
          //  style={{ display: "flex", gap: "1.66vw", marginLeft: "3.33vw",    marginTop: "3.89vh", }}
        >
          <div className="active-leads-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="active-lead-text">Active leads</p>
              <Link
                to="/clients"
                state={{
                  myName: [
                    "KYC not started",
                    "Not Signed Up",
                    "Address pending",
                    "Bank verification pending",
                    "Nominee pending",
                    "Signature pending",
                    "Video verification pending",
                    "No investments done",
                    "KYC Pending",
                    "KYC Rejected",
                  ],
                }}
                className="view-all-text"
              >
                <p onClick={moveToClientPage}>View all</p>
              </Link>
            </div>
            <div className="active-leads-content">
              <ActiveLeadsTable
                searchClientsName={searchClientsName}
                clientsName={clientsName}
                setClientName={setClientName}
                // showClientsDetails={showClientsDetails}
                // setShowClietnsDetails={setShowClietnsDetails}
              />
            </div>
          </div>
          {/* -----------------------UPCOMING EVENTS----------------------------------  */}
          <div className="upcoming-events-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="abc-text">Upcoming events</p>
              {/* <Link className="view-all-text"><p>View all</p></Link> */}
            </div>

            <div className="graph-container">
              <Event setEventData={setEventData} eventData={eventData} />
            </div>
          </div>
        </div>

        {/* ---------------Bottom ----Card----------------------------------------------- */}
        <div
          className="dashborad-bottom-card-container"
          // style={{
          //   display: "flex",
          //   marginLeft: "3.33vw",
          //   gap: "1.66vw",
          //   marginTop: "4.43vh",
          // }}
        >
          {/* --------------------------offer--------news--------------------            */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <p className="offer-text">Offers</p> */}

            <div
              className="offer-news-cont"
              // style={{ display: "flex", gap: "1.66vw" }}
            >
              <div style={{ dishplay: "flex" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p className="offer-text">Offers</p>
                  {/* <Link className="view-all-text">
                    <p>View all</p>
                  </Link> */}
                </div>
                <div className="relation-ship-card-container">
                  <img
                    src="/assets/Coming soon Now.png"
                    alt=""
                    width="100%"
                    height="100%"
                    // className="card-container"
                    style={{ borderRadius: "1.11vw" }}
                  />
                  {/* <div>
                  <Comingsoon/>
                  </div>
                */}
                </div>
              </div>
              <div style={{ dishplay: "flex" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p className="offer-text">Latest news</p>
                  <Link
                    className="view-all-text"
                    to="/marketing"
                    state={{
                      index: 2,
                      locationFilter: "Latest news",
                      flag: true,
                    }}
                  >
                    <p>View all</p>
                  </Link>
                </div>
                {/* <div className="card-container"> */}
                <ImageSlider latestNewsData={latestNewsData} />
                {/* </div> */}
              </div>
            </div>
          </div>

          {/* ---------------------Relationships--------------------manager--------------------           */}
          <div
            className="managerdetail-main-container"
            // style={{
            //   display: "flex",
            //   //   marginTop: "16px",
            //   flexDirection: "column",
            //   width: "47.11vw",
            // }}
          >
            <p className="my-site-text">Relationship manager</p>
            <div className="relation-ship-card-container">
              <div
                className="rm-profile-detail-cont"
                //  style={{ display: "inherit", gap: "1vw", padding: "1vw" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="profile-img-container-rm">
                    <img src="/assets/rmanegrPng.png" alt="" width={"100%"} />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p className="manager-name">{rmName}</p>
                    <p className="manager-type">Relationship Manager</p>
                    <div>
                      <h6 className="contact-text">
                        Phone no.:{" "}
                        <span className="manager-conatct-data">
                          +91-{rmNumber}
                        </span>{" "}
                      </h6>
                      <p className="contact-text">
                        Email:{" "}
                        <span className="manager-conatct-data">{rmEmail}</span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
