import React, { useState } from "react";
import "./ClientDetails";
import InvestMentPortFolioModalView from "./InvestMentPortFolioModalView";
import { ReactComponent as EmptySreen } from "../../../Icons/emptyscreen.svg";
import axios from "axios";
import config from "../../../config";
import { Spin } from "antd";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function InvestMentPortFolioTable(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [investMentPortFolioModalData, setinvestMentPortFolioModalData] =
    useState([]);

    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };

  const openInvestMentPortFolioModal = (data) => {
    setIsModalOpen(true);
    axios
      .post(
        config.singleInvestMentPortFolio,
        { folio_number: data.folio_number },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res, "Post folio data successfully");
        setinvestMentPortFolioModalData(res.data);
        // setLoder(false)
      })
      .catch((err) => {
        // console.log(err, "post folio data erro",err);
      });
  };

  return (
    <div className="invested-portFolio-table-cont">
      {props.investMentPortFolioData.length > 0 ? (
        <div className="table-style-one">
        <table >
          <tr>
            <th>Fund name</th>
            <th>Total investment</th>
            <th>Current value</th>
            <th>Return</th>
            <th>Investment type</th>
            <th>Total SIPs</th>
            <th>Folio number</th>
          </tr>

          {props.investMentPortFolioData.map((data) => {
            return (
              <tr   className="inv_port_fundname">
                <td
                  onClick={() => openInvestMentPortFolioModal(data)}
                  // className="inv_port_fundname"
                >
                  {data.fund_name ? data.fund_name.slice(0, 20):"-"}
                </td>
                <td  onClick={() => openInvestMentPortFolioModal(data)}>
                  &#8377;{data.total_investments}
                </td>
                <td onClick={() => openInvestMentPortFolioModal(data)}>
                  &#8377;{data.current_value}
                </td>
                <td onClick={() => openInvestMentPortFolioModal(data)}>
                  &#8377;{data.total_return}
                </td>
                <td onClick={() => openInvestMentPortFolioModal(data)}>
                  {data.investment_type}
                </td>
                <td onClick={() => openInvestMentPortFolioModal(data)}>
                  {data.total_sip}
                </td>
                <td onClick={() => openInvestMentPortFolioModal(data)}>
                  {data.folio_number}
                </td>
              </tr>
            );
          })}
        </table>
        </div>
      ) : (
        <div className="investment-portFo-empty-Scrn">
           { props.loder === true? <Spin size="large"/>:<>
          <EmptySreen />
     
          <h1>This portfolio is empty</h1>
          <p>
            Start building this portfolio by exploring mutual funds on Kuants.
          </p>
          </>}
        </div>
      )}
      <InvestMentPortFolioModalView
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        investMentPortFolioModalData={investMentPortFolioModalData}
      />
    </div>
  );
}
