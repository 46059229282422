import React, { memo  } from 'react'
import "./ClientDetails.css"
import { Line,  } from "react-chartjs-2/";


  function ClientChart(props) {
const windowWidth = window.screen.width
    // console.log("inside graph",props.clientGraphLevelsData)


const plugins =[{
  afterDraw: chart => {
      if (chart.tooltip?._active?.length) {
          let x = chart.tooltip._active[0].element.x;
          let yAxis = chart.scales.y;
          let ctx = chart.ctx;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, yAxis.top);
          ctx.lineTo(x, yAxis.bottom);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#00000';
          ctx.stroke();
          ctx.restore();
      }
  }
}]


    const data = {
        labels: props.clientGraphLevelsData,
        responsive:true,
        datasets: [
          {
          
            // fill: false,
            borderColor: "#5985FC",
            borderWidth: 4,
            pointRadius: 0,
            borderRadious:10,
 
            pointRadius: 0,
            borderRadious:8,
            pointBackgroundColor:"#5985FC",
            tension: 0.4,
            label: props.level,
            spanGaps: false,
            data: props.clienttGraphXaxisData
          },
    
        ]
      }

      return (
        <div className="chart">
       
          <Line
        
         
        // intersect={true}
        // plugins={
        //   annotation: {
        //     annotations: line
        //   },
         
        // }
        plugins={plugins}
            data={data}
            options={{
     
                plugins: {
                    legend: {
                      position: "top",
                      align: "end",
                        display: true,
                        labels: {
                          usePointStyle: true,
               
                        },
                        
                    },
                
                   
                  
                },
                interaction: {
                  intersect: false,
                  mode: 'index',
              },
                // tooltips: {
                //   mode: 'index',
                //   intersect: false,
                //   // annotation: {
                //   //   annotations: line
                //   // },
                // },
              
              hover: {
                  mode: 'index',
                  intersect: false
              },
              
                scales: {
                  x: {
                    display :windowWidth < 767? false: true,
                      ticks: {
                          font: {
                              size: "14vw",
                              family:"Rubik",
                              weight:"400",
                              lineHeight:"20px",
                            
                          },
                      
                      },
                      
                  },
                  y: {
                    display:windowWidth < 767? false: true,
                      ticks: {
                          font: {
                              size: "14vw",
                              family:"Rubik",
                              weight:"400",
                              lineHeight:"20px"
                          },
                         
                      }
                      
                  }
              }
              }}
          />

        </div>
      )
    }
    export default memo(ClientChart)






// import React from "react";
// import Chart from 'chart.js/auto';
// import { Line } from "react-chartjs-2";
// // import 'chartjs-adapter-moment';

// const plugin = {
//   id: 'corsair',
//   afterInit: (chart) => {
//     chart.corsair = {
//       x: 0,
//       y: 0
//     }
//   },
//   afterEvent: (chart, evt) => {
//     const {
//       chartArea: {
//         top,
//         bottom,
//         left,
//         right
//       }
//     } = chart;
//     const {
//       x,
//       y
//     } = evt.event;
//     if (x < left || x > right || y < top || y > bottom) {
//       chart.corsair = {
//         x,
//         y,
//         draw: false
//       }
//       chart.draw();
//       return;
//     }

//     chart.corsair = {
//       x,
//       y,
//       draw: true
//     }

//     chart.draw();
//   },
//   afterDatasetsDraw: (chart, _, opts) => {
//     const {
//       ctx,
//       chartArea: {
//         top,
//         bottom,
//         left,
//         right
//       }
//     } = chart;
//     const {
//       x,
//       y,
//       draw
//     } = chart.corsair;

//     if (!draw) {
//       return;
//     }

//     ctx.lineWidth = opts.width || 0;
//     ctx.setLineDash(opts.dash || []);
//     ctx.strokeStyle = opts.color || 'black'

//     ctx.save();
//     ctx.beginPath();
//     if (opts.vertical) {
//       ctx.moveTo(x, bottom);
//       ctx.lineTo(x, top);
//     }
//     if (opts.horizontal) {
//       ctx.moveTo(left, y);
//       ctx.lineTo(right, y);
//     }
//     ctx.stroke();
//     ctx.restore();
//   }
// }

// Chart.register(plugin)
// const options = {
//   type: 'line',
//   data: {
//     labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
//     datasets: [{
//         label: '# of Votes',
//         data: [12, 19, 3, 5, 2, 3],
//         borderColor: 'pink'
//       },
//       {
//         label: '# of Points',
//         data: [7, 11, 5, 8, 3, 7],
//         borderColor: 'orange'
//       }
//     ]
//   },
//   options: {
//     plugins: {
//       corsair: {
//         horizontal: false,
//         vertical: true,
//         color: 'red',
//         dash: [],
//         width: 2
//       }
//     }
//   },
// }

// const ctx = document.getElementById('chartJSContainer').getContext('2d');
// new Chart(ctx, options);
// const ClientChart = ({ dataChart }) => {
//   return <>

  
//   <Line 
//             data={dataChart} options={options}
//         />;
//   </>
// };

// export default ClientChart;