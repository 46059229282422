import React from "react";
import { useState, useEffect } from "react";
import "./login.css";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import LoginLeftPart from "./LoginLeftPart";
import SendOtp from "./SendOtp";
import "antd/dist/antd.min.css";
import { notification, message } from "antd";
import axios from "axios";
import config from "../../config";
import { ReactComponent as CLoseEye } from "../../Icons/closeEye.svg";
import { ReactComponent as OpenEye } from "../../Icons/openEye.svg";
import {crypt} from "../TockenConversion/JwtConvert";
export default function Login(props) {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [userOtp, setUserOtp] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [showPass, setShowPassword] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "OTP sent Successfully",
      duration: 2,
      closeIcon: <></>,
    });
  };

// const text  = crypt("salt","hello")
// console.log(text);


  const showOtpError = () => {
    message.error({
      content: "OTP does not match",
      className: "custom-class",
      style: {
        marginTop: "5vh",
        marginLeft: "77vw",
      },
    });
  };
  const showErrorUserNotExist = () => {
    message.error({
      content: "User does not exist",
      className: "custom-class",
      style: {
        marginTop: "5vh",
        marginLeft: "77vw",
      },
    });
  };

  // if(window.location.pathname ==="/"){
  //   localStorage.clear()
  // }

  useEffect(() => {
    if (window.location.pathname === "/") {
      if(localStorage.getItem("access_token")){
        // navigate("/dashboard"); 
      }
      else{
      localStorage.clear();
      }

    }
  }, [window.location.pathname]);

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(0);
  };

  // ----------------------------OTP Request Function---------------------
  const otpRequest = () => {
    // e.prevent.Default()
    setPassword("");
    // console.log("outisde if click");
    setPassword("");
    // if (mobileNumber.length === 10) {

    //   console.log("insde if click");

    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
    };
    axios
      .post(
        config.resendOtp,
        { contact_number: mobileNumber ,step:"Signin"},
        { headers: headers }
      )
      .then((res) => {
        // console.log("login resend otp", res);
        if (res.data) {
          // setSendOtp(true);

          openNotificationWithIcon("success");
          setSendOtp(true);
        }
      })
      .catch((error) => {
        // console.log("login resend otp error", error);
        if (error.response.data.non_field_errors[0]) {
          showErrorUserNotExist();

          // console.log("error function call");
        }
      });
  };

  // -----------------------------Login Function-------------------
  const loginSuccess = () => {
    if (sendOtp === true) {
      const headers = {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin":"*",
        Accept: "application/json",
      };

      axios
        .post(
          config.signupOtp,
          {
            otp_generated: userOtp.split(" ").join(""),
            contact_number: mobileNumber,
          },
          { headers: headers }
        )
        .then((res) => {
          // console.log("login otp verfy seccfly", res);
          if (res.data.message) {
            props.setLoged(true);
            navigate("/dashboard");
            message.success("Login successful");
          }
          const encrypted_access_tocken = crypt("access_token", res.data.access);
          const encrypted_refresh_tocken = crypt("refresh_token", res.data.refresh);

          localStorage.setItem("access_token", encrypted_access_tocken);
          localStorage.setItem("refresh_token", encrypted_refresh_tocken);
          localStorage.setItem("agentName", res.data.agent_name);
        })
        .catch((error) => {
          // console.log("login otp vrfy fail error", error);
          if (error.response.data.non_field_errors) {
            showOtpError();
            // showError();
            // message.error("OTP does not match")
          }
        });
    } else {
      const headers = {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin":"*",
        Accept: "application/json",
      };
      axios
        .post(
          config.login,
          { agent_email_id: mobileNumber, password: password },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.message) {
            // props.setLoged(true) 
            const encrypted_access_tocken = crypt("access_token", res.data.access);
            const encrypted_refresh_tocken = crypt("refresh_token", res.data.refresh);

            localStorage.setItem("access_token", encrypted_access_tocken);
            localStorage.setItem("refresh_token", encrypted_refresh_tocken);
            localStorage.setItem("agentName", res.data.agent_name);
            props.setLoged(true);
            navigate("/dashboard");
            message.success("Login successful");
          }

          // <Link to="/dashboard"/>
        })
        .catch((error) => {
          if (error.response.data.non_field_errors) {
            // showError("error");
            setLoginError(true);
          }
          // setLoginError("Id password does not matched")
        });
    }
  };

  const numberEmailChange = (e) => {
    const val = e.target.value;
    setMobileNumber(val);
  };
  const handelEnterKey = (event) => {
    if (event.keyCode === 13) {
      // console.log("key press")
      if ((mobileNumber && password.length >= 8) || userOtp.length === 7) {
        loginSuccess();
      }
    }
  };

  const showPassword = () => {
    if (password) {
      if (showPass === true) {
        setShowPassword(false);
      } else {
        setShowPassword(true);
      }
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Helmet>
        <title>Login</title>
        <link rel="icon" href="faveicon.ico" />
        {/* <link
          rel="icon"
          type="image/png"
          href="./././public/feicon.ico"
          sizes="16x16"
        /> */}
      </Helmet>

      <LoginLeftPart />

      <div
        className="login-container"
        // style={{
        //   display: "flex",
        //   flexDirection: "column",
        //   marginLeft: "5vw",
        //   height: "100%",
        //   // justifyContent: "center",
        // }}
      >
        <img className="logo-img" src="/assets/Logo.png" alt="Loding...." />
        <h4 className="login-text">Log in</h4>
        {sendOtp ? (
          <SendOtp
            mobileNumber={mobileNumber}
            setUserOtp={setUserOtp}
            userOtp={userOtp}
            handelEnterKey={handelEnterKey}
          />
        ) : (
          // <form>
          <>
            <div className="login-input-container">
              {/* <span>+91-</span> */}
              {/* {sendOtp ? <SendOtp/> :<> */}
              <input
                className="phone-input"
                type="text"
                value={mobileNumber}
                placeholder="Mobile no or Email"
                required
                
                onChange={numberEmailChange}
                onKeyDown={handelEnterKey}
              />
              <br />
              <input
                value={password}
                className="password-input"
                type={showPass ? "text" : "password"}
                placeholder="Password"
                
                onChange={(e) => {
                  // if (e.target.value.length > 8) {
                  setPassword(e.target.value);
                  // }
                }}
                onKeyDown={handelEnterKey}
              />{" "}
              <span onClick={showPassword} className="eye-icn-clss">
                {" "}
                {showPass ? <OpenEye /> : <CLoseEye />}
              </span>
              {loginError ? (
                <p className="login-error-msg">Invalid credentials</p>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                width: "23.7vw",
                minWidth: "299px",
                // marginLeft: "15vw",
                gap: "0.55vw",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.69vw",
                // marginLeft:"19vmin"
              }}
            >
              <h6 className="or-text">or</h6>
              <button
                disabled={mobileNumber.length === 10 ? false : true}
                className={`${
                  !isNaN(mobileNumber) && mobileNumber.length === 10
                    ? "reqst-otp-btn"
                    : "deactivate-reqst-otp-btn"
                }`}
                // type="submit"
                onClick={otpRequest}
              >
                Request for OTP
              </button>
            </div>
          </>
        )}
        {/* <Link to="/dashboard"> */}
        {/* {loginError ? <span className="loginError">Id password does not match</span>:""} */}
        <button
          disabled={
            (mobileNumber && password.length >= 8) || userOtp.length === 7
              ? false
              : true
          }
          className={`login-btn ${
            (mobileNumber && password.length >= 8) || userOtp.length === 7
              ? "active-login-btn"
              : ""
          }`}
          type="submit"
          onClick={loginSuccess}
        >
          Login
        </button>
        {/* </Link> */}
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            width: "23.7vw",
            minWidth: "306px",
            // gap: "4.2vw",
            // minWidth: "250px",/
          }}
        >
          <p className="Forget-text" onClick={()=>navigate("/forgotpassword")}>Forgot password?</p>
          <Link
            to="/signup"
            style={{
              // width:"25.30vw",
              whiteSpace: "nowrap",
              display: "flex",
              // flexDirection: "row",
              // marginLeft: "10.5vw",
              // marginTop: "-2.1vw",
              gap: "3.2px",
            }}
          >
            <p className="new-on-text">New on Kuants?</p>

            <h6 className="Sign-up-btn">Sign up</h6>
          </Link>
        </div>
      </div>
    </div>
  );
}
