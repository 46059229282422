import { message } from "antd";
import axios from "axios";
import React from "react";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import KycUpload from "../KycComponent/KycUpload";
// import KycDocument from "./KycDocument";
import { crypt ,decrypt} from "../TockenConversion/JwtConvert";
import "./PersonalDetail.css";
export default function PersonalDetail(props) {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [arnNumber, setArnNumber] = useState("");
  const [showInput, setShowInput] = useState(false);
const[nameValidation,setNameValidation] = useState(false)
  // const [disableBtn, setDisableBtn] = useState(true);
  const [movePanToCheck, setMovePanToCheck] = useState(false);
  // const [move, setMove] = useState(false);
  const moveKycPage = () => {
    // setMove(true);
    // console.log("agent Name", fullName);
    // console.log("agent occupation", selectedOption);
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "Application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };
    axios
      .post(
        config.agentName,
        { agent_name: fullName, agent_occupation: selectedOption ,arn_number:arnNumber? arnNumber:null},
        { headers: headers }
      )
      .then((res) => {
        // console.log("agent detail", res);
        props.setLoged(true)
        if (res.data) {
          navigate("/dashboard");
          message.success("Sign-up successful");
        }
        const encrypted_access_tocken = crypt("access_token", res.data.access);
        const encrypted_refresh_tocken = crypt("refresh_token", res.data.refresh);
        localStorage.setItem("agentName", res.data.agent_name);
        localStorage.setItem("access_token", encrypted_access_tocken);
        localStorage.setItem("refresh_token",encrypted_refresh_tocken);
      })
      .catch((error) => {
        // console.log("agent deatil error", error);
      });
  };
  // const handelSkip = () => {
  //   setMove(true);
  // };
  const handelEnterKey = (event) => {
    if (event.keyCode === 13 && fullName && selectedOption) {
      moveKycPage();
    }
  };

  const showArnInput = (event) => {
    if (event.target.checked) {
      setShowInput(true)
      // console.log("show input");
    }
    else{
     setShowInput(false)
    }
  };


  const onInputChange = e => {
    const { value } = e.target;
    const re = /^[a-zA-Z\s]*$/;
    if (value === "" || re.test(value)) {
      setFullName(value);
      // console.log("value ",e.target.value)
    }
  }



  return (
    <div>
      {/* {move ? (
        //
        // <KycDocument documentName="Aadhar" />
        <KycUpload
          documentName="Aadhar card"
          setMovePanToCheck={setMovePanToCheck}
          movePanToCheck={movePanToCheck}
        />
      ) : (
        <> */}
      <img className="logo-img" src="/assets/Logo.png" alt="Loding...." />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1 className="heading-personal">Enter personal details</h1>
        <div style={{ display: "flex", flexDirection: "column"}}>
          <input
            className="name-input"
            type="text"
            name="name"
            value={fullName}
            placeholder="Full name (As per PAN card)"
            onKeyDown={handelEnterKey}
            // onkeydown="return /[a-z]/i.test(event.key)"
            onChange={ onInputChange
        
            }
          
          />
          <div className="alert-msg-cont">
          {nameValidation === true ? <p className="alert-msg">Please enter the correct full name </p>:""}
          </div>
          <select
            className="select-occupation"
            onChange={(e) => {
              setSelectedOption(e.target.value);
              // setDisableBtn(false)
            }}
          >
            <option value="Select occupation" selected disabled>
              Select occupation
            </option>
            <option value="LIC Agent">LIC Agent</option>
            <option value="Businessman">Businessman</option>
            <option value="Wealth Manager">Wealth Manager</option>
            <option value="MFD">MFD</option>
            <option value="Others">Others</option>
          </select>
          <div className="arn-checkbox-cont">
            <input type="checkbox" onChange={showArnInput} />
            <p>Yes, I have ARN number.</p>
          </div>
        <span className={`${showInput === true ? "arn-input-prefix":"hide-arn-input"}`}>ARN-
          <input
            className={`${showInput === true ? "arn-input":"hide-arn-input"}`}
            type="number"
            placeholder="ARN number"
            // onKeyDown={handelEnterKey}
            onChange={(e) => setArnNumber(e.target.value)}
          /></span>
      
        </div>
        <button
          className={`btn-continue ${
            fullName && selectedOption && nameValidation ===false ? "active-btn-continue" : ""
          }`}
          // className={`continue-btn  ${
          //   fullName !== ""
          //     ? "active-btn-continue"
          //     : ""
          // }`}
          onClick={moveKycPage}
          disabled={fullName && selectedOption  && nameValidation ===false ? false : true}
        >
          Continue
        </button>
        {/* <p className="do-later-text" onClick={handelSkip}>I’ll do later</p> */}
      </div>
      {/* </>
      )} */}
    </div>
  );
}
