import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import config from "../../../config";
import "./DataTable";
import { decrypt } from "../../TockenConversion/JwtConvert";
const PLATFORMS = [
  "Software Engineer",
  "Doctor",
  "CA/Finance",
  "Businessman",
  "LIC Agents",
  "Married",
  "Single",
  "Senior Citizen",
  "First Time Investor",
  "Aggressive Investor",
];

export default function Checkbox(props) {
  const windowWidth = window.screen.width
  const [expanded, setExpanded] = useState(false);
  const [selections, setSelections] = useState([]);
  const [expandedBlur, setExpandedBlur] = useState(true);
  const[isModalOpen, setIsModalOpen] = useState(false)


  const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

  const headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":"*",
    Accept: "application/json",
    Authorization: `Bearer ${decrypted_access_token}`,
  };

  useEffect(() => {
    setSelections( props.clientData.client_tags);
  }, []);


  const toggleExpanded = () => {
    if (!expanded) {
      setExpanded(true);
if(windowWidth <767){
  setIsModalOpen(true);
}

      // console.log("ON FILTEr");
    } else {
      setExpanded(false);
      axios
      .post(
        config.clientTags,
        {
          client_id: props.clientData.user_id
            ? props.clientData.user_id
            : props.clientData.temp_id,
          client_tags: selections.join(),
        },
        { headers: headers }
      )
      .then((res) => {
        // console.log("tags post", res);
      })
      .catch((error) => {
        // console.log("tags post error", error);
      });
      // --------------Post api--------------tags
      // console.log("OFF FILTEr");
    }
  };

  const handleChange = (event) => {
    // console.log("HANDLE CHANGE" , event.target.value);
    if (event.target.checked) {
      // console.log("if checkbox is checked", event.target.checked);
      setSelections([...selections, event.target.value]);
      // console.log(event.target.value, "after check");
    } else {
      // setSelections([...selections, event.target.value]);

      let tempData = event.target.value;
      let tempDataArray = selections;
      let tempCurrent = [];

      for (let i = 0; i < tempDataArray.length - 1; i++) {
        if (tempDataArray[i] == tempData) {
        } else {
          tempCurrent.push(tempDataArray[i]);
        }
      }

      setSelections(tempCurrent);
    }
    // console.log( "after check",selections);
  };
  // console.log("store value",selections);
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <div
        onBlur={() => {
          if (!expandedBlur) {
            setExpanded(true);
            // console.log("On FILTE");
          } else {
            setExpanded(false);
            axios
            .post(
              config.clientTags,
              {
                client_id: props.clientData.user_id
                  ? props.clientData.user_id
                  : props.clientData.temp_id,
                client_tags: selections.join(),
              },
              { headers: headers }
            )
            .then((res) => {
              // console.log("tags post", res);
            })
            .catch((error) => {
              // console.log("tags post error", error);
            });
            // console.log("OFF FILTE");
            // console.log(“false else condition”);
          }
        }}
      >
        <div onClick={toggleExpanded} className="click-div" tabIndex="-1">
          {selections.length === 0 ? (
            <h6 className="selecttag-text">Select Tags</h6>
          ) : (
            <h6 className="selecttag-text">
              {selections[0]}
              {selections.length > 1 ? " + " : ""}
              {selections.length > 1 ? selections.length - 1 : ""}
            </h6>
          )}

          <div
            className={`font-semibold cursor-pointer ${
              expanded ? "up-arrow" : "down-arrow"
            }`}
          ></div>
        </div>
         <Modal className="expand-cont-in-mob" open={isModalOpen} onCancel={handleCancel}>
 
          <div
          className="expand-main-container-in-mob"
          >
            <h6 className="selecttag-text">Select tags</h6>
            <p className="profess-heading">Profession</p>
            {PLATFORMS.map((platform, index) => (
              <div
                style={{
                  display: "flex",
                  marginLeft: "1.1vw",
                  marginBottom: "1.31vw",
                }}
              >
                <label htmlFor="one" className="block" key={platform}>
                  <input
                    type="checkbox"
                    name={platform}
                    value={platform}
                
                    checked={
                      selections.find((e) => platform === e) ? true : false
                    }
                    onChange={handleChange}
                    className="m-3 cursor-pointer"
                  />


                  {platform}
                  {index === 4 ? (
                    <p className="investr-type-Heading">Investor Type</p>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            ))}
          </div>
         </Modal>
        {expanded && (
          <div
            className="expand-cont"
            tabIndex="-1"
            onFocus={() => {
              setExpandedBlur(false);
            }}
            onMouseEnter={() => {
              setExpandedBlur(false);
            }}
            onMouseLeave={() => {
              setExpandedBlur(true);
            }}
            onBlur={() => {
              setExpandedBlur(true);
            }}
          >
            <h6 className="selecttag-text">Select tags</h6>
            <p className="profess-heading">Profession</p>
            {PLATFORMS.map((platform, index) => (
              <div
                style={{
                  display: "flex",
                  marginLeft: "1.1vw",
                  marginBottom: "1.31vw",
                }}
              >
                <label htmlFor="one" className="block" key={platform}>
                  {/* {selections.map((data) => { */}
                  <input
                    type="checkbox"
                    name={platform}
                    value={platform}
                    // checked={ value === "Professional" ? true:false}
                    // checked={profsntag}
                    checked={
                      selections.find((e) => platform === e) ? true : false
                    }
                    onChange={handleChange}
                    className="m-3 cursor-pointer"
                  />
                  {/* })} */}

                  {platform}
                  {index === 4 ? (
                    <p className="investr-type-Heading">Investor Type</p>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
