import React, { memo } from 'react'
import "./ClientDetails.css"
function AboutCLient(props) {
  // console.log("render about")
  return (
    <div>
         <p className='clientInfoHeading'>{props.clientInfoHeading}</p>
         <p className='clientInfoHeadingData'>{props.clientInfoHeadingData}</p>
    </div>
  )
}
export default memo(AboutCLient)