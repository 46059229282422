import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import config from "../../../config";
import BankDetailOverView from "../../Clients/ClientnDetails/AboutCLient";
import { decrypt } from "../../TockenConversion/JwtConvert";
export default function BankDetailOverViewForm(props) {
  const [bankDetail, setBankDetail] = useState([]);
  const editFunction = () => {
    props.setDetailView(false);
    props.setFillDetails(true);
    props.setIfscCodeError(false)
  };
  const nexthandel = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    props.handleComplete()
  };
  useEffect(() => {
    const decrypted_access_token = decrypt("access_token",localStorage.getItem("access_token"));

    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin":"*",
      Accept: "application/json",
      Authorization: `Bearer ${decrypted_access_token}`,
    };

    axios
      .get(config.agentBankingDetail, { headers: headers })
      .then((res) => {
        props.setFillDetails(false);
        props.handleComplete()
        // console.log("agent get bank detail", res);

    if(res.status === 204){
      // console.log("status code 204")
      props.setDetailView(false);
      props.setFillDetails(true);
    }    
    else {   
      props.setDetailView(true);
      const AgentBankDetail = [
          {
            heading: "Account number",

            heading_info: `${res.data.agent_bank_account_number_1.replace(/.(?=.{4,}$)/g, 'X')}`,
          },
          {
            heading: "IFSC code",

            heading_info: `${res.data.agent_bank_ifsc_1}`,
          },
          {
            heading: "Account holder name",

            heading_info: `${res.data.agent_bank_account_name}`,
          },
        ];
        setBankDetail(AgentBankDetail);
        props.setAccountNum(res.data.agent_bank_account_number_1)
        props.setReAccountNum(res.data.agent_bank_account_number_1)
        props.setIfsc(res.data.agent_bank_ifsc_1)
        props.setHolderName(res.data.agent_bank_account_name)
      
      }
      })
    
      .catch((error) => {
        // console.log("agent bank get detail error", error);
      });
    
  }, []);

  return (
    <div className="overViewContainer">
      {bankDetail.map((data) => {
        return (
          <BankDetailOverView
            clientInfoHeading={data.heading}
            clientInfoHeadingData={data.heading_info}
          />
        );
      })}
      <div className="edit-next-btn-container">
        <p className="edit-btn" onClick={editFunction}>
          Edit
        </p>
        <p className="edit-btn" onClick={nexthandel}>
          Next
        </p>
      </div>
    </div>
  );
}
